import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBrfSysDesc.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,
        canMountType(map, node, type) {
            return /^(AR)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'AR'
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['AR', '描述'],
            ]
        },
        canWriteTree(map) {
            const data = map.root.data;

            return ['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)
        },
    })
}
