import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Form, Input, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfSubmitProduction({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        form.validateFields().then(async values => {
            try {
                setLoading(true)
                await runAsync(
                    async () => {
                        return await http.post(`/brf/submitProduction`, {...values}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                    }, {
                        error: {content: (err) => `提交投产失败: ${err.message}`},
                        loading: {show: true},
                        success: {show: true},
                    });
                onOk(true)
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue(record)
    }, []);

    return (
        <Modal
            title="提交投产"
            width="600px"
            {...props}
            onOk={handleOk}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Form.Item name='pkid' hidden><Input/></Form.Item>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
