import { css } from "@emotion/react";
import { Button, Modal, Space } from "antd";
import InputText from "@/components/Form/InputText.jsx";
import { useMapContext } from "@/components/MindMap/index.mjs";
import { useProperties } from "../../SidePanel/RightPanel/Properties.mjs";
import { PropertiesController } from "../../SidePanel/RightPanel/PropertiesController.jsx";
import BrfSubmitPreApproval from "@/pages/MainPage/panes/BrfOther/BrfSubmitPreApproval";
import BrfPreApproval from "@/pages/MainPage/panes/BrfOther/BrfPreApproval";
import BrfAccessApproval from "@/pages/MainPage/panes/BrfOther/BrfAccessApproval";
import BrfSubmitAccessApproval from "@/pages/MainPage/panes/BrfOther/BrfSubmitAccessApproval";
import BrfAccessMerge from "@/pages/MainPage/panes/BrfOther/BrfAccessMerge";
import BrfSubmitProductionApproval from "@/pages/MainPage/panes/BrfOther/BrfSubmitProductionApproval";
import BrfSchedule from "@/pages/MainPage/panes/BrfOther/BrfSchedule";
import BrfProductionApproval from "@/pages/MainPage/panes/BrfOther/BrfProductionApproval";
import BrfProductionMerge from "@/pages/MainPage/panes/BrfOther/BrfProductionMerge";
import Permission from "@/components/Permission.jsx";
import { useUser } from "@/biz/user.mjs";
import { useOpenModal } from "@/components/Modal/Modal.jsx";
import BrfSubmitProduction from "@/pages/MainPage/panes/BrfOther/BrfSubmitProduction";

const ButtonStatus = (data) => {
  const map = useMapContext();
  const { refreshProduct  } = map.commands;
  const user = useUser();
  const openModal = useOpenModal();
  const record = map.data;

  const doQuery = () => {
    refreshProduct([map.root]);
  };

  const handleSubmitPreApprovalClick = async () => {
    const res = await openModal(<BrfSubmitPreApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handlePreApprovalClick = async () => {
    const res = await openModal(<BrfPreApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleSubmitAccessApprovalClick = async () => {
    const res = await openModal(<BrfSubmitAccessApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleAccessApprovalClick = async () => {
    const res = await openModal(<BrfAccessApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleAccessMergeClick = async () => {
    const res = await openModal(<BrfAccessMerge record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleScheduleClick = async () => {
    const res = await openModal(<BrfSchedule record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleSubmitProductionApprovalClick = async () => {
    const res = await openModal(<BrfSubmitProductionApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleProductionApprovalClick = async () => {
    const res = await openModal(<BrfProductionApproval record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleProductionMergeClick = async () => {
    const res = await openModal(<BrfProductionMerge record={data} />);
    if(!res) return;
    doQuery();
  };

  const handleSubmitProductionClick = async () => {
      const res = await openModal(<BrfSubmitProduction record={data}/>)
      doQuery()
  }

  const handleRemoveClick = async () => {
    Modal.confirm({
      title: "操作提示",
      okText: "确认",
      cancelText: "取消",
      content: "确认删除？",
      okType: "danger",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        await runAsync(
          async () => {
            return await http.post("/brf/removeBrf", { pkid: data.pkid });
          },
          {
            error: { content: (err) => `删除失败: ${err.message}` },
            loading: { show: true },
            success: { show: true },
          }
        );
        window.close();
      },
    });
  };

  return (
    <Space>
      {["DRAFT", "PRE_APPROVAL_REJECTED"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_SUBMIT_PRE_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.crtId}
        >
          <Button type="primary" onClick={handleSubmitPreApprovalClick}>
            提交预审
          </Button>
        </Permission>
      )}

      {["PENDING_PRE_APPROVAL"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_PRE_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={data.mrmId === user.pkid}
        >
          <Button type="primary" onClick={handlePreApprovalClick}>
            预审
          </Button>
        </Permission>
      )}

      {["ACCESS_APPROVAL_REJECTED"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_SUBMIT_ACCESS_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.crtId}
        >
          <Button type="primary" onClick={handleSubmitAccessApprovalClick}>
            提交评审
          </Button>
        </Permission>
      )}

      {["PENDING_ACCESS_APPROVAL"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_ACCESS_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={data.mrmId === user.pkid}
        >
          <Button type="primary" onClick={handleAccessApprovalClick}>
            评审
          </Button>
        </Permission>
      )}

      {["PENDING_ACCESS_MERGE"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_ACCESS_MERGE"
          project={[record.prjId + record.sVer]}
        >
          <Button type="primary" onClick={handleAccessMergeClick}>
            准入合并基线
          </Button>
        </Permission>
      )}

      {['PENDING_SCHEDULE'].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_SCHEDULE"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.mbmId }
        >
          <Button type="primary" onClick={handleScheduleClick}>
            排期
          </Button>
        </Permission>
      )}

      {["PENDING_DEFINE", "PRODUCTION_APPROVAL_REJECTED"].includes(
        data.brfStsCode
      ) && (
        <Permission
          permission="BRF_SUBMIT_PRODUCTION_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.crtId}
        >
          <Button type="primary" onClick={handleSubmitProductionApprovalClick}>
            提交核准
          </Button>
        </Permission>
      )}

      {["PENDING_PRODUCTION_APPROVAL"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_PRODUCTION_APPROVAL"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.mbmId}
        >
          <Button type="primary" onClick={handleProductionApprovalClick}>
            核准
          </Button>
        </Permission>
      )}

      {/* {["PENDING_PRODUCTION_MERGE"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_PRODUCTION_MERGE"
          project={[record.prjId + record.sVer]}
        >
          <Button type="primary" onClick={handleProductionMergeClick}>
            投产并版
          </Button>
        </Permission>
      )} */}

      {
          ['PRODUCTION_MERGED'].includes(data.brfStsCode) && (
              <Permission permission="BRF_SUBMIT_PRODUCTION" project={[record.prjId + record.sVer]} conditions={data.mbmId === user.pkid}>
                  <Button type="primary" onClick={handleSubmitProductionClick}>提交投产</Button>
              </Permission>
          )
      }

      {["DRAFT"].includes(data.brfStsCode) && (
        <Permission
          permission="BRF_DEL"
          project={[record.prjId + record.sVer]}
          conditions={user.pkid === data.crtId}
        >
          <Button type="ghost" onClick={handleRemoveClick} danger>
            删除
          </Button>
        </Permission>
      )}
    </Space>
  );
};

const cssStatus = css({
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "end",
  gap: 4,
});

export default function PropertiesControllerProductStatus(props) {
  const [{ bizNodeType, brfStsCode, stsName, crtId, ...otherProps }] = useProperties();

  return (
    <PropertiesController label="状态" {...props}>
      <Space>
        <InputText readOnly disabled value={stsName} />

        <ButtonStatus
          bizNodeType={bizNodeType}
          stsCode={brfStsCode}
          brfStsCode={brfStsCode}
          crtId={crtId}
          {...otherProps}
        />
      </Space>
    </PropertiesController>
  );
}
