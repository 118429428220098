import {cloneElement} from 'react'
import {css} from '@emotion/react'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import {Button} from 'antd'
import Table from '@/components/Table/Table.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const ButtonSelect = ({item, onSelect, ...props}) => {
    const handleClick = () => onSelect(item)

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            选择
        </Button>
    )
}

const cssOperates = css({
    display: 'flex',
    justifyContent: 'center',
    gap: 4,
})

const Operates = ({item, map, operates = [], onSelect, ...props}) => {
    const {mapTypeCode, pblType} = map.data

    if (
        ! (
            'SA_MAP' === mapTypeCode &&
            'INCREMENT' === pblType
        ) &&

        0 === item.rev
    ) {
        return null
    }

    const children = [<ButtonSelect key="select" />, ...operates].map(
        e => cloneElement(e, {item, onSelect})
    )

    return (
        <div
            css={cssOperates}
            onClick={e => e.stopPropagation()}
            {...props}
        >
            {children}
        </div>
    )
}

export default function TableChooseDesign({
    columns,
    fetch,
    multiple,
    operates,
    query,
    onChange,
    onSelect,
    ...props
}) {
    const map = useMapContext()
    const {mapTypeCode, pblType} = map.data

    const {data, isLoading} = useSwrNoCache(
        () => fetch(query),
        {dependencies: [query]}
    )

    const getCheckboxProps = (
        'SA_MAP' === mapTypeCode &&
        'INCREMENT' === pblType
    ) ? void 0 : e => ({disabled: ! (0 < e.rev)})

    const rowSelection = {
        getCheckboxProps,
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: multiple ? 'checkbox' : 'radio',
    }

    const enhancedColumns = [
        ...columns,

        {
            title: '版本号',
            dataIndex: 'ver',
            width: 60,
            getValue: ({rev, sVer}) => `${sVer}.${rev}`,
            component: <Table.ViewText />,
        },

        {
            title: '模型成品版本号',
            dataIndex: 'patternRevConcatString',
            width: 120,
            component: <Table.ViewText />,
        },

        {
            title: '操作',
            dataIndex: '_op',
            width: 120,

            component: (_, row) => (
                <Operates
                    item={row}
                    map={map}
                    operates={operates}
                    onSelect={onSelect}
                />
            ),
        },
    ]

    return (
        <Table
            columns={enhancedColumns}
            dataSource={data}
            loading={isLoading}
            rowKey="pkid"
            rowSelection={rowSelection}
            {...props}
        />
    )
}
