import {Modal, Space, Typography} from "antd";
import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {css} from "@emotion/react";

const {Paragraph, Text} = Typography
const cssListPane = css({
    overflow: 'auto',
    padding: 24,
    gap: 24,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',

})
const cssListItem = css({
    padding: 12,
    gap: 12,
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 2px -2px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px, rgba(0, 0, 0, 0.09) 0px 5px 12px 4px',
        borderColor: 'transparent',
        cursor: 'pointer',
        transition: 'box-shadow .3s,border-color .3s',
    },
})

const cssListItemTitle = css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

const cssListItemDescription = css({
    height: 180,
    // lineHeight: '22px',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 2
    // whiteSpace: 'pre',
})

const cssSelectedListItem = css({
    // marginLeft: 12,
    // padding: '1px 8px',
    // border: '1px solid #0099CC',
    // background: 'rgba(0,153,204,0.2)',
    // fontSize: 8,
    // color: '#0099CC',
    border: '1.5px solid #0099CC',
    borderImage: 'linear-gradient(to right, #8f41e9, #0099CC, #8f41e9) 1',
})

export default ({scope, value, onChange}) => {
    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false)
    const [data, setData] = useState([])
    const [name, setName] = useState()
    const http = useHTTP();

    //查询平台代码模板组
    const doQueryTplGroups = async () => {
        const data = await runAsync(
            async () => {
                return await http.get('/basAgTemplateGroups', {tplGroupStsCode: 'RLS', scopes: [scope]});
            }, {
                error: {content: (err) => `查询代码模板套件失败: ${err.message}`},
                loading: {show: false},
            }
        );
        return data;
    }

    const handleClick = () => {
        if (scope) {
            setVisible(!visible);
        } else {
            Modal.info({title: '提示信息', content: '请先选择子系统类型'})
        }
    }

    const handleOk = async (item) => {
        if (onChange) {
            onChange(item.pkid)
            setName(item.tplGroupName)
            setVisible(false)
        }
    }

    useEffect(() => {
        (async () => {
            if (scope) {
                setLoading(true)
                try {
                    const data = await doQueryTplGroups();
                    if (data) {
                        if (value) {
                            const valueItem = data.find(({pkid}) => pkid === value);
                            if (valueItem) {
                                setName(valueItem.tplGroupName)
                            } else {
                                setName(null)
                                onChange(null)
                            }
                        }
                    } else {
                        if (value) {
                            setName(value);
                        } else {
                            setName(null)
                        }
                    }
                    setData(data)
                } finally {
                    setLoading(false)
                }

            }
        })()
    }, [scope]);

    return (
        <>
            <a onClick={handleClick}>
                {name ?? "请选择..."}
            </a>
            <Modal
                visible={visible}
                width={1280}
                title='选择代码框架套件'
                // okText="确认"
                // cancelText="取消"
                // onOk={handleOk}
                onCancel={() => setVisible(false)}
                style={{top: 20}}
                bodyStyle={{background: '#f0f2f5', padding: 0, height: '80vh', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}
                footer={null}
            >
                <div css={[cssListPane]}>
                    {
                        data.map(item => {
                            return (
                                <div css={[cssListItem, value && item.pkid === value ? cssSelectedListItem : {}]}
                                     onClick={() => {
                                         handleOk(item)
                                     }}
                                >
                                    <div css={[cssListItemTitle]}>
                                        <Text strong>{item.tplGroupName}</Text>
                                        {/*{*/}
                                        {/*    value && item.pkid === value && <div css={[cssSelectedListItem]}>当前选择</div>*/}
                                        {/*}*/}
                                    </div>
                                    <Space>
                                        <Text type='secondary'>
                                            脚手架:{item.frameworkName}
                                        </Text>
                                        <Text type='secondary'>
                                            支持模板类型:{item.tplTypeNames}
                                        </Text>
                                    </Space>
                                    <div css={[cssListItemDescription]}>
                                        {
                                            item.tplGroupDesc.split("\n")
                                                .map(s =>
                                                    <div>
                                                        {s}
                                                    </div>
                                                )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal>
        </>
    )
}
