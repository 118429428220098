import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaBf.mjs'
import Model from './ModelBf.mjs'
import PropertiesPane from './PropertiesPaneBf.jsx'
import FormChoose from './FormChooseBf.jsx'
import TableChoose from './TableChooseBf.jsx'
import api from './apiBf.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        attrNodes: {
            top: ['ATTR_BF_DESC'],
        },

        async choose(map, node) {
            const getQuery = ({bms = [], pms = [], ...q}) => ({
                ...q,
                bmIds: bms.map(({bmId}) => bmId),
                pmIds: pms.map(({pmId}) => pmId),
            })

            return this._choose(map, node, {getQuery})
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'Arrow',
            }
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = n.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        bmCode,
                        bmId,
                        bmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        _pushDataSlots: {
            algList: [],
            bmBfs: [],
            fileList: [],
            sfList: [],
        },

        _getCategories(map, node) {
            return [
                'BF_CHART',
                'BF_DATAFLOW',
                'BF_SU',
                'BF_BF',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ProductNode._getDefaultDataFields.call(this, map, node),
                bfTypeCode: 'TARGET'
            }
        },

        //async _grow(map, node, depth) {
            //const newDepth = await ProductNode._grow.call(
                //this, map, node, depth
            //)

            //if (newDepth === depth) {
                //return depth
            //}

            //const {mapTypeCode, prjTypeCode, subTypeCode} = map.data

            //if (
                //'SA_MAP' === mapTypeCode &&

                //(
                    //'BM' === prjTypeCode ||
                    //'BODA2' === subTypeCode
                //)
            //) {
                //for (const n of [...node.children]) {
                    //if ('BF_BF' === n.data.bizNodeType) {
                        //map.deleteTree(n)
                    //}
                //}
            //}
            //else {
                //const p = node.trueParent

                //if (p) {
                    //const {bizNodeType} = p.data

                    //if (/^(CAT_ROOT_)?BM$/.test(bizNodeType)) {
                        //for (const n of [...node.children]) {
                            //if ('BF_SU' === n.data.bizNodeType) {
                                //map.deleteTree(n)
                            //}
                        //}
                    //}
                //}
            //}

            //return newDepth
        //},
    })
}
