import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import ImportDpDialog from '@/pages/MainPage/dialogs/ImportDpDialog.jsx'

export default function PropertiesPaneButtonExportBd(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const {mapStsCode, prjId, prjName, prjNo, sVer} = map.data

    if ('RLS' !== mapStsCode) {
        return null
    }

    const handleClick = () => {
        const pkids = [map.root.data.dmId]

        openModal(
            <ImportDpDialog
                pkids={pkids}
                prjId={prjId}
                prjName={prjName}
                prjNo={prjNo}
                sVer={sVer}
                type="generateDo"
            />
        )
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            生成数据对象
        </Button>
    )
}
