import {Button} from 'antd'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import useCurrentProject from '@/biz/useCurrentProject.mjs'

export default function ButtonSetAsCurrentPrj(props) {
    const map = useMapContext()
    const [node] = map.useSelectedNodes()
    const [, setCurrentProject] = useCurrentProject()

    const handleClickDetail = async () => {
        const {
            prjId: pkid,
            prjName,
            prjNo,
            sVer,
            teamId,
        } = node.data

        const prj = {
            pkid,
            prjName,
            prjNo,
            sVer,
            teamId,
        }

        await runAsync(
            () => setCurrentProject(prj),
            {action: '设置当前项目'},
        )
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClickDetail}
            {...props}
        >
            设为当前项目
        </Button>
    )
}
