import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Model from './ModelBranch.mjs'

const PropertiesPaneBranch = (props) => (
    <NodePropertiesPane
        Model={Model}
        {...props}
    >
        <Text prop="pblName" />
        <Text prop="pkid" />
        <Text prop="sVer" />
    </NodePropertiesPane>
)

export default PropertiesPaneBranch
