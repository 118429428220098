import React from 'react';
import { Button, Form, Input, Select, Space, Radio } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

const SsData = () => {

}

SsData.ssUserCode = () => {
    const rules = [
        {
            required: true,
            message: '请输入子系统代码',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="ssUserCode" rules={rules} label="子系统代码">
            <Input size="middle" placeholder="请输入子系统代码" />
        </Form.Item>
    );
}

SsData.ssName = () => {
    const rules = [
        {
            required: true,
            message: '请输入子系统名称',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="ssName" rules={rules} label="子系统名称">
            <Input size="middle" placeholder="请输入子系统名称" />
        </Form.Item>
    );
}

SsData.ssTypeCode = () => {
    const rules = [
        {
            required: true,
            message: '请选择子系统类型',
        },
    ];

    return (
        <Form.Item name="ssTypeCode" rules={rules} label="子系统类型">
            <Select placeholder="请选择子系统类型">
                <Option value="GS">一般子系统</Option>
                <Option value="UIS">用户界面子系统</Option>
                <Option value="FCS">服务子系统</Option>
            </Select>
        </Form.Item>
    );
}

SsData.isUi = () => {
    const rules = [
        {
            required: true,
            message: '请选择子系统类型',
        },
    ];

    return (
        <Form.Item name="isUi" rules={rules} label="子系统类型" initialValue={'0'}>
            <Select placeholder="请选择子系统类型">
                <Option value="1">界面</Option>
                <Option value="0">服务</Option>
                <Option value="2">程序库</Option>
            </Select>
        </Form.Item>
    );
}

SsData.memo = () => {
    const rules = [
        {
            max: 2000,
            message: '最长2000位',
        },
    ];

    return (
        <Form.Item name="memo" rules={rules} label="备注">
            <Input.TextArea placeholder="请输入备注" rows="4" />
        </Form.Item>
    );
}

SsData.SaveButton = ({ onCancel, onSave }) => {
    return (
        <Space>
            <Button size="middle" type="primary" ghost onClick={onCancel}>取消</Button>
            <Button htmlType="submit" type="primary" size="middle" icon={<SaveOutlined />} onClick={onSave}>保存</Button>
        </Space>
    );
}

export default SsData;