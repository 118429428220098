import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space, Row, Col, Typography, Tooltip, Radio, Select, Tag, } from 'antd';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SsData from './SsData';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import openWindow from "@/script/openWindow.mjs";
import { writeFileToDisk } from "@/script/file.mjs";
import SelectAgTemplateGroupDialog from "@/pages/MainPage/dialogs/SelectAgTemplateGroupDialog";
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

const { Text, Paragraph } = Typography;
const FormLabel = ({ label, hint }) => {

    return (
        <Space>
            <span>{label}</span>
            {
                hint &&
                <Tooltip title={hint}>
                    <Text strong type='secondary' style={{ cursor: 'pointer' }}>
                        <InfoCircleOutlined />
                        {/*<InfoCircleFilled/>*/}
                    </Text>
                </Tooltip>
            }
        </Space>
    )
}

export default function ModalCreateSs({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();
    const tplConfigMode = Form.useWatch('tplConfigMode', form)
    const agTplGroupId = Form.useWatch('agTplGroupId', form)
    const isUi = Form.useWatch('isUi', form)
    const [tplGroups, setTplGroups] = useState([])
    // const [frameworkModules, setFrameworkModules] = useState([])
    // 数据模型
    const [prj, setPrj] = useState({});

    // 加载显示
    const [visiblePrj, setVisiblePrj] = useState(false); // 项目 Dialog

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    // 组件初始化
    useEffect(() => {
        doQueryTplGroups();
    }, [isUi]);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name, tplConfigMode: 'platform' });

    }

    const doAdd = async () => {
        try {
            const formField = await form.validateFields();
            formField.prjId = prj.prjId;
            formField.sVer = prj.sVer;
            formField.agCustomTplFlag = false;
            formField.agCopyPrjId = null;
            formField.agCopySVer = null;
            formField.agCopySsCode = null;

            // const {tplConfigMode, agTplGroupId,} = formField;
            //
            // if (tplConfigMode == 'platform') {
            //     formField.agCustomTplFlag = false;
            //     formField.agCopyPrjId = null;
            //     formField.agCopySVer = null;
            //     formField.agCopySsCode = null;
            // }
            //
            // if (tplConfigMode == 'custom') {
            //     formField.agCustomTplFlag = true;
            //     formField.agCopyPrjId = null;
            //     formField.agCopySVer = null;
            //     formField.agCopySsCode = null;
            // }
            //
            // //TODO 预留，以后扩展时处理
            // if (tplConfigMode == 'fromss') {
            //     formField.agCustomTplFlag = null;
            //     formField.agCopyPrjId = null;
            //     formField.agCopySVer = null;
            //     formField.agCopySsCode = null;
            // }


            const data = await runAsync(
                async () => {
                    return await http.post(`/archsss`, formField);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            onOk(data)
            openWindow('/SsDetail', { ssId: data.pkid })
        } catch (err) {
            console.log(err);
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }
    }

    //查询平台代码模板组
    const doQueryTplGroups = async () => {
        try {
            // const {isUi} = form.getFieldsValue();
            // const data = await runAsync(
            //     async () => {
            //         return await http.get('/basAgTemplateGroups', {tplGroupStsCode: 'RLS', scope: isUi});
            //     }, {
            //         error: {content: (err) => `查询失败: ${err.message}`},
            //         loading: {show: false},
            //     }
            // );
            // setTplGroups(data)
        } finally {

        }
    }

    const doPreview = async () => {
        const { ssUserCode, ssName, contextPath, mainPackage, agTplGroupId } = form.getFieldsValue();
        if (!ssUserCode) {
            Modal.info({ title: '提示信息', content: '子系统代码为空' });
            return
        }

        if (!ssName) {
            Modal.info({ title: '提示信息', content: '子系统名称为空' });
            return
        }

        if (!mainPackage) {
            Modal.info({ title: '提示信息', content: '子系统的主包名为空' });
            return
        }
        openWindow('/frameworkIDE', { ssUserCode, ssName, contextPath, mainPackage, agTplGroupId })
    }

    const doDownload = async () => {
        const { ssUserCode, ssName, contextPath, mainPackage, agTplGroupId } = form.getFieldsValue();
        if (!ssUserCode) {
            Modal.info({ title: '提示信息', content: '子系统代码为空' });
            return
        }

        if (!ssName) {
            Modal.info({ title: '提示信息', content: '子系统名称为空' });
            return
        }

        if (!mainPackage) {
            Modal.info({ title: '提示信息', content: '子系统的主包名为空' });
            return
        }
        try {
            const { blob, fileName } = await runAsync(async () => {
                return await http.post(`/generateFramework`, { ssCode: ssUserCode, ssName, contextPath, mainPackage, agTplGroupId });
            }, {
                error: { content: (err) => `下载脚手架失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            writeFileToDisk(blob, { suggestedName: fileName })
        } finally {
        }
    }
    // const doQueryFrameworkModules = async (agTplGroupId) => {
    //     try {
    //         const data = await runAsync(
    //             async () => {
    //                 return await http.get(`/basAgTemplateGroups/${agTplGroupId}/frameworkModules`);
    //             }, {
    //                 error: {content: (err) => `查询失败: ${err.message}`},
    //                 loading: {show: false},
    //             }
    //         );
    //         setFrameworkModules(data)
    //     } finally {
    //
    //     }
    // }

    return (
        <>
            <Modal
                title="新建子系统"
                okText="保存"
                cancelText="取消"
                width={900}
                footer={[
                    <Space>
                        <Permission permission="ARCH_SS_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} onFinish={doAdd} layout="vertical">
                    <Form.Item hidden name="tplConfigMode" >
                        <Input />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <SsData.ssUserCode />
                        </Col>

                        <Col span={8}>
                            <SsData.ssName />
                        </Col>

                        <Col span={8}>
                            <SsData.isUi />
                        </Col>

                        {
                            '1' == isUi &&
                            <Col span={8}>
                                <Form.Item name="terminalType" label="终端类型" initialValue={'PC'} rules={[{ required: true, message: '请选择终端类型' }]}>
                                    <Select placeholder="请选择终端类型">
                                        <Option value="PC">PC端</Option>
                                        <Option value="MOBILE">移动端</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        }

                        <Col span={8}>
                            <Form.Item
                                name="contextPath"
                                label={<FormLabel label='应用访问路径' hint='应用访问路径也可叫项目路径，是构成URL的一部分，例如：/identityServer' />}
                            >
                                <Input placeholder="for example: /bas" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="mainPackage"
                                // rules={[{required: true, message: '请输入主包名'}]}
                                label={<FormLabel label='主包名' hint='在设计项目分层时，我们通常会定义一个公共的包路径，在此之后再根据实际情况分子包，常见定义：com.公司名.产品名。' />}
                            >
                                <Input placeholder="for example: com.hedu.xxx" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="agTplGroupId"
                                // rules={[{required: true, message: '请选择平台代码模板'}]}
                                label={<FormLabel label='代码框架套件' hint='系统已为您默认定义了一些开箱即用的技术框架脚手架，你可以选择适合您的脚手架。' />}
                            >
                                <SelectAgTemplateGroupDialog scope={isUi} />
                                {/*<Select*/}
                                {/*    showSearch*/}
                                {/*    allowClear*/}
                                {/*    optionFilterProp="children"*/}
                                {/*    // onChange={(value) => {*/}
                                {/*    //     if (value) {*/}
                                {/*    //         doQueryFrameworkModules(value);*/}
                                {/*    //     } else {*/}
                                {/*    //         setFrameworkModules([])*/}
                                {/*    //     }*/}
                                {/*    // }}*/}
                                {/*    // onSearch={onSearch}*/}
                                {/*    placeholder="请选择"*/}
                                {/*    filterOption={(input, option) =>*/}
                                {/*        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())*/}
                                {/*    }*/}
                                {/*>*/}
                                {/*    {*/}
                                {/*        tplGroups &&*/}
                                {/*        tplGroups.map(({pkid, tplGroupName, tplGroupDesc}) => {*/}
                                {/*            return (*/}
                                {/*                <Select.Option value={pkid} label={tplGroupName}>*/}
                                {/*                    <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>*/}
                                {/*                        <Text>{tplGroupName}</Text>*/}
                                {/*                        <Paragraph ellipsis={{rows: 1}} style={{marginBottom: 0}}>*/}
                                {/*                            <Text type='secondary'>{tplGroupDesc}</Text>*/}
                                {/*                        </Paragraph>*/}
                                {/*                    </div>*/}
                                {/*                </Select.Option>*/}
                                {/*            )*/}
                                {/*        })*/}
                                {/*    }*/}
                                {/*</Select>*/}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label={<FormLabel label='预览代码框架套件' hint='当您选择具体的代码框架套件后，您可以选择在线预览，也可以选择下载到本地查看。' />}
                            >
                                <Space>
                                    <a onClick={doPreview} disabled={!agTplGroupId}>在线预览</a>
                                    <a onClick={doDownload} disabled={!agTplGroupId}>下载到本地</a>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            {/* 选择项目 */}
            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
