import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import Table from '@/components/Table/Table.jsx'
import apiMap from '../../apis/apiMap.mjs'

export default function TableVersions({
    dataId,
    dataType,
    prjId,
    onChange,
    ...props
}) {
    const {data, isLoading} = useSwrNoCache(
        () => apiMap.readVersions({dataId, dataType, prjId, rlsFlag: true}),
        {dependencies: [dataId, dataType, prjId]}
    )

    const rowSelection = {
        onChange: (_, selectedRows) => onChange(selectedRows[0]),
        type: 'radio',
    }

    const columns = [
        {
            dataIndex: 'dataName',
            title: '名称',
            component: <Table.ViewText />,
            width: 200,
        },

        {
            dataIndex: 'dataNo',
            title: '编号',
            component: <Table.ViewText />,
            width: 200,
        },

        {
            dataIndex: 'ver',
            title: '版本号',
            getValue: ({dataVer, sVer}) => `${sVer}.${dataVer}`,
            component: <Table.ViewText />,
            width: 80,
        },

        {
            title: '模型成品版本号',
            dataIndex: 'patternRevConcatString',
            component: <Table.ViewText />,
            width: 80,
        },

        {
            dataIndex: 'crtTime',
            title: '发布时间',
            component: <Table.ViewText />,
            width: 180,
        },

        {
            dataIndex: 'crtName',
            title: '发布人',
            component: <Table.ViewText />,
            width: 120,
        },

        {
            dataIndex: 'memo',
            title: '备注',
            component: <Table.ViewLongText />,
            width: 120,
        },
    ]

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            noRowNumber
            rowKey="pkid"
            rowSelection={rowSelection}
            selectRowOnClick
            {...props}
        />
    )
}
