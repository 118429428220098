import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'sysUserCode', 
    mapProp: 'sysMap',
    name: '系统',
    ownerIdProp: 'brfId',
    ownerTextProp: 'brfName',
    ownerTextProp: 'brfTitle',
    ownerType: 'BRF',
    textProp: 'sysName',
    type: 'BRFSYS',
}
