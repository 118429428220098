import React, { useEffect, useState, useRef } from 'react';
import { Form, Row, Col, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import openWindow from '@/script/openWindow.mjs';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectSsDialog from '@/pages/MainPage/dialogs/SelectSsDialogNew';
import SelectFmDialog from '@/pages/MainPage/dialogs/SelectFmDialogNew';
import SelectVmDialog from '@/pages/MainPage/dialogs/SelectVmDialogNew';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateFc({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();
    const openModal = useOpenModal();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        ref.current = {};
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer, prjTypeCode });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    const doAdd = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();
            formData.prjId = prj.prjId;
            formData.sVer = prj.sVer;
            formData.isSi = '0';
            formData.fmCode = ref.current.fmCode;
            formData.ssCode = ref.current.ssCode;
            formData.vmCode = ref.current.vmCode;

            const data = await runAsync(
                async () => {
                    return await http.post(`/fmfcs`, formData);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            onOk(data)
            openWindow('/FcDetail', { fcId: data.pkid }); // 跳转
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调：设置项目
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.fmCode = null;
        ref.current.ssCode = null;
        ref.current.vmCode = null;
        form.setFieldsValue({ fmName: '', ssName: '', vmName: '' });
    }

    // 回调：设置功能域
    const callFm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectFmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.fmCode = result.fmCode;
            form.setFieldsValue({ fmName: result.fmName });
        }
    }

    // 回调：设置子系统
    const callSs = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectSsDialog params={{ prjId, sVer }} />);

        if (result) {
            if (result.ssCode != ref.current.ssCode) {
                ref.current.vmCode = null;
                form.setFieldsValue({ vmName: '' });
            }

            ref.current.ssCode = result.ssCode;
            form.setFieldsValue({ ssName: result.ssName });
        }
    }

    // 回调：设置程序域
    const callVm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const ssCode = ref.current.ssCode;
        const result = await openModal(<SelectVmDialog params={{ prjId, sVer, ssCode }} />);

        if (result) {
            ref.current.ssCode = result.ssCode;
            ref.current.vmCode = result.vmCode;
            form.setFieldsValue({ ssName: result.ssName, vmName: result.vmUserCode });
        }
    }

    return (
        <>
            <Modal
                width={800}
                title={`新建程序能力`}
                okText="保存"
                cancelText="取消"
                bodyStyle={{ padding: '12px 24px 2px' }}
                footer={[
                    <Space>
                        <Permission permission="FC_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical" labelAlign="right"
                // labelCol={{ style: { width: '80px', height: '30px' } }}
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]} >
                                <Input placeholder="请选择项目" readOnly addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="fmName" label="功能域">
                                <Input placeholder="请选择功能域" readOnly addonAfter={<SearchOutlined onClick={callFm} />} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="ssName" label="子系统">
                                <Input placeholder="请选择子系统" readOnly addonAfter={<SearchOutlined onClick={callSs} />} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="vmName" label="程序域">
                                <Input placeholder="请选择程序域" readOnly addonAfter={<SearchOutlined onClick={callVm} />} />
                            </Form.Item>
                        </Col>

                        {/* <Col span={8}>
                            <Form.Item name="fcType" label="API类型" rules={[{ required: true, message: '请选择API类型!' }]}>
                                <Select placeholder="请选择" onChange={value => {
                                    if ('other' != value) {
                                        let prefix = value.split('Obj')[0];

                                        // 名称
                                        let fcName = '';
                                        if (value == 'listObj') fcName = '组合查询';
                                        if (value == 'oneObj') fcName = 'ID查询';
                                        if (value == 'addObj') fcName = '新增';
                                        if (value == 'modifyObj') fcName = '修改';
                                        if (value == 'removeObj') fcName = '删除';
                                        if (value == 'submitObj') fcName = '提交';
                                        if (value == 'cancelObj') fcName = '撤回';
                                        if (value == 'acceptObj') fcName = '受理';
                                        if (value == 'returnObj') fcName = '退回';
                                        if (value == 'evObj') fcName = '审核';
                                        if (value == 'eaObj') fcName = '审批';
                                        if (value == 'reviseObj') fcName = '变更';
                                        if (value == 'submitRevObj') fcName = '提交变更';
                                        if (value == 'saveObjList') fcName = '批量保存';
                                        if (value == 'cloneObj') fcName = '克隆';

                                        form.setFieldsValue({ fcUserCode: prefix, fcName: fcName });

                                        if (1 == ref.current.isFcPgCode) {
                                            form.setFieldsValue({ fcPgCode: prefix });
                                        }

                                        if (1 == ref.current.isReqUrl) {
                                            form.setFieldsValue({ reqUrl: '/' + prefix });
                                        }
                                    }
                                }}>
                                    <Option value="listObj">组合查询/listObj</Option>
                                    <Option value="oneObj">ID查询/oneObj</Option>
                                    <Option value="addObj">新增Obj/addObj</Option>
                                    <Option value="modifyObj">修改Obj/modifyObj</Option>
                                    <Option value="removeObj">删除Obj/removeObj</Option>
                                    <Option value="submitObj">提交Obj/submitObj</Option>
                                    <Option value="cancelObj">撤回Obj/cancelObj</Option>
                                    <Option value="acceptObj">受理Obj/acceptObj</Option>
                                    <Option value="returnObj">退回Obj/returnObj</Option>
                                    <Option value="evObj">审核Obj/evObj</Option>
                                    <Option value="eaObj">审批Obj/eaObj</Option>
                                    <Option value="reviseObj">变更Obj/reviseObj</Option>
                                    <Option value="submitRevObj">提交变更Obj/submitRevObj</Option>
                                    <Option value="saveObjList">批量保存Obj/saveObjList</Option>
                                    <Option value="cloneObj">克隆Obj/cloneObj</Option>
                                    <Option value="other">其他</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <Form.Item name="fcUserCode" label={`程序能力代码`} rules={[{ required: true, message: `请输入程序能力代码` }]}>
                                <Input placeholder={`请输入程序能力代码`} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="fcName" label={`程序能力名称`}>
                                <Input placeholder={`请输入程序能力名称`} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
