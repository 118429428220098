import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useArchNode from '../_ARCH/useArchNode.jsx'
import meta from './metaCa.mjs'
import PropertiesPane from './PropertiesPaneCa.jsx'
import api from './apiCa.mjs'

export default () => {
    const ArchNode = useArchNode()

    return extendNode(ArchNode, {
        ...meta,
        api,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {
                backgroundColor: Color.GOLD,
            })
        },

        _pushDataSlots: {
            archCmList: [],
        },
    })
}
