import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Space, Radio } from 'antd';
import { SearchOutlined, } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import SelectDataTypeDialog from '@/pages/MainPage/dialogs/SelectDataTypeDialog';
import Permission from '@/components/Permission.jsx';
import openWindow from '@/script/openWindow.mjs';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateCdt({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();

    // 数据模型
    const [prj, setPrj] = useState({});
    const [dataType, setDataType] = useState({});

    // 加载显示
    const [expand, setExpand] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false); // 项目 Dialog
    const [visibleDataType, setVisibleDataType] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name, isSeq: "1", lockFlag: "0" });
    }

    const doAdd = async () => {
        try {
            form.validateFields().then(async values => {
                const param = {
                    ...values,
                    prjId: prj.prjId,
                    sVer: prj.sVer,
                    bdtCode: dataType.bdtCode,
                }

                const data = await runAsync(
                    async () => {
                        return await http.post(`/dmcdts`, param);
                    }, {
                    error: { content: (err) => `新增失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: true },
                });

                onOk(data)
                openWindow('/DmCdtDetail', { cdtId: data.pkid });
            });
        } catch (err) {
            console.log(err);
        }
    }

    // 处理回调
    const doCall = (value) => {
        if (value) {
            setDataType(value);
            form.setFieldsValue({ bdtName: value.bdtName });
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }
    }

    // 操作
    const doAction = (data) => {
        fetch(data.url, data.options).then(res => res.json()).then(response => {
            if (200 == response.code) {
                doSearch();
                // 清空checkbox
                setSelectedRows([]);
            } else {
                Modal.error({ title: '提示信息', content: response.errorBody.errorMessage });
            }
        });
    }

    // 选中事件
    const onChange = (e) => {
        if (e.target.value == '1') {
            setExpand(true);
        } else {
            setExpand(false);
        }
    }

    return (
        <>
            <Modal
                width={1000}
                title="新建CDT"
                okText="保存"
                cancelText="取消"
                footer={[
                    <Space>
                        <Permission permission="CDT_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical">
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name="prjName"
                                label="项目"
                                rules={[{ required: true, message: '请选择项目!' }]}
                            >
                                <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="cdtUserCode"
                                label="CDT类型代码"
                                rules={[{ required: true, message: '请输入CDT类型代码!' }]}
                            >
                                <Input placeholder="请输入CDT类型代码" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="cdtName"
                                label="CDT类型名称"
                                rules={[{ required: true, message: '请输入CDT类型名称!' }]}
                            >
                                <Input placeholder="请输入CDT类型名称" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="bdtName"
                                label="基本数据类型"
                                rules={[{ required: true, message: '请选择基本数据类型!' }]}>
                                <Input placeholder="请选择基本数据类型" addonAfter={<SearchOutlined onClick={() => setVisibleDataType(true)} />} readOnly />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="cdtLen"
                                label="长度"
                            >
                                <Input placeholder="请输入长度" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="cdtPrecision"
                                label="精度"
                            >
                                <Input placeholder="请输入精度" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                name="cdtCat"
                                label="类别"
                            >
                                <Input placeholder="请输入类别" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="lockFlag"
                                label="锁定"
                                rules={[{ required: true, message: '请选择是否锁定' }]}
                            >
                                <Radio.Group >
                                    <Radio value={'0'} >否</Radio>
                                    <Radio value={'1'}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="isSeq"
                                label="是否序列"
                                rules={[{ required: true, message: '请选择是否序列' }]}
                            >
                                <Radio.Group onChange={onChange}>
                                    <Radio value={'0'} >否</Radio>
                                    <Radio value={'1'}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        {/* 
                        <Col span={24} style={{ display: expand ? 'block' : 'none' }}>
                            <Card title="序列" style={{ background: '#fff', width: '100%' }}>
                                <EditableTable doCallData={doCallData} rows={rows} columnss={columns} />
                            </Card>
                        </Col> */}

                        <Col span={8}>
                            <Form.Item
                                name="cdtDesc"
                                label="说明"
                            >
                                <Input placeholder="请输入说明" rows={5} />
                            </Form.Item>
                        </Col>

                        {/* <Col span={24}>
                            <Form.Item name="memo" label="备注">
                                <Input.TextArea placeholder="请输入备注" rows={3} />
                            </Form.Item>
                        </Col> */}
                    </Row>
                </Form>
            </Modal>

            <SelectDataTypeDialog visible={visibleDataType} setVisible={setVisibleDataType} onCall={doCall} single={true} typeId={'bdt'} prjId={prj} />

            {/* 选择项目 */}
            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} selectItems={prj} />
        </>
    );
}
