import {useState} from 'react'
import http from '@/biz/http.mjs'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import Table from '@/components/Table/Table.jsx'
import openWindow from '@/script/openWindow.mjs'
import runAsync from '@/script/runAsync.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import MenuButton from '../../MenuButton.jsx'

const TableBaselines = ({prjId, onChange, ...props}) => {
    const {data, isLoading} = useSwrNoCache(
        () => runAsync(
            () => http.get(
                '/prjbaselines',

                {
                    //pblType: 'BASELINE',
                    pblStsCodes: 'ACTIVE',
                    prjId,
                }
            ),

            {action: '读取基线分支'},
        ),

        {dependencies: [prjId]},
    )

    const columns = [
        {
            title: '分支名称',
            dataIndex: 'pblName',
            component: <Table.ViewText />,
        },

        {
            title: '分支版本',
            dataIndex: 'sVer',
            component: <Table.ViewText />,
        },
    ]

    const rowSelection = {
        defaultSelect: rows => 0 < rows.length ? [rows[0].pkid] : [],
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: 'radio',
    }

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            rowKey="pkid"
            rowSelection={rowSelection}
            selectRowOnClick
            {...props}
        />
    )
}

const ModalChooseBaseline = ({prjId, onOk, ...props}) => {
    const [baseline, setBaseline] = useState()
    const okButtonProps = {disabled: ! baseline}
    const onTableChange = rows => setBaseline(rows[0])
    const _onOk = () => onOk(baseline)

    return (
        <Modal
            okButtonProps={okButtonProps}
            title="选择基线分支"
            onOk={_onOk}
            {...props}
        >
            <TableBaselines
                prjId={prjId}
                onChange={onTableChange}
            />
        </Modal>
    )
}

export default function MenuButtonCompare(props) {
    const openModal = useOpenModal()
    const map = useMapContext()
    const {mapTypeCode, pblType, prjId, sVer: srcSVer} = map.data

    if (! (
        'SA_MAP' === mapTypeCode &&
        'INCREMENT' === pblType
    )) {
        return null
    }

    ///prjbaselines?prjId=xx&pblType=BASELINE&pblStsCodes=ACTIVE
    const onClick = async () => {
        const b = await openModal(<ModalChooseBaseline prjId={prjId} />)

        if (b) {
            openWindow('/MergeCommonList', {prjId, srcSVer, tarSVer: b.sVer})
        }
    }

    return (
        <MenuButton
            onClick={onClick}
            {...props}
        >
            分支比对
        </MenuButton>
    )
}
