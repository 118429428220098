import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Upload, Space, Button, Popover, Tabs, Badge, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import FileUtils from '@/script/FileUtils';
import Modal from '@/components/Modal/Modal';
import usePermission from '@/hooks/usePermission.mjs';
import { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';

const Pane = ({ prjId, sVer, prjNo, prjName, fileIds, pkids, type, onCall, onOk, onCancel, ...props }) => {
    const http = useHTTP();
    const { TabPane } = Tabs;
    const [form] = Form.useForm();

    const ref = useRef({});
    const [bds, setBds] = useState([]);
    const [sss, setSss] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bdLoading, setBdLoading] = useState(false);
    const [ssLoading, setSsLoading] = useState(false);

    // 多文件上传
    const isMultiple = ['importBdRuleStepForFiles'].includes(type);
    // 不需要上传文件
    const noFile = ['generateDo', 'importBdRuleForAi', 'importBdRuleStepForAi', 'importBdRuleStepForAiFiles', 'importUiAlgForAi'].includes(type);
    const hasBd = ['generateDo', 'importBdRule', 'importBdRuleStep', 'importBdRuleStepForFiles', 'importBdRuleForAi', 'importBdRuleStepForAi', 'importBdRuleStepForAiFiles'].includes(type);
    const hasSs = ['importBd', 'importUi', 'importUiAlg', 'importUiAlgForAi'].includes(type);

    // 导入权限
    const crtBf = usePermission(['BF_CRT'], [prjId + sVer], []);
    const crtBd = usePermission(['BD_CRT'], [prjId + sVer], []);
    const uptBd = usePermission(['BD_UPT'], [prjId + sVer], []);
    const crtSf = usePermission(['SF_CRT'], [prjId + sVer], []);
    const crtUi = usePermission(['UI_CRT'], [prjId + sVer], []);
    const uptUi = usePermission(['UI_UPT'], [prjId + sVer], []);
    const crtSs = usePermission(['ARCH_SS_CRT'], [prjId + sVer], []);

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        ref.current = {};

        // 业务流程
        if ('importBfForPm' == type) {
            ref.current.perm = crtBf;
            ref.current.url = '/bmbfs/';
            ref.current.title = '导入业务流程(BODA2)'; // BODA2：流程域
        } else if ('importBfForBm' == type) {
            ref.current.perm = crtBf;
            ref.current.url = '/bmbfs/';
            ref.current.title = '导入业务流程(BODA1)'; // BODA1：业务域
        }
        // 业务能力模块
        else if ('importBd' == type) {
            ref.current.perm = crtBd;
            ref.current.url = '/reverse/';
            ref.current.title = '导入业务能力模块';
            selectSs();
        } else if (['importBdRule', 'importBdRuleForAi'].includes(type)) {
            ref.current.perm = uptBd;
            ref.current.url = '/reverse/';
            ref.current.title = '导入业务事务能力清单';
            selectBd();
        } else if (['importBdRuleStep', 'importBdRuleStepForAi'].includes(type)) {
            ref.current.perm = uptBd;
            ref.current.url = '/reverse/';
            ref.current.title = '导入业务事务能力步骤清单';
            selectBd();
        } else if (['importBdRuleStepForFiles', 'importBdRuleStepForAiFiles'].includes(type)) {
            ref.current.perm = uptBd;
            ref.current.url = '/reverse/';
            ref.current.title = '导入业务事务能力';
            selectBd();
        } else if ('generateDo' == type) {
            ref.current.perm = uptBd;
            ref.current.url = '/reverse/';
            ref.current.title = '生成数据对象';
            selectBd();
        }
        // 系统功能
        else if ('importSfForUi' == type) {
            ref.current.perm = crtSf;
            ref.current.url = '/sf/';
            ref.current.title = '导入交互功能用例';
        }
        // 用户界面
        else if ('importUi' == type) {
            ref.current.perm = crtUi;
            ref.current.url = '/umuis/';
            ref.current.title = '导入用户界面';
            selectSs();
        } else if (['importUiAlg', 'importUiAlgForAi'].includes(type)) {
            ref.current.perm = uptUi;
            ref.current.url = '/umuis/';
            ref.current.title = '导入用户界面'; // 界面能力步骤清单
            selectSs();
        }
        // 子系统
        else if ('importSs' == type) {
            ref.current.perm = crtSs;
            ref.current.url = '/archsss/';
            ref.current.title = '导入子系统';
        }

        const name = await formatName({ prjId, prjName, sVer });
        setFileList([]);
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    // 查询：业务能力模块/服务
    const selectBd = async () => {
        try {
            if (prjId && sVer) {
                setBdLoading(true);
                const data = await runAsync(
                    async () => {
                        return await http.get('/dmbds', { prjId, sVer });
                    }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

                const options = [];

                for (const bd of data) {
                    options.push({ value: bd.bdCode, label: bd.bdNo + '/' + bd.bdName });
                }

                setBds(options);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setBdLoading(false);
        }
    }

    // 查询：子系统
    const selectSs = async () => {
        try {
            if (prjId && sVer) {
                setSsLoading(true);
                const data = await runAsync(
                    async () => {
                        return await http.get('/archsss', { prjId, sVer });
                    }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

                const options = [];

                for (const ss of data) {
                    options.push({ value: ss.ssCode, label: ss.ssUserCode + '/' + ss.ssName });
                }

                setSss(options);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setSsLoading(false);
        }
    }

    // 导入：模型制品
    const importDp = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();

            if (fileList.length == 0 && !noFile) {
                return Modal.info({ content: '请选择上传文件' });
            }

            const url = ref.current.url + type;
            const param = { prjId, sVer, files: fileList };
            let header = { headers: { 'Content-Type': 'multipart/form-data' } };

            // 业务能力模块
            param.bdCode = formField.bdCode;

            // 子系统
            param.ssCode = formField.ssCode;

            // AI生成文件id
            if (fileIds) {
                param.fileIds = fileIds;
            }

            // 库表域id
            if (pkids) {
                param.pkids = pkids;
            }

            const data = await runAsync(
                async () => {
                    return await http.post(url, param, header);
                }, {
                error: { content: (err) => `导入失败: ${err.message}` },
                loading: { show: true },
                success: { show: true, content: () => '导入成功' },
            });

            onOk();
            doInfo(data); // 打印导入日志

            if (onCall) {
                onCall();
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const BadgeExt = ({ name, count }) => {
        return <Badge size="small" offset={[10, 0]} count={count}>{name}</Badge>;
    }

    const doInfo = (data) => {
        // 新增/修改
        const adds = data?.adds;
        const upts = data?.upts;

        // 业务流程
        const bfs = data?.bfs;

        // 业务能力模块/服务
        const bds = data?.bds;
        const svrs = data?.svrs;
        const rules = data?.rules;

        // 系统功能
        const sfs = data?.sfs;

        // 用户界面
        const uis = data?.uis;

        // 子系统
        const sss = data?.sss;

        // 通用
        const infos = data?.infos;
        const invalids = data?.invalids;
        const repeats = data?.repeats;
        const exists = data?.exists;
        const notExists = data?.notExists;

        return Modal.info({
            width: 800,
            title: '导入结果',
            content: <>
                <Tabs type="list" style={{ overflow: 'hidden' }}>
                    {
                        adds && adds.length > 0 &&
                        <TabPane key="adds" tab={<BadgeExt name="新增的记录" count={adds.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {adds.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        upts && upts.length > 0 &&
                        <TabPane key="upts" tab={<BadgeExt name="修改的记录" count={upts.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {upts.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        bfs && bfs.length > 0 &&
                        <TabPane key="bfs" tab={<BadgeExt name="业务流程" count={bfs.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {bfs.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        bds && bds.length > 0 &&
                        <TabPane key="bds" tab={<BadgeExt name="业务能力模块" count={bds.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {bds.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        svrs && svrs.length > 0 &&
                        <TabPane key="svrs" tab={<BadgeExt name="业务服务" count={svrs.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {svrs.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        rules && rules.length > 0 &&
                        <TabPane key="rules" tab={<BadgeExt name="业务事务能力" count={rules.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {rules.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        sfs && sfs.length > 0 &&
                        <TabPane key="sfs" tab={<BadgeExt name="系统功能" count={sfs.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {sfs.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        uis && uis.length > 0 &&
                        <TabPane key="uis" tab={<BadgeExt name="用户界面" count={uis.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {uis.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        sss && sss.length > 0 &&
                        <TabPane key="sss" tab={<BadgeExt name="子系统" count={sss.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {sss.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        invalids && invalids.length > 0 &&
                        <TabPane key="invalids" tab={<BadgeExt name="不合法的记录" count={invalids.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {invalids.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        repeats && repeats.length > 0 &&
                        <TabPane key="repeats" tab={<BadgeExt name="重复的记录" count={repeats.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {repeats.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        exists && exists.length > 0 &&
                        <TabPane key="exists" tab={<BadgeExt name="已存在的记录" count={exists.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {exists.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        notExists && notExists.length > 0 &&
                        <TabPane key="notExists" tab={<BadgeExt name="不存在的记录" count={notExists.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {notExists.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }

                    {
                        infos && infos.length > 0 &&
                        <TabPane key="infos" tab={<BadgeExt name="信息" count={infos.length} />}>
                            <div style={{ listStyle: 'none', height: '300px', overflow: 'auto' }}>
                                {infos.map(v => <div>{v}</div>)}
                            </div>
                        </TabPane>
                    }
                </Tabs>
            </>
        });
    }

    // 权限控制
    const perm = () => {
        return ref.current.perm ?? false;
    }

    const download = async (type) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/api/download?type=` + type);
                }, {
                error: { content: (err) => `下载失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            FileUtils.saveFile(data.blob, data.fileName);
        } catch (err) {
            console.log(err);
        }
    }

    const useStep = <ol>
        <li>能力清单：业务事务能力清单；</li>
        <li>步骤清单：业务事务能力步骤清单；</li>
        <li>可分别导入能力清单、步骤清单；</li>
        <li>可一次导入能力清单与步骤清单；</li>
    </ol>;

    return (
        <Modal
            title={ref.current.title}
            okText="确定"
            cancelText="取消"
            width="600px"
            footer={[
                <Space>
                    <Button onClick={onCancel}>取消</Button>
                    <Button type="primary" onClick={importDp} loading={loading} disabled={!perm()}>确定</Button>
                </Space>
            ]}
            {...props}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="prjName" label="项目名称" rules={[{ required: true }]}>
                    <Input disabled />
                </Form.Item>

                {
                    hasBd &&
                    <Form.Item name="bdCode" label="业务能力模块" rules={[{ required: true, message: '请选择业务能力模块' }]}>
                        <Select
                            showSearch
                            loading={bdLoading}
                            options={bds}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                }

                {
                    hasSs &&
                    <Form.Item name="ssCode" label="子系统">
                        <Select
                            showSearch
                            loading={ssLoading}
                            options={sss}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                }

                {
                    !noFile &&
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Upload
                            accept=".xls,.xlsx"
                            fileList={fileList}
                            multiple={isMultiple}
                            showUploadList={true}
                            beforeUpload={(file, files) => {
                                if (isMultiple) {
                                    setFileList([...fileList, ...files]);
                                } else {
                                    setFileList(files);
                                }

                                return false;
                            }}
                            onRemove={(file) => {
                                // setFileList([]);
                                if (file) {
                                    const notDeletes = fileList.filter(f => f.name != file.name);
                                    setFileList([...notDeletes]);
                                }
                            }}
                        >
                            <div className="ant-upload-btn bg-grey-light">
                                <PlusOutlined />
                                <div style={{ marginTop: 4 }}>上传</div>
                            </div>
                        </Upload>

                        <Space>
                            {
                                'importBdRuleStepForFiles' == type ?
                                    <>
                                        <a onClick={() => download('importBdRule')}>下载能力清单</a>
                                        <a onClick={() => download('importBdRuleStep')}>下载步骤清单</a>

                                        <Popover
                                            content={useStep}
                                            title="业务事务能力导入步骤"
                                            trigger="click"
                                            open={open}
                                            onOpenChange={setOpen}
                                        >
                                            <a>使用说明</a>
                                        </Popover>
                                    </>
                                    :
                                    <a onClick={() => download(type)}>下载模板</a>
                            }
                        </Space>
                    </div>
                }
            </Form>
        </Modal>
    );
}

export default Pane;