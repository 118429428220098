import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaUe_.mjs'
import FormChoose from './FormChooseUe_.jsx'
import TableChoose from './TableChooseUe_.jsx'
import api from './apiUe_.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,

        async choose(map, node) {
            const getQuery = query => {
                const {bizNodeType, ssCode, uiUserCode} = map.root.data

                if ('UI' === bizNodeType) {
                    return {ssCode, uiUserCode, ...query}
                }
                else {
                    return query
                }
            }

            const items = await this._choose(map, node, {getQuery})

            return items.map(e => {
                const {algType} = e.data

                if (algType) {
                    return {
                        ...e,

                        data: {
                            ...e.data,

                            bizNodeType: {
                                GENERAL: 'UIALG',
                                UE: 'UE',
                            }[e.data.algType],
                        },
                    }
                }
                else {
                    return e
                }
            })
        },
    })
}
