import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'fmUserCode',
    mapIdProp: 'sfMapId',
    mapStsCodeProp: 'sfMapStsCode',
    mapTypeCode: 'SF_MAP',
    name: '功能域',
    ownerIdProp: 'faId',
    ownerTextProp: 'faName',
    ownerType: 'FA',
    textProp: 'fmName',
    type: 'FM',
}
