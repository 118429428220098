import {toProductMap} from '../../compatibility.mjs'
import extendNode from '../extendNode.mjs'
import IconLetters from '../icons/IconLetters.jsx'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaBd.mjs'
import Model from './ModelBd.mjs'
import PropertiesPane from './PropertiesPaneBd.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchBd.jsx'
import FormChoose from './FormChooseBd.jsx'
import TableChoose from './TableChooseBd.jsx'
import api from './apiBd.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        get updateMigrate() {
            const {updateMigrate} = this.api

            if (! updateMigrate) {
                return void 0
            }

            return async (map, node) => {
                const mapName = this.getText(map, node)
                const root = this.exportTree(map, node)

                const mapData = {
                    data: {...map.data, mapName},
                    root,
                }

                const migrateMap = JSON.stringify(toProductMap(mapData))

                const {
                    [this.mapProp]: _1,
                    migrateMap: _2,
                    ...updates
                } = await updateMigrate({
                    ...node.data,
                    mapSize: migrateMap.length,
                    migrateMap,
                    nodeQty: node.descendantCount + 1,
                })

                return updates
            }
        },

        canLinkType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(UI)$/.test(type)
            }
            else {
                false
            }
        },

        canMountType(map, node, type) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return /^(CAT|MARK)$/.test(type)
            }
            else {
                return ProductNode.canMountType.call(this, map, node, type)
            }
        },

        async choose(map, node) {
            const getQuery = ({bms = [], ss, ...query}) => ({
                ...query,
                bmIds: bms.map(({bmId}) => bmId),
                ssId: ss?.ssId,
            })

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return 'UI'
            }
            else {
                return ''
            }
        },

        getIcons(map, node) {
            const {bdType} = node.data

            if ('1' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="A"
                        textColor="#000"
                    />
                ]
            }
            else if ('3' === bdType) {
                return [
                    <IconLetters
                        key="type"
                        fill="#fff"
                        letters="D"
                        textColor="#000"
                    />
                ]
            }
            else {
                return []
            }
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_YELLOW,
                }),

                shape: 'SingleBreakangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            if ('BDM_MAP' === map.data?.mapTypeCode) {
                return [
                    ['CAT'],
                    ['MARK'],
                ]
            }
            else {
                return []
            }
        },

        menuItemsInsertProduct(map, node) {
            if ('BDM_MAP' === map.data.mapTypeCode) {
                return [
                    ['UI'],
                ]
            }
            else {
                return []
            }
        },

        _pushDataSlots: {
            algList: [],
            eventList: [],
            ruleList: [],
            sfList: [],
            termList: [],
        },

        async _atAttach(map, node, event) {
            await ProductNode._atAttach.call(this, map, node, event)

            if (event.target === node) {
                await this._onInsert(map, node)
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    bmCode,
                    bmId,
                    bmName,
                } = n.data

                if ('CAT_ROOT_BM' === bizNodeType) {
                    node.data = {
                        ...node.data,
                        bmCode,
                        bmId,
                        bmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        _getCategories(map, node) {
            return [
                'BD_TERM',
                'BD_RULE',
                'BD_SUMMARY',
                'BD_LOGIC',
                'BD_EVENT',
                'BD_STRUCT',
                'BD_RELATION',
                'BD_DEVELOP',
                'BD_BASE',
                'BD_SU',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ProductNode._getDefaultDataFields.call(this, map, node),
                bdNo: 'BO',
                bdType: '2',
            }
        },

        async _grow(map, node, depth) {
            const newDepth = await ProductNode._grow.call(
                this, map, node, depth
            )

            if (newDepth === depth) {
                return depth
            }

            const {mapTypeCode} = map.data

            if (/^(BIZ|DATA|DEP|FC|SA|SF|UI)_MAP$/.test(mapTypeCode)) {
                const {bdType} = node.data

                for (const n of [...node.children]) {
                    const {bizNodeType} = n.data

                    if (
                        (
                            '3' === bdType &&
                            'BD_DEVELOP' === bizNodeType
                        ) ||
                        (
                            '3' !== bdType &&
                            'BD_SUMMARY' === bizNodeType
                        )
                    ) {
                        map.deleteNode(n)
                    }
                    else {
                        map.deleteTree(n)
                    }
                }
            }

            return newDepth
        },

        async _onChange(map, node, event) {
            await ProductNode._onChange.call(this, map, node, event)

            if (event.target === node) {
                const oldData = event.detail

                if (node.data.bdType !== oldData.bdType) {
                    map.forceRerenderChildren(node)
                }
            }
        },

        async _readGrowTree(map, node) {
            const {mapTypeCode} = map.data

            if (
                'IS_MAP' === mapTypeCode ||

                (
                    'SA_MAP' === mapTypeCode &&

                    (() => {
                        for (const n of map.trueChain(node.parent)) {
                            const {bizNodeType} = n.data

                            if (bizNodeType !== 'BM') {
                                return 'CAT_DATA' === bizNodeType
                            }
                        }

                        return false
                    })()
                )
            ) {
                const {pkid} = node.data
                const rev = this.getRev(map, node)
                return this.readTree({pkid, rev, briefType: 'IS'})
            }
            else {
                return ProductNode._readGrowTree.call(this, map, node)
            }
        },
    })
}
