import http from '@/biz/http.mjs'

export default {
    publish: data => http.post('/ca/rlsArchCa', data),
    pull: ({pkid}) => http.get('/ca/loadDp', {pkid}),
    push: data => http.post('/ca/modifyDp', data),
    read: args => http.get('/ca/oneArchCa', args),
    revise: ({pkid}) => http.post('/ca/reviseArchCa', {pkid}),
    updateMap: data => http.post('/ca/saveMap', data),
}
