import ButtonCreateProduct from '@/biz/ButtonCreateProduct/ButtonCreateProduct.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import _Menu from '../../Menu.jsx'
import MenuButtonEdit from '../../menuButtons/MenuButtonEdit.jsx'
import MenuButtonInsertNode from '../../menuButtons/MenuButtonInsertNode.jsx'
import MenuButtonInsertProducts from '../../menuButtons/MenuButtonInsertProducts.jsx'
import MenuButtonCompare from './MenuButtonCompare.jsx'
import MenuButtonMap from './MenuButtonMap.jsx'
import MenuButtonPublishMap from './MenuButtonPublishMap.jsx'
import MenuButtonReviseMap from './MenuButtonReviseMap.jsx'
import MenuButtonSaveMap from './MenuButtonSaveMap.jsx'

export default function Menu(props) {
    const map = useMapContext()
    const {mapTypeCode, prjId, sVer} = map.data

    return (
        <_Menu {...props}>
            <MenuButtonMap />
            <MenuButtonEdit />
            <MenuButtonInsertNode type="common" />
            <MenuButtonInsertNode type="concept" />
            <MenuButtonInsertNode type="product" />
            <MenuButtonInsertProducts />
            <MenuButtonSaveMap />
            <MenuButtonPublishMap />
            <MenuButtonReviseMap />

            {
                'SA_MAP' === mapTypeCode &&

                <ButtonCreateProduct
                    prjId={prjId}
                    sVer={sVer}
                >
                    新建制品
                </ButtonCreateProduct>
            }

            <MenuButtonCompare />
        </_Menu>
    )
}
