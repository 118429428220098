import http from '@/biz/http.mjs'

export const canCopyNodeUrl = () => () => {
    return function (node) {
        return true
    }
}

export const copyNodeUrl = () => {
    return () => async function (nodes) {
        if (0 === nodes.length) {
            return
        }

        if (1 < nodes.length) {
            this.logger.warn(
                '只能拷贝单一节点的链接，忽略除第一个节点外的其余节点',
                nodes
            )
        }

        const [node] = nodes
        const {bizNodeType} = node.data

        const url = await (async () => {
            const url = await this.BizNode[bizNodeType].getUrl(this, node)

            try {
                const {shortUrl} = await http.post(
                    '/createBasSu', {suOlu: url}
                )

                return shortUrl
            }
            catch (err) {
                this.logger.warn(`生成短链接失败: ${err.message}`, nodes)
                return url
            }
        })()

        await navigator.clipboard.writeText(url)
        this.logger.info('节点链接已拷贝到剪贴板', [node])
    }
}
