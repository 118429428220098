import {useRef, useState} from 'react'
import {css} from '@emotion/react'
import {Collapse} from 'antd'
import InputText from '@/components/Form/InputText.jsx'
import {useReadOnly} from './ReadOnly.mjs'

const cssCollapse = css({
    '.ant-collapse-header': {
        lineHeight: 1,
        padding: '8px !important',
    },

    '.ant-collapse-content-box': {
        padding: 0,
    },
})

const cssOption = css({
    padding: '4px 8px',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: '#f5f5f5',
    },
})

const cssOptionHasIcon = css({
    display: 'grid',
    gridTemplateColumns: '1em 1fr',
    alignItems: 'center',
    gap: 12,
})

const cssOptionSelected = css({
    color: '#fff',
    backgroundColor: '#0099CC',

    '&:hover': {
        backgroundColor: '#0099CC',
    },
})

const Select = ({accordion = true, options, value, onChange, ...props}) => {
    const [activeKey, setActiveKey] = useState()
    const refLastValue = useRef()

    if (! options?.length) {
        return null
    }

    let hasIcon = false
    const groups = []

    for (const g of options) {
        const group = {
            ...g,
            hasIcon: false,
            key: g.key ?? g.label,
            options: [],
        }

        for (const o of g.options) {
            const option = {
                ...o,
                isSelected: false,
                key: o.key ?? o.value,
                label: o.label ?? o.value,
            }

            if (o.icon) {
                group.hasIcon = true
                hasIcon = true
            }

            if (o.value === value) {
                if (
                    activeKey !== group.key &&
                    value !== refLastValue.current
                ) {
                    setActiveKey(group.key)
                }

                option.isSelected = true
            }

            group.options.push(option)
        }

        groups.push(group)
    }

    const panels = groups.map(g => {
        const children = g.options.map(o => {
            const onClick = () => {
                if (! o.isSelected) {
                    onChange(o.value)
                }
            }

            const csss = [cssOption]

            if (o.isSelected) {
                csss.push(cssOptionSelected)
            }

            const icon = (() => {
                if (o.icon) {
                    return o.icon
                }
                else if (
                    g.hasIcon ||
                    (hasIcon && ! accordion)
                ) {
                    return <div></div>
                }
                else {
                    return null
                }
            })()

            if (icon) {
                csss.push(cssOptionHasIcon)
            }

            return (
                <div
                    css={csss}
                    key={o.key}
                    onClick={onClick}
                >
                    {icon}
                    {o.label}
                </div>
            )
        })

        return (
            <Collapse.Panel
                key={g.key}
                header={g.label}
            >
                {children}
            </Collapse.Panel>
        )
    })

    refLastValue.current = value

    return (
        <Collapse
            css={cssCollapse}
            accordion={accordion}
            activeKey={activeKey}
            expandIconPosition="end"
            onChange={setActiveKey}
            {...props}
        >
            {panels}
        </Collapse>
    )
}

export default function CollapseSelect({
    accordion = true,
    disabled,
    options,
    readOnly,
    value,
    onChange,
    ...props
}) {
    const gReadOnly = useReadOnly()

    if (gReadOnly || readOnly || disabled) {
        const label = (() => {
            for (const g of options) {
                for (const o of g.options) {
                    if (o.value === value) {
                        return o.label ?? o.value
                    }
                }
            }
        })()

        return (
            <InputText
                disabled={disabled}
                readOnly={gReadOnly || readOnly}
                value={label}
                {...props}
            />
        )
    }
    else {
        return (
            <Select
                accordion={accordion}
                options={options}
                value={value}
                onChange={onChange}
                {...props}
            />
        )
    }
}
