import {css} from '@emotion/react'
import usePermission from '@/components/TreeDoc/plugins/usePermission.mjs'
import useSidePanel from '@/components/TreeDoc/plugins/useSidePanel.mjs'
import {Canvas, MindMap} from '@/components/MindMap/index.mjs'
import defaultHotkeys from './hotkeys.mjs'
import {SMapDoc} from './SMapDoc.mjs'
import NodeInnerBox from './NodeInnerBox/NodeInnerBox.jsx'
import DefaultLeftPanel from './SidePanel/LeftPanel/LeftPanel.jsx'
import DefaultRightPanel from './SidePanel/RightPanel/RightPanel.jsx'
import DefaultStatusBar from './StatusBar/StatusBar.jsx'
import useEventBus from './plugins/useEventBus.mjs'
import useHideNode from './plugins/useHideNode.mjs'
import useInitMarkedNodes from './plugins/useInitMarkedNodes.mjs'
import useInitSidePanel from './plugins/useInitSidePanel.mjs'
import useInitSelectedNodes from './plugins/useInitSelectedNodes.mjs'
import useMarkNode from './plugins/useMarkNode.mjs'
import useMindMapPlugins from './plugins/useMindMapPlugins.mjs'
import useNodeProxy from './plugins/useNodeProxy.mjs'
import useRunAction from './plugins/useRunAction.mjs'
import useShowNodeDesc from './plugins/useShowNodeDesc.mjs'
import useMindMapExtensions from './plugins/useMindMapExtensions.mjs'

const cssMap = css({
    display: 'grid !important',
    width: '100%',
    height: '100%',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateColumns: 'auto 1fr auto',

    gridTemplateAreas: `
        "Top    Top    Top"
        "Left   Main   Right"
        "Bottom Bottom Bottom"
    `,

    backgroundColor: '#fff',
    overflow: 'hidden',
    userSelect: 'none',

    '*': {
        '&::-webkit-scrollbar': {
            width: 4,
            height: 4,
        },

        '&::-webkit-scrollbar-thumb': {
            borderRadius: 4,
            backgroundColor: '#c1c1c1',

            '&:hover': {
                backgroundColor: '#a8a8a8',
            },
        },
    },
})

const cssMain = css({
    gridArea: 'Main',
})

const cssLeftPanel = css({
    gridArea: 'Left',
})

const cssRightPanel = css({
    gridArea: 'Right',
})

const cssToolBar = css({
    gridArea: 'Top',
})

const cssStatusBar = css({
    gridArea: 'Bottom',
})

export default function BaseMap({
    components: {
        LeftPanel = DefaultLeftPanel,
        RightPanel = DefaultRightPanel,
        StatusBar = DefaultStatusBar,
        ToolBar = () => null,
        ...components
    } = {},

    hotkeys,

    inits: {
        leftPanel = {},
        markedNodes = [],
        rightPanel = {},
        run,
        selectedNodes = [],
        showNodeDesc = false,
    } = {},

    permission = [],
    plugins = [],
    onLoad,
    ...props
}) {
    const handleLoad = map => {
        if (run) {
            eval(run)(map)
        }

        onLoad?.(map)
    }

    return (
        <MindMap
            css={cssMap}
            Doc={SMapDoc}

            plugins={[
                useEventBus(),
                useHideNode(),
                useInitMarkedNodes(markedNodes),
                useInitSidePanel({left: leftPanel, right: rightPanel}),
                useInitSelectedNodes(selectedNodes),
                useMarkNode(),
                useMindMapPlugins(),
                useMindMapExtensions(),
                useNodeProxy(),
                usePermission(permission),
                useRunAction(),
                useShowNodeDesc(showNodeDesc),
                useSidePanel(),
                ...plugins
            ]}

            components={{...components, NodeInnerBox}}
            hotkeys={{...defaultHotkeys, ...hotkeys}}
            onLoad={handleLoad}
            {...props}
        >
            <Canvas css={cssMain} />
            <ToolBar css={cssToolBar} />
            <StatusBar css={cssStatusBar} />
            <LeftPanel css={cssLeftPanel} />
            <RightPanel css={cssRightPanel} />
        </MindMap>
    )
}
