import superMeta from '../_MODULE/metaModuleNode.mjs'

export default {
    ...superMeta,
    codeProp: 'cmUserCode',
    mapIdProp: 'ucMapId',
    mapStsCodeProp: 'ucMapStsCode',
    mapTypeCode: 'UC_MAP',
    name: '用例域',
    ownerIdProp: 'caId',
    ownerTextProp: 'caName',
    ownerType: 'CA',
    textProp: 'cmName',
    type: 'CM',
}
