import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectCm from './SelectCm.jsx'

export default function PropertiesControllerCm({
    className,
    style,
    codeProp = 'cmCode',
    disabled,
    hidden,
    idProp = 'cmId',
    label,
    labelSuffix,
    nameProp = 'cmName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: cmCode,
        [idProp]: cmId,
        [nameProp]: cmName,
    } = values

    const handleChange = cm => {
        const {
            cmCode = '',
            cmId = '',
            cmName = '',
        } = cm ?? {}

        update({
            [codeProp]: cmCode,
            [idProp]: cmId,
            [nameProp]: cmName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectCm
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{cmCode, cmId, cmName}}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
