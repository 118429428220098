import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bmCode: {
        name: () => '业务域',
    },

    fmCode: {
        name: () => '功能域',
    },

    ssCode: {
        name: () => '子系统',
    },

    uiUserCode: {
        name: () => '界面代码',
        required: () => true,
    },

    uiName: {
        name: () => '界面名称',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },
})
