import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '分析模型',
    archName: '分析地图',
    archUrl: '/ProjectAnaMap',
    detailUrl: '/AaDetail',
    detailIdProp: 'aaId',
    mapProp: 'aaMap',
    moduleType: 'AM',
    name: '分析结构',
    type: 'AA',
}
