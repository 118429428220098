import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    resume: {
        name: () => '简述',
    },

    ruleKey: {
        name: () => '处理能力关键字',
    },

    ruleTitle: {
        name: () => '处理能力标题',
        required: () => true,
    },

    ruleType: {
        name: () => '处理能力类型',
        required: () => true,
    },

    ruleUserCode: {
        name: () => '处理能力代码',
    },

    url: {
        name: () => 'URL',
    },
})
