import SelectCmDialog from '@/pages/MainPage/dialogs/SelectCmDialog'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectCm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({cmName}) => cmName).join(',') :
        (value ?? {}).cmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectCmDialog}
            {...props}
        />
    )
}
