/**
 * 获取指定字典编码的字典值数组
 * @param dictCode
 */
const getDictDtls = (dictCode) => {
    switch (dictCode) {
        case "PRJ_TYPE":
            return [
                { code: 'SLN', nameCn: '解决方案', sortSeq: 1 },
                { code: 'RP', nameCn: '需求池', sortSeq: 2 },
                { code: 'OM', nameCn: '组织模型', sortSeq: 16 },
                { code: 'BM', nameCn: '流程模型', sortSeq: 17 },
                { code: 'NEW', nameCn: 'BODA软件模型', sortSeq: 18 },
                { code: 'ONLINE', nameCn: '部署模型', sortSeq: 19 },
                { code: 'DM', nameCn: 'RDB库表模型', sortSeq: 20 },
                { code: 'BAM', nameCn: 'STAR基准分析模型', sortSeq: 21 },
                { code: 'TAM', nameCn: 'STAR主题分析模型', sortSeq: 22 },
                { code: 'BCT', nameCn: '业务组件', sortSeq: 22 },
                { code: 'LIB', nameCn: '建模资源', sortSeq: 23 },
            ];
        case "PRJ_SUB_TYPE":
            return [
                { code: 'BODA1', nameCn: 'BODA1', sortSeq: 1 },
                { code: 'BODA2', nameCn: 'BODA2', sortSeq: 2 },
            ];
        case "PRJ_BASELINE":
            return [
                { code: 'BASELINE', nameCn: '基线分支', sortSeq: 1 },
                { code: 'INCREMENT', nameCn: '增量分支', sortSeq: 2 },
            ];
        case "DES_TYPE":
            return [
                { code: 'REQ', nameCn: '需求', sortSeq: 1 },
                { code: 'IMPL', nameCn: '设计', sortSeq: 2 },
                { code: 'OTH', nameCn: '测试', sortSeq: 3 },
            ];
        case "WHITE_LIST_TYPE":
            return [
                { code: 'PRJ', nameCn: '项目', sortSeq: 0 },
                { code: 'DB', nameCn: '数据库', sortSeq: 1 },
                { code: 'DT', nameCn: '数据表', sortSeq: 2 },
                { code: 'BDSUMMARY', nameCn: '用户场景', sortSeq: 3 },
                { code: 'BDLOGIC', nameCn: '业务处理能力', sortSeq: 4 },
                { code: 'BDRULE', nameCn: '数据实体', sortSeq: 5 },
            ];
        case "PRD_VERSION_TYPE":
            return [
                { code: 'AUTO', nameCn: '自动', sortSeq: 1 },
                { code: 'MANUAL', nameCn: '手动', sortSeq: 2 },
            ];
        case "DB_TYPE":
            return [
                { code: 'MYSQL', nameCn: 'MySQL', sortSeq: 1 },
                { code: 'ORACLE', nameCn: 'Oracle', sortSeq: 2 },
                { code: 'SQLSERVER', nameCn: 'SqlServer', SqlServer: 3 },
                { code: 'POSTGRESQL', nameCn: 'PostgreSQL', sortSeq: 4 },
            ];
        case "ER_CATEGORY":
            return [
                { code: 'FILE_GEN', nameCn: '文件生成通知', sortSeq: 1 },
                { code: 'PATTERN_GEN', nameCn: '模型成品生成通知', sortSeq: 2 },
                { code: 'PATTERN_COMPARE_REPORT_GEN', nameCn: '模型比对报告生成通知', sortSeq: 3 },
            ];
        case "ER_OPT_TYPE":
            return [
                { code: 'NONE', nameCn: '无操作', sortSeq: 1 },
                { code: 'NEW_WINDOW', nameCn: '新页面', sortSeq: 2 },
            ];
        case "PATTERN_STS":
            return [
                { code: 'BUILDING', nameCn: '构建中', sortSeq: 1 },
                { code: 'BUILD_ERR', nameCn: '构建失败', sortSeq: 2 },
                { code: 'TO_REVISE', nameCn: '待修订', sortSeq: 3 },
                { code: 'CAN_BUILD', nameCn: '可构建成品', sortSeq: 4 },
                { code: 'BUILD', nameCn: '已构建成品', sortSeq: 5 },
                { code: 'TO_RLS', nameCn: '待发布', sortSeq: 6 },
                { code: 'RLS', nameCn: '已发布', sortSeq: 7 },
            ];
        case "PATTERN_COMPARE_REPORT_STS":
            return [
                { code: 'TO_ACCEPT', nameCn: '待受理', sortSeq: 1 },
                { code: 'COMPARING', nameCn: '比对中', sortSeq: 2 },
                { code: 'COMPARED', nameCn: '比对完成', sortSeq: 3 },
                { code: 'COMPARED_ERR', nameCn: '比对失败', sortSeq: 3 },
            ];
        case "PATTERN_MODULE_ERR_TYPE":
            return [
                { code: 'DP_NOT_EXIST', nameCn: '制品不存在', sortSeq: 1 },
                { code: 'DP_REV_ERR', nameCn: '制品版本错误', sortSeq: 2 },
                { code: 'MAP_VER_NOT_EXIST', nameCn: '发布版不存在', sortSeq: 3 },
                { code: 'MAP_VER_DATA_ERR', nameCn: '发布版数据异常', sortSeq: 4 },
                { code: 'REF_MODULE_INFO_ERR', nameCn: '引用模件信息异常', sortSeq: 5 },
                { code: 'REF_MODULE_NOT_EXIST', nameCn: '引用模件不存在', sortSeq: 6 },
            ];
        case "MERGE_STS":
            return [
                { code: 'NOT_START', nameCn: '未开始', sortSeq: 1 },
                { code: 'PROCESSING', nameCn: '合并处理中', sortSeq: 2 },
                { code: 'PART_MERGED', nameCn: '已部分合并', sortSeq: 3 },
                { code: 'FINISH_MERGED', nameCn: '已完成合并', sortSeq: 4 },
                { code: 'FAIL_MERGED', nameCn: '合并失败', sortSeq: 5 },
            ];
    }
    return [];
}


/**
 * 获取指定字典编码、字典值编码的字典值对象
 * @param dictCode
 * @param dictItemCode
 * @returns {string|*|string}
 */
const getDictDtl = (dictCode, dictItemCode) => {
    const data = getDictDtls(dictCode);
    if (data) {
        return data.find(item => item.code == dictItemCode);
    }
    return null;
}

const dict = {
    getDictDtls,
    getDictDtl
};

export default dict;
