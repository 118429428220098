import useSWR from 'swr/immutable'
import http from '@/biz/http.mjs'

export default prjId => {
    const {data: models} = useSWR(
        '/meta-models',
        () => http.get('/basMetaModel/listEnableBasMetaModel')
    )

    const {data: project} = useSWR(
        `/projects/${prjId}`,
        () => http.get(`/prjs/${prjId}/info`)

    )

    const types = new Set

    if (models && project) {
        const {prjTypeCode, subTypeCode} = project

        const getTypes = (models, prjTypes) => {
            const [prjType, ...restPrjTypes] = prjTypes

            if (! prjType) {
                return
            }

            for (const {basMetaModelDps, children, metaModelCode} of models) {
                if (metaModelCode !== prjType) {
                    continue
                }

                for (const {metaDpCode} of basMetaModelDps ?? []) {
                    types.add(metaDpCode)
                }

                getTypes(children ?? [], restPrjTypes)
            }
        }

        getTypes(models, [prjTypeCode, subTypeCode])
    }

    return types
}
