import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconIe from '../IE/icon-ie.svg?react'
import api from './apiBrfsys.mjs'
import meta from './metaBrfsys.mjs'
import Model from './ModelBrfsys.mjs'
import PropertiesPane from './PropertiesPaneBrfsys.jsx'
import FormChoose from './FormChooseBrfsys.jsx'
import TableChoose from './TableChooseBrfsys.jsx'
import { useUser } from "@/biz/user.mjs";

function getPblNames(data, bizNodeType) {
    return data
      .find(item => item.data.bizNodeType === bizNodeType)
      ?.children?.map(child => child.data) || [{}];
}

export default () => {
    const ComponentNode = useComponentNode()
    const user = useUser();


    return extendNode(ComponentNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,

        getText(map, node){
            return `${node.data.sysPrjName || ''}`
        },
        getStyle(map, node) {
            return {
                ...ComponentNode.getStyle.call(this, map, node),
                // ...BaseNode.getStyle.call(this, map, node),
                backgroundColor: '#fa9096',
                textColor: '#000',
                shape: 'RoundedRectangle',
            }
        },
        canWriteData(map) {
            return false
        },
        
        // canWriteTree(map) {
        //     const data = map.root.data;

        //     return (data.mrmId == user.pkid || data.crtId == user.pkid) && ["DRAFT", "PRE_APPROVAL_REJECTED", "PENDING_PRE_APPROVAL"].includes(data.brfStsCode)
        // },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            const sysMap = JSON.parse(d.sysMap);
            const accessData = getPblNames(sysMap, 'BRF_SYS_ACCESS_BRANCH');
            // const productionData = getPblNames(sysMap, 'BRF_SYS_PRODUCTION_BRANCH');

            d.accessBranchId = accessData[0]?.pkid || null;
            d.accessBranchName = accessData[0]?.pblName || null;
            // d.productionBranchId = productionData[0]?.pkid || null;
            // d.productionBranchName = productionData[0]?.pblName || null;

            event.detail.brfSyss.push(d)
        },

        async _onChange(map, node, event) {
            await ComponentNode._onChange.call(this, map, node, event)
            const deleteTypeArr = ['BRF_SYS_PRODUCTION_BRANCH', 'BRF_SYS_ACCESS_BRANCH']
            if (event.target === node) {
                const oldData = event.detail
                const {sysPrjId} = node.data

                if (sysPrjId === oldData.sysPrjId) {
                    return
                }

                for (const n of node.children) {
                    if (
                        deleteTypeArr.includes(n.data.bizNodeType)
                    ) {
                        map.deleteChildren(n)
                    }
                }
            }
        },

        _getCategories(map, node) {
            return [
                'BRF_SYS_DESC',
                'BRF_SYS_ACCESS_BRANCH',
                // 'BRF_SYS_PRODUCTION_BRANCH'
            ]
        },
        canDeleteTree(map, node) {
            const data = map.root.data;

            return ['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)
        },
    })
}
