const FileUtils = {
    // 文件服务器【上传API，POST】，【下载API，GET】
    fileServer: import.meta.env.VITE_HTTP_BASE_URL,

    // SMS文件桶
    smsBucket: import.meta.env.VITE_BUCKET,

    CommonFile: '/commonfile',

    // 用户logo
    UserLogo: '/UserLogo',

    // 团队logo
    TeamLogo: '/TeamLogo',

    LayoutPic: '/LayoutPic',

    //-----------------------------------------------
    // 项目文档
    projectDoc: '/projectdoc/',

    // 界面图册
    uiPic: '/uipic/',

    // 服务图册
    funcPhotos: '/system/func/',

    // 变更图册
    dcPhoto: '/dcphoto',

    // 变更文档
    dcDoc: '/dcdoc',

    // license
    license: '/license',

    // 组件图标
    comIcon: '/comicon',

    //
    requirementForm: '/requirementForm',

    uvTemplateCover: '/uv/template/cover/',

    // 原型工具组件图标


    // 制品模板
    dpt: '/dpt',

    // 图片预览
    getFileUrl: (fileId) => {
        let server = import.meta.env.VITE_HTTP_BASE_URL;
        return server + `/files/${fileId}`;
    },

    // 文件下载
    download: (fileStream, fileName) => {
        try {
            const blob = new Blob([fileStream]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click(); // 模拟点击

            // 释放资源并删除标签a
            URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
        } catch (err) {
            console.log(err);
        }
    },

    // 文件另存为
    saveFile: async (blob, fileName) => {
        try {
            const handle = await window.showSaveFilePicker({ suggestedName: fileName, types: getTypes(fileName) });
            const w = await handle.createWritable();
            await w.write(blob);
            await w.close();
        } catch (err) {
            if (['NotAllowedError', 'AbortError'].includes(err.name)) {
                throw err;
            } else {
                console.log(err);
            }
        }
    }
}

// 文件类型
const getTypes = (fileName) => {
    try {
        if (fileName) {
            let mime = null;
            let fileType = fileName.split('.').pop();

            switch (fileType) {
                case 'txt':
                    mime = 'text/plain';
                    break;
                case 'xml':
                    mime = 'application/xml';
                    break;
                case 'xls':
                    mime = 'application/vnd.ms-excel';
                    break;
                case 'xlsx':
                    mime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    break;
                case 'doc':
                    mime = 'application/msword';
                    break;
                case 'docx':
                    mime = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                    break;
                case 'pdf':
                    mime = 'application/pdf';
                    break;
                case 'zip':
                    mime = 'application/zip';
                    break;
                case 'jpg':
                    mime = 'image/jpeg';
                    break;
                case 'jpeg':
                    mime = 'image/jpeg';
                    break;
                case 'png':
                    mime = 'image/png';
                    break;
                case 'gif':
                    mime = 'image/gif';
                    break;
            }

            if (mime) {
                const param = {};
                param[mime] = ['.' + fileType];
                return [{ accept: param }];
            }

            return [];
        }

        return [];
    } catch (err) {
        console.log(err);
    }
}

export default FileUtils;
