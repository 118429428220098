import {useState} from 'react'
import {Form} from 'antd'
import http from '@/biz/http.mjs'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import Select from '@/components/Form/Select2.jsx'
import Table from '@/components/Table/Table.jsx'
import runAsync from '@/script/runAsync.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import metaBizNode from '../../metaBizNode.mjs'

export const canAddNodeToBranch = () => () => {
    return function (nodes) {
        return [...nodes].filter(node => {
            const {bizNodeType, pkid, prjId} = node.data

            return (
                pkid &&
                prjId === this.data.prjId &&
                this.BizNode[bizNodeType]?.classes.includes('product')
            )
        })
    }
}

const SelectBranch = ({prjPatternId, ...props}) => {
    const {data} = useSwrNoCache(
        () => runAsync(
            () => http.get('/prjbaselines', {prjPatternId, isDesc: '1'}),
            {action: '读取增量分支'}
        ),

        {dependencies: [prjPatternId]},
    )

    const options = data ?
        data.filter(({pblProgress, pblStsCode, pblType}) => (
            '1' === pblProgress &&
            'ACTIVE' === pblStsCode &&
            'INCREMENT' === pblType
        ))
        :
        void 0

    const autoSelect = options => options[0]
    const getOptionKey = e => e.pkid
    const getOptionLabel = e => `[${e.sVer}]${e.pblName}`

    return (
        <Select
            autoSelect={autoSelect}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            options={options}
            {...props}
        />
    )
}

const FormAddToBranch = ({prjPatternId, ...props}) => {
    return (
        <Form {...props}>
            <Form.Item
                label="目标分支"
                name="branch"
            >
                <SelectBranch prjPatternId={prjPatternId} />
            </Form.Item>
        </Form>
    )
}

const ViewProductType = (_, row) => {
    const {name} = metaBizNode[row.bizNodeType]
    return <Table.ViewText value={name} />
}

const ViewProductCode = (_, row) => {
    const {bizNodeType} = row
    const {codeProp} = metaBizNode[bizNodeType]
    const code = row[codeProp]
    return <Table.ViewText value={code} />
}

const ViewProductName = (_, row) => {
    const {bizNodeType} = row
    const {textProp} = metaBizNode[bizNodeType]
    const text = row[textProp]
    return <Table.ViewText value={text} />
}

const ViewProductVersion = (_, row) => {
    const {rev, sVer} = row
    const version = `${sVer}.${rev}`
    return <Table.ViewText value={version} />
}

const TableProducts = ({onChange, ...props}) => {
    const columns = [
        {
            title: '制品类型',
            dataIndex: 'bizNodeType',
            component: ViewProductType,
        },

        {
            title: '制品编号',
            dataIndex: 'dpSn',
            component: <Table.ViewText />,
        },

        {
            title: '制品代码',
            dataIndex: 'code',
            component: ViewProductCode,
        },

        {
            title: '制品名称',
            dataIndex: 'name',
            component: ViewProductName,
        },

        {
            title: '版本号',
            dataIndex: 'version',
            component: ViewProductVersion,
        },
    ]

    const rowSelection = {
        defaultSelect: rows => rows.map(e => e.pkid),
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: 'checkbox',
    }

    return (
        <Table
            columns={columns}
            rowKey="pkid"
            rowSelection={rowSelection}
            selectRowOnClick
            {...props}
        />
    )
}

const ModalAddNodeToBranch = ({nodes, prjPatternId, onOk, ...props}) => {
    const [params, setParams] = useState({})
    const dataSource = nodes.map(n => n.data)

    const okButtonProps = {
        disabled: ! (params.pkid && 0 < params.pkids?.length)
    }

    const onFormChange = values => {
        const {
            branch: {pblType, pkid, prjId, sVer} = {},
        } = values

        setParams(p => ({...p, pblType, pkid, prjId, sVer}))
    }

    const onTableChange = rows => {
        const pkids = rows.map(e => e.pkid)
        setParams(p => ({...p, pkids}))
    }

    const _onOk = async () => {
        await runAsync(
            () => http.post('/prjbaselines/addIncrementDp', params),
            {action: '加入增量分支'}
        )

        onOk()
    }

    return (
        <Modal
            okButtonProps={okButtonProps}
            title="加入增量分支"
            width={800}
            onOk={_onOk}
            {...props}
        >
            <FormAddToBranch
                prjPatternId={prjPatternId}
                onValuesChange={onFormChange}
            />

            <TableProducts
                dataSource={dataSource}
                onChange={onTableChange}
            />
        </Modal>
    )
}

export const addNodeToBranch = () => () => {
    const openModal = useOpenModal()

    return async function (nodes) {
        const {prjPatternId} = this.data

        await openModal(
            <ModalAddNodeToBranch
                nodes={nodes}
                prjPatternId={prjPatternId}
            />
        )
    }
}
