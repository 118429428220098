import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import DbData from './DbData';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import openWindow from '@/script/openWindow.mjs';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateDb({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();

    // 数据模型
    const [prj, setPrj] = useState({});

    // 加载显示
    const [visiblePrj, setVisiblePrj] = useState(false); // 项目 Dialog

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    const doAdd = async () => {
        try {
            const formField = await form.validateFields();
            formField.prjId = prj.prjId;
            formField.sVer = prj.sVer;

            const data = await runAsync(
                async () => {
                    return await http.post(`/archdbs`, formField);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            onOk(data)
            openWindow('/DbDetail', { dbId: data.pkid });
        } catch (err) {
            console.log(err);
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }
    }

    return (
        <>
            <Modal
                title="新建数据库"
                okText="保存"
                cancelText="取消"
                // onOk={doAdd}
                footer={[
                    <Space>
                        <Permission permission="ARCH_DB_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} onFinish={doAdd} layout="vertical">
                    <Form.Item name="prjName" label="项目名称" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                    </Form.Item>
                    <DbData.dbUserCode />
                    <DbData.dbName />
                    <DbData.dbmsCode />
                </Form>
            </Modal>

            {/* 选择项目 */}
            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
