import {Form} from 'antd'
import apiBaseline from '@/biz/apis/baseline.mjs'
import Checkbox from '@/components/Form/Checkbox.jsx'
import TextArea from '@/components/Form/TextArea.jsx'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import apiMap from '../../../apis/apiMap.mjs'

export const canPublishMap = () => () => {
    return function () {
        if (! /^DRAFT|REVISE$/.test(this.data.mapStsCode)) {
            this.logger.error('只有草稿/修订状态的地图才能发布')
            return false
        }

        if (! this.permission.has('write')) {
            this.logger.error('没有发布权限')
            return false
        }

        return true
    }
}

const FormItemUpgrade = ({mapTypeCode, prjId, sVer, ...props}) => {
    const {data} = useSwrNoCache(
        () => apiBaseline.read({prjId, sVer})
    )

    if (
        'SA_MAP' === mapTypeCode ||
        'INCREMENT' === data?.pblType
    ) {
        return null
    }

    return (
        <Form.Item name="upgradeDp" {...props}>
            <Checkbox>地图升版</Checkbox>
        </Form.Item>
    )
}

const ModalPublish = ({mapTypeCode, prjId, sVer, onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Modal
            title="发布地图"
            width={400}
            {...props}
            onFinish={onOk}
            onOk={handleOk}
            css={cssForm}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                <Form.Item name="memo">
                    <TextArea
                        autoSize={false}
                        rows={5}
                        placeholder="备注"
                    />
                </Form.Item>

                <FormItemUpgrade
                    mapTypeCode={mapTypeCode}
                    prjId={prjId}
                    sVer={sVer}
                />
            </Form>
        </Modal>
    )
}

export const publishMap = () => {
    const openModal = useOpenModal()

    return () => async function () {
        const {mapTypeCode, prjId, sVer} = this.data

        const extra = await openModal(
            <ModalPublish
                mapTypeCode={mapTypeCode}
                prjId={prjId}
                sVer={sVer}
            />
        )

        if (! extra) {
            return
        }

        return this.runAction(
            async () => {
                const {pkid} = this.data
                const updates = await apiMap.publish({pkid, ...extra})
                this.data = {...this.data, ...updates}
            },

            {
                action: '发布地图',
                success: {show: true},
            },
        )
    }
}
