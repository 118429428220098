import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaDbins.mjs'
import Model from './ModelDbins.mjs'
import PropertiesPane from './PropertiesPaneDbins.jsx'

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        ...meta,
        Model,
        PropertiesPane,

        getIcons() {
            return [
                <IconLetters
                    key="type"
                    borderColor={Color.DARK_GREEN}
                    letters="D"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return this._getStyle(map, node, {
                borderColor: Color.DARK_GREEN,
            })
        },

        getTextPrefix(map, node) {
            const defaultPrefix = ComponentNode.getTextPrefix.call(
                this, map, node
            )

            const {dbInsEnv} = node.data

            if (dbInsEnv) {
                return `${defaultPrefix}【${dbInsEnv}】`
            }
            else {
                return defaultPrefix
            }
        },

        mapUpdateNodeData(map, node, data) {
            // 手动修改了 url
            if (Object.hasOwnProperty.call(data, 'url')) {
                return data
            }

            const oldData = node.data

            const {
                dbInsIntranetIp,
                dbInsPort,
                dbInsSchema,
            } = {...oldData, ...data}

            // 生成 url 所需数据不全
            if (! (
                dbInsIntranetIp &&
                dbInsPort &&
                dbInsSchema
            )) {
                return data
            }

            const genPath = ({
                dbInsIntranetIp,
                dbInsPort,
                dbInsSchema,
            }) => `//${dbInsIntranetIp}:${dbInsPort}/${dbInsSchema}`

            const oldPath = genPath(oldData)

            // 原 url 不是自动生成的
            if (
                oldData.url &&
                ! oldData.url.endsWith(oldPath)
            ) {
                return data
            }

            const protocol = (() => {
                for (const n of map.trueChain(node.parent)) {
                    const {bizNodeType, dbmsCode} = n.data

                    if ('DB' === bizNodeType) {
                        return {
                            ORACLE: 'jdbc:oracle:thin:@',
                            MYSQL: 'jdbc:mysql:',
                            SQLSERVER: 'jdbc:sqlserver:',
                            POSTGRESQL: 'jdbc:postgresql:',
                        }[dbmsCode]
                    }
                }
            })()

            const path = genPath({
                dbInsIntranetIp,
                dbInsPort,
                dbInsSchema,
            })

            const url = `${protocol}${path}`
            return {...data, url}
        },

        async _atOwnerPush(map, node, event) {
            const d = await this._getPushData(map, node)
            event.detail.archDbInss.push(d)
        },

        _getCategories(map, node) {
            return [
                'DBINS_DEPLOYED_IN',
                'DBINS_DEPENDENCE_BY',
            ]
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                dbDefault: '0',
            }
        },

        _pushDataSlots: {
            archDbInsDeploys: [],
        },
    })
}
