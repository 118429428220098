import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBdruleDt.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(DT)$/.test(type)
        },

        isHidden(map, node) {
            const {ruleType} = node.parent?.data ?? {}

            return (
                'DE' !== ruleType ||
                CategoryNode.isHidden.call(this, map, node)
            )
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['DT'],
            ]
        },
    })
}
