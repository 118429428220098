import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Button, Form, Input, Space, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfProductionApproval({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);

    const doApproval = async (actionResult, values) => {
        try {
            setLoading(true)
            await runAsync(
                async () => {
                    return await http.post(`/brf/productionApproval`, {...values, actionResult}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                }, {
                    error: {content: (err) => `核准失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                });
            onOk(true)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    const handleApprovalClick = async (actionResult) => {
        if (actionResult === 'yes') {
            form.validateFields().then(async values => {
                doApproval(actionResult, values)
            })
        } else {
            const values = form.getFieldsValue()
            doApproval(actionResult, values)
        }
    }

    useEffect(() => {
        form.setFieldsValue(record)
    }, []);

    return (
        <Modal
            title="核准"
            width="600px"
            {...props}
            footer={
                <Space>
                    <Button type='primary' ghost size='small' onClick={() => handleApprovalClick('yes')}>通过</Button>
                    <Button type='ghost' size='small' danger onClick={() => handleApprovalClick('no')}>驳回</Button>
                    <Button type='ghost' size='small' onClick={() => onOk(false)}>取消</Button>
                </Space>
            }
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Form.Item name='pkid' hidden><Input/></Form.Item>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
