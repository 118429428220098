import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Space, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectPmDialog from '@/pages/MainPage/dialogs/SelectPmDialog';
import SelectBmDialog from '@/pages/MainPage/dialogs/SelectBmDialogNew';
import UiCase from '@/pages/MainPage/datas/UiCase';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import openWindow from '@/script/openWindow.mjs';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateBf({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const { Option } = Select;
    const [form] = Form.useForm();
    const openModal = useOpenModal();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        ref.current = {};

        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject();

        setPrj({ prjId, sVer, prjTypeCode, subTypeCode });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name, bfTypeCode: 'TARGET' });
    }

    // 新建：业务流程
    const doAdd = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.prjId = prj.prjId;
            formField.sVer = prj.sVer;
            formField.bmCode = ref.current.bmCode;
            formField.pmCode = ref.current.pmCode;

            const data = await runAsync(
                async () => {
                    return await http.post(`/bmbfs`, formField);
                }, {
                error: { content: (err) => `新建失败: ${err.message}` },
                loading: { show: false },
                success: { show: true },
            });

            onOk(data);
            openWindow('/BfDetail', { bfId: data.pkid });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调：设置项目
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer, prjTypeCode: value.prjTypeCode, subTypeCode: value.subTypeCode });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.pmCode = null;
        ref.current.bmCode = null;
        form.setFieldsValue({ pmName: "", bmName: '' });
    }

    // 回调：设置流程域
    const callPm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectPmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.pmCode = result.pmCode;
            form.setFieldsValue({ pmName: result.pmName });
        }
    }

    // 回调：设置业务域
    const callBm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectBmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.bmCode = result.bmCode;
            form.setFieldsValue({ bmName: result.bmName });
        }
    }

    return (
        <>
            <Modal
                title={'新建业务流程'}
                okText="保存"
                cancelText="取消"
                bodyStyle={{ padding: '12px 24px 2px' }}
                footer={[
                    <Space>
                        <Permission permission="BF_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                    </Form.Item>

                    {
                        ((prj.prjTypeCode == 'NEW' && prj.subTypeCode == 'BODA2') || prj.prjTypeCode === 'BM')
                        &&
                        <Form.Item name="pmName" label="流程域">
                            <Input placeholder="请选择流程域" readOnly addonAfter={<SearchOutlined onClick={callPm} />} />
                        </Form.Item>
                    }

                    {!((prj.prjTypeCode == 'NEW' && prj.subTypeCode == 'BODA2') || prj.prjTypeCode === 'BM') &&
                        <Form.Item name="bmName" label="业务域">
                            <Input placeholder="请选择业务域" readOnly addonAfter={<SearchOutlined onClick={callBm} />} />
                        </Form.Item>
                    }

                    <Form.Item name="bfTypeCode" label="业务流程类型" rules={[{ required: true, message: '请选择业务流程类型' }]}>
                        <Select>
                            <Option value="TARGET">目标流程</Option>
                            {/* <Option value="SITUATION">现状流程</Option> */}
                        </Select>
                    </Form.Item>

                    <Form.Item name="bfNo" label="业务流程代码" rules={[{ required: true, message: '请输入业务流程代码' }]}>
                        <Input placeholder="请输入业务流程代码" />
                    </Form.Item>

                    <Form.Item name="bfName" label="业务流程名称" rules={[{ required: true, message: '请输入业务流程名称' }]}>
                        <Input placeholder="请输入业务流程名称" />
                    </Form.Item>

                    <UiCase prjId={prj.prjId} sVer={prj.sVer} prjTypeCode={prj.prjTypeCode} />
                </Form>
            </Modal>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
