import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseUe = (props) => {
    const map = useMapContext()

    const columns = [
        'BCT' === map.data.prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '功能域',
            dataIndex: 'fmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        {
            title: '界面名称',
            dataIndex: 'uiName',
            component: <Table.ViewText />,
        },

        {
            title: '界面代码',
            dataIndex: 'uiUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '能力标题',
            dataIndex: 'ueName',
            component: <Table.ViewText />,
        },

        {
            title: '能力代码',
            dataIndex: 'ueUserCode',
            component: <Table.ViewText />,
        },

        {
            title: '能力类型',
            dataIndex: 'ueTypeName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseUe
