import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    rmCode: {
        name: () => "需求域"
    },
    rmName: {
        name: () => "需求域",
        disabled: () => true,
    },
    brfTitle: {
        name: () => '需求单标题',
    },
    brfModeName: {
        name: () => '需求单模式',
        readOnly: () => true,
        disabled: () => true,
    },
    resume:  {
        name: () => '需求单简述',
    },

    brfCode: {
        name: () => '需求单编号',
        readOnly: () => true,
        disabled: () => true,
    },
    crtName: {
        name: () => '创建人',
        readOnly: () => true,
        disabled: () => true,
    },

    crtTime: {
        name: () => '创建时间',
        readOnly: () => true,
        disabled: () => true,
    },
    mrmId: {
        name: () => '需求经理',
    },
    mrmName: {
        name: () => '需求经理',
        readOnly: () => true,
        disabled: () => true,
    },
    mbmName: {
        name: () => '主牵业务经理',
        readOnly: () => true,
        disabled: () => true,
    },

    mtmId: {
        name: () => '主牵技术经理',
    },
    mtmName: {
        name: () => '主牵技术经理',
        readOnly: () => true,
        disabled: () => true,
    },

})