import extendNode from '../extendNode.mjs'

import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import meta from './metaBranch.mjs'
import PropertiesPane from './PropertiesPaneBranch.jsx'
import FormChoose from './FormChooseBranch.jsx'
import TableChoose from './TableChooseBranch.jsx'
import openWindow from "@/script/openWindow.mjs";
import api from './apiBranch.mjs'
import { message } from 'antd'

// const readProducts = data => http.get('/prjbaselines', data)

export default () => {
    const CommonNode = useComponentNode()

    return extendNode(CommonNode, {
        ...meta,
        api,
        PropertiesPane,
        FormChoose,
        TableChoose,

        getText(map, node){
            return `${node.data.pblName} [${node.data.sVer}]`
        },
        // getStyle(map, node) {
        //     return {
        //         ...CommonNode.getStyle.call(this, map, node),
        //         // ...BaseNode.getStyle.call(this, map, node),
        //         backgroundColor: '#fa9096',
        //         textColor: '#000',
        //         shape: 'RoundedRectangle',
        //     }
        // },
        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: '#fa9096',
                }),

                shape: 'HorizontalHexagon',
            }
        },

        async choose(map, node) {
            const {sysPrjId} = node.parent.data;
            const getQuery = query => {
                
                return {prjId: sysPrjId, ...query}
            }
            if (!sysPrjId) {
                return message.warning('请先关联系统项目')
            }
            const newNode = await this._choose(map, node, {getQuery})
            // console.log(node)
            if (newNode.length > 0) {
                map.deleteChildren(node)
            }
            // const formatNode = newNode.map(item => {
            //     return {
            //         data: {
            //             pkid: item.data.pkid,
            //             pblName: item.data.pblName,
            //             sVer: item.data.sVer,
            //             bizNodeType: "BRFBRANCH",
            //             prjId: item.data.prjId,
            //             prjNo: item.data.prjNo
            //         }
            //     }
            // })
            return newNode
        },
        
        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {prjId, pkid, sVer} = node.data
            const target = event.altKey ? '_self' : '_blank'
            openWindow('/SaList', {prjId: prjId || pkid, sVer}, target)
        },
    })
}
