import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'
import ButtonImportProductsFromXls from '../_DESIGN/PropertiesPaneButtonImportProductsFromXls.jsx'

export default function PropertiesPanePa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonExportModel global type="pm" />

                    <ButtonImportProductsFromXls type="pm">
                        导入流程结构
                    </ButtonImportProductsFromXls>
                </>
            }

            {...props}
        />
    )
}
