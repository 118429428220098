import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import {Form} from 'antd'
import apiBaseline from '@/biz/apis/baseline.mjs'
import Checkbox from '@/components/Form/Checkbox.jsx'
import TextArea from '@/components/Form/TextArea.jsx'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'

export const canPublishProduct = () => () => {
    return function (nodes) {
        return [...nodes].filter(
            node => this.actions.canPublishProduct(node)
        )
    }
}

const FormItemUpgrade = ({prjId, sVer, ...props}) => {
    const {data} = useSwrNoCache(
        () => apiBaseline.read({prjId, sVer})
    )

    if ('INCREMENT' === data?.pblType) {
        return null
    }

    return (
        <Form.Item name="upgradeDp" {...props}>
            <Checkbox>制品升版</Checkbox>
        </Form.Item>
    )
}

const ModalPublish = ({prjId, sVer, onOk, ...props}) => {
    const [form] = Form.useForm()

    const handleOk = () => {
        form.submit()
    }

    const cssForm = {
        '.ant-form-item': {
            marginBottom: 0,
        }
    }

    return (
        <Modal
            title="发布模型制品"
            width={400}
            {...props}
            onOk={handleOk}
            onFinish={onOk}
            css={cssForm}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onOk}
            >
                <Form.Item name="memo">
                    <TextArea
                        autoSize={false}
                        rows={5}
                        placeholder="备注"
                    />
                </Form.Item>

                <FormItemUpgrade
                    prjId={prjId}
                    sVer={sVer}
                />
            </Form>
        </Modal>
    )
}

export const publishProduct = () => {
    const openModal = useOpenModal()

    return () => async function (nodes) {
        const {prjId, sVer} = this.data

        const extra = await openModal(
            <ModalPublish
                prjId={prjId}
                sVer={sVer}
            />
        )

        if (! extra) {
            return
        }

        let results

        await this.cmd(async () => {
            results = await this.runParallelAction(
                () => this.actions.publishProduct(
                    nodes, {prjId, sVer, ...extra}
                ),

                {action: '发布模型制品'},
            )
        })

        const isOk = results.some(
            ({status}) => 'rejected' !== status
        )

        if (isOk) {
            this.resetChangeHistory()
        }
    }
}
