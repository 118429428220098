import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useOprNode from '../_OPR/useOprNode.jsx'
import meta from './metaFopr.mjs'

export default () => {
    const OprNode = useOprNode()

    return extendNode(OprNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(SF)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(FRES)$/.test(type)
        },

        defaultChildType(map, node) {
            return 'FRES'
        },

        menuItemsInsertConcept(map, node) {
            return [
                ['FRES'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['SF_4'],
            ]
        },

        async _atOwnerPush(map, node, event) {
            let dataKey, oprCat, oprScene, sceneNo

            for (const n of map.chain(node.parent)) {
                const {bizNodeType} = n.data

                if ('CAT' === bizNodeType) {
                    if (! oprCat) {
                        oprCat = map.BizNode.CAT.getTextRaw(map, n)
                    }
                }
                else if (map.BizNode[bizNodeType].classes.includes('comment')) {
                    continue
                }
                else if ('SF_MAIN' === bizNodeType) {
                    dataKey = 'mainOprList'
                    break
                }
                else if ('SCENE' === bizNodeType) {
                    dataKey = 'otherOprList'
                    oprScene = map.BizNode.SCENE.getTextRaw(map, n)
                    sceneNo = n.data.sceneNo
                    break
                }
            }

            if (! dataKey) {
                return
            }

            const d = await this._getPushData(map, node)

            Object.assign(d, {
                oprCat,
                oprScene,
                sceneNo,
                sfOprSfs: [],
            })

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType} = n.data

                if ('SF' === bizNodeType) {
                    const _n = map.nodeProxy(n)
                    const data = await _n._getPushData()
                    d.sfOprSfs.push(data)
                }
            }

            event.detail[dataKey].push(d)
        },

        _fixChild(map, node, child) {
            const {bizNodeType} = child.data

            if ('CRES' === bizNodeType) {
                map.BizNode.FRES.castFrom(map, child)
                return true
            }
            else {
                return OprNode._fixChild.call(this, map, node, child)
            }
        },
    })
}
