import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import SelectCm from '../CM/SelectCm.jsx'

const FormItemCms = (props) => {
    return (
        <Form.Item
            label="用例域"
            name="bms"
            {...props}
        >
            <SelectCm multiple />
        </Form.Item>
    )
}

const FormItemUcName = (props) => {
    return (
        <Form.Item
            label="集成用例名称"
            name="sfName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemUcNo = (props) => {
    return (
        <Form.Item
            label="集成用例代码"
            name="sfNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseUc = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemCms />
            </Col>

            <Col span={6}>
                <FormItemUcNo />
            </Col>

            <Col span={6}>
                <FormItemUcName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseUc
