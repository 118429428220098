import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBdSu.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,

        canLinkType(map, node, type) {
            return /^(UC)$/.test(type)
        },

        canMountType(map, node, type) {
            return /^(CAT)$/.test(type)
        },

        menuItemsInsertCommon(map, node) {
            return [
                ['CAT'],
            ]
        },

        menuItemsInsertProduct(map, node) {
            return [
                ['UC'],
            ]
        },

        async _atPush(map, node, event) {
            event.stopPropagation()

            const next = chain => {
                const _n = map.nodeProxy(chain[0])
                return {yieldChildren: _n.isMounted()}
            }

            for (const n of map.bfs(node.children, next)) {
                const {bizNodeType} = n.data

                if ('UC' === bizNodeType) {
                    const _n = map.nodeProxy(n)
                    const data = await _n._getPushData()
                    event.detail.sfList.push(data)
                }
            }
        },
    })
}
