import {Button} from 'antd'
import openWindow from '@/script/openWindow.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'
import metaBizNode from '../../metaBizNode.mjs'

export default function ButtonGotoArch(props) {
    const map = useMapContext()
    const [node] = map.useSelectedNodes()
    const {bizNodeType, prjId, sVer} = node.data
    const {archName, archUrl} = metaBizNode[bizNodeType]

    const handleClickDetail = e => {
        const target = e.altKey ? '_self' : '_blank'
        openWindow(archUrl, {prjId, sVer}, target)
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClickDetail}
            {...props}
        >
            {archName}
        </Button>
    )
}
