import SelectPrjDialog from './SelectPrjDialog'
import { Form, Input } from 'antd'
import { useState, useEffect } from 'react';
import {SearchOutlined} from '@ant-design/icons'
export default function SelectPrj({value, ...props}) {
    const [visiblePrj, setVisiblePrj] = useState(false);

    return (
        <>
            <Input value={value.sysPrjName} placeholder="请选择项目" disabled={props.disabled} addonAfter={<SearchOutlined  onClick={() => {
                if(props.disabled) return;
                setVisiblePrj(true)
            }} />} readOnly />
            {/* 选择项目 */}
            <SelectPrjDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={props.onChange} />
        </>
    )
}
