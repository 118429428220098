import React, { useEffect, useState } from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import { Button, Col, Form, Input, Row, Select, Space, Spin } from "antd";
import Modal, { useOpenModal } from "@/components/Modal/Modal";
import Permission from '@/components/Permission.jsx';
import SelectProjectDialog, { formatName } from "@/pages/MainPage/dialogs/SelectProjectDialog";
import { SearchOutlined } from "@ant-design/icons";
import SelectAmDialog from "@/pages/MainPage/dialogs/SelectAmDialog";
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function AddAmAi({ prjId, sVer, onOk, onCancel, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const openModal = useOpenModal();

    const [form] = Form.useForm();
    const formPrjId = Form.useWatch('prjId', form);
    const formSver = Form.useWatch('sVer', form);

    const [loading, setLoading] = useState(false);
    const [selectPrjVisible, setSelectPrjVisible] = useState(false);

    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        // 格式化名称
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()

        const name = await formatName({ prjId, prjName, sVer });
        form.setFieldsValue({ prjId, prjName: name, sVer });
    }

    // 回调，项目信息
    const handlePrjChange = async (value) => {
        if (value) {
            const name = await formatName(value);
            form.setFieldsValue({ prjId: value.pkid, prjName: name, sVer: value.sVer });
        } else {
            form.setFieldsValue({ prjId: null, prjName: null, sVer: null });
        }
    }

    const handleAmChange = async () => {
        const { prjId, sVer } = form.getFieldsValue()
        const result = await openModal(<SelectAmDialog params={{ prjId, sVer }} />);

        if (result) {
            form.setFieldsValue({ amCode: result.amCode, amName: result.amName });
        }
    }

    const handleOk = async () => {
        try {
            setLoading(true)
            const values = await form.validateFields();
            await runAsync(
                async () => {
                    return await http.post(`/amAi/addAmAi`, values);
                }, {
                error: { content: (err) => `新建失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });
            onOk(true)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal
                title="新建分析指标"
                width="600px"
                bodyStyle={{
                    padding: 12,
                }}
                {...props}
                onOk={handleOk}
                closable={true}
                onCancel={onCancel}
                footer={[
                    <Space>
                        <Permission permission="AMAI_CRT" projects={[formPrjId + formSver]}>
                            <Button size="small" onClick={() => onCancel(true)}>取消</Button>
                            <Button size="small" type="primary" onClick={handleOk} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
            >
                <Spin spinning={loading}>
                    <Form form={form} layout="vertical">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="prjId" hidden><Input allowClear /></Form.Item>
                                <Form.Item name="sVer" hidden><Input allowClear /></Form.Item>
                                <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目!' }]}>
                                    <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setSelectPrjVisible(true)} />} readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="amCode" hidden><Input allowClear /></Form.Item>
                                <Form.Item name="amName" label="分析域">
                                    <Input placeholder="请选择分析域" readOnly addonAfter={<SearchOutlined onClick={handleAmChange} />} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="aiNo" label="分析指标代码">
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="aiName" label="分析指标名称" rules={[{ required: true, message: '请输入分析指标名称' }]}>
                                    <Input allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>

            {/* 选择项目 */}
            <SelectProjectDialog visible={selectPrjVisible} setVisible={setSelectPrjVisible} onCall={handlePrjChange} />
        </>
    )
};
