import React, { useEffect, useState, useRef } from 'react';
import { Form, Space, Input, Button, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import openWindow from '@/script/openWindow.mjs';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs';

export default function ModalCreateLsi({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const { Option } = Select;

    // 表单
    const [form] = Form.useForm();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});
    const [lsList, setLsList] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
            await doSearchLs();
        })();
    }, []);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject();

        ref.current.prjId = prjId;
        ref.current.sVer = sVer;
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    // 新建外联接口
    const doAdd = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.prjId = ref.current.prjId;
            formField.sVer = ref.current.sVer;

            const data = await runAsync(
                async () => {
                    return await http.post(`/imlsis`, formField);
                }, {
                error: { content: (err) => `新建失败: ${err.message}` },
                loading: { show: false },
                success: { show: true },
            });

            onOk(data)
            openWindow('/LsiDetail', { lsiId: data.pkid, lsiName: data.lsiName });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调，项目信息
    const callPrj = async (value) => {
        if (value) {
            ref.current.prjId = value.pkid;
            ref.current.sVer = value.sVer;

            setPrj({ prjId: value.pkid, sVer: value.sVer });
            doSearchLs();

            const name = await formatName(value);
            form.setFieldsValue({ prjName: name, sVer: value.sVer, lsCode: '' });
        } else {
            ref.current.prjId = null;
            ref.current.sVer = null;

            setPrj({});
            setLsList([]);
            form.setFieldsValue({ prjName: '', sVer: '', lsCode: '' });
        }

        // callLs(null);
    }

    // 查询外联系统
    const doSearchLs = async () => {
        try {
            const param = {
                prjId: ref.current.prjId,
                sVer: ref.current.sVer,
                lsStsCodes: ['RLS', 'REVISE'],
            }

            const data = await runAsync(
                async () => {
                    return await http.get(`/archlss`, param);
                }, {
                error: { content: (err) => `查询外联系统失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setLsList(data);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <Modal
                title={'新建外联接口'}
                okText="保存"
                cancelText="取消"
                footer={[
                    <Space>
                        <Permission permission="LSI_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                    </Form.Item>

                    <Form.Item name="lsCode" label="外联系统" rules={[{ required: true, message: '请选择外联系统' }]}>
                        <Select>
                            {
                                lsList.map(v => <Option value={v.lsCode}>{v.lsName}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Form.Item name="lsiNo" label="接口代码" rules={[{ required: true, message: '请输入接口代码' }]}>
                        <Input placeholder="请输入接口代码" />
                    </Form.Item>

                    <Form.Item name="lsiName" label="接口名称" rules={[{ required: true, message: '请输入接口名称' }]}>
                        <Input placeholder="请输入接口名称" />
                    </Form.Item>
                </Form>
            </Modal>

            {/* 选择项目 */}
            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
