import {Col, Form, Input, Row} from 'antd'
import Select from '@/components/Form/Select.jsx'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import StoryType from '../BDSUMMARY/StoryType.jsx'

const FormItemAlgTitle = (props) => {
    return (
        <Form.Item
            label="用户场景标题"
            name="algTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdName = (props) => {
    return (
        <Form.Item
            label="业务能力模块名称"
            name="bdName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBdNo = (props) => {
    return (
        <Form.Item
            label="业务能力模块代码"
            name="bdNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemPrjNo = (props) => {
    return (
        <Form.Item
            label="项目编号"
            name="prjNo"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemStoryType = (props) => {
    return (
        <Form.Item
            label="用户场景类型"
            name="storyType"
            {...props}
        >
            <Select allowClear options={StoryType} />
        </Form.Item>
    )
}

const FormChooseBdsummary = (props) => {
    const brief = (
        <Row gutter={12}>
            <Col span={4}>
                <FormItemPrjNo />
            </Col>

            <Col span={5}>
                <FormItemBdName />
            </Col>

            <Col span={5}>
                <FormItemBdNo />
            </Col>

            <Col span={5}>
                <FormItemAlgTitle />
            </Col>

            <Col span={5}>
                <FormItemStoryType />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseBdsummary
