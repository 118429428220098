import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    sysPrjId: {
        name: () => '项目ID',
    },
    sysPrjNo: {
        name: () => '关联系统',
        required: () => true
    },
    sysPrjName: {
        name: () => '项目名称'
    },
})
