import ButtonImportProducts from '../_CAT_ROOT_MODULE/PropertiesPaneButtonImportProducts.jsx'
import CatRootModulePropertiesPane from '../_CAT_ROOT_MODULE/CatRootModulePropertiesPane.jsx'
import ButtonExportBd from './PropertiesPaneButtonExportBd.jsx'

export default function PropertiesPaneCatRootDm(props) {
    return (
        <CatRootModulePropertiesPane
            buttons={
                <>
                    <ButtonImportProducts />
                    <ButtonExportBd />
                </>
            }

            {...props}
        />
    )
}
