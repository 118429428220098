import React, { useEffect, useState } from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import { Button, Col, Form, Input, Row, Select, Space, Spin, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Modal from "@/components/Modal/Modal";
import Permission from '@/components/Permission.jsx';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import openWindow from '@/script/openWindow.mjs';
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateMid({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [middlewareTypes, setMiddlewareTypes] = useState([])
    const [prj, setPrj] = useState({});
    const [visiblePrj, setVisiblePrj] = useState(false);

    useEffect(() => {
        (async () => {
            await init();
            doQueryMiddlewareTypes();
        })();
    }, []);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.setFieldsValue({ prjId, prjName: name });
    }

    const doQueryMiddlewareTypes = async () => {
        try {
            setLoading(true)
            const data = await runAsync(
                async () => {
                    return await http.get(`/listEnableArchMiddlewareType`, {});
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            if (data) {
                setMiddlewareTypes(data);
            }
        } finally {
            setLoading(false)
        }
    }

    const handleOk = async () => {
        try {
            setLoading(true)
            const values = await form.validateFields();
            values.prjId = prj.prjId;
            values.sVer = prj.sVer;

            const data = await runAsync(
                async () => {
                    return await http.post(`/addArchMiddleware`, values);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });
            onOk(data)
            openWindow('/ArchMiddlewareDetail', { middlewareId: data.pkid });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }
    }

    return (
        <Modal
            title="新建中间件"
            width="600px"
            bodyStyle={{
                padding: 12,
            }}
            {...props}
            closable={true}
            footer={[
                <Space>
                    <Permission permission="MID_CRT" projects={[prj.prjId + prj.sVer]}>
                        <Button onClick={() => onOk()}>取消</Button>
                        <Button size="small" type="primary" onClick={handleOk} loading={loading}>保存</Button>
                    </Permission>
                </Space>
            ]}
            {...props}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Row gutter={24}>
                        {/* <Col span={24}>
                            <Form.Item name="prjId" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <ProjectSelect onChange={onChangePrj} />
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name="middlewareName" label="中间件名称" rules={[{ required: true, message: '请输入中间件名称' }]}>
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="middlewareDesc" label="中间件描述">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="middlewareType" label="中间件类型">
                                <Select allowClear
                                    options={middlewareTypes.map(({ pkid, middlewareTypeName }) => ({ value: pkid, label: middlewareTypeName }))}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="middlewareMemo" label="备注">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/*<Divider plain style={{marginTop: 0, marginBottom: 12, fontWeight: 'bold'}}>业务</Divider>*/}

                    {/*<Row gutter={24}>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareStatus" label="运行状态">*/}
                    {/*            <SelectMiddlewareStatus/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareBu" label="BU">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareDm" label="开发负责人">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareOm" label="运维负责人">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareEnv" label="环境">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {/*<Divider plain style={{marginTop: 0, marginBottom: 12, fontWeight: 'bold'}}>系统</Divider>*/}

                    {/*<Row gutter={24}>*/}
                    {/*   */}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareVersion" label="版本">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareSchema" label="Schema">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareIntranetIp" label="内网IP">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewareInternetIp" label="公网IP">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*    <Col span={6}>*/}
                    {/*        <Form.Item name="middlewarePort" label="端口">*/}
                    {/*            <Input allowClear/>*/}
                    {/*        </Form.Item>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                </Form>
            </Spin>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </Modal>
    )
};
