import React, { useEffect, useState } from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import { Button, Col, Divider, Form, Input, Row, Space, Spin, Typography } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import Modal from "@/components/Modal/Modal";
import SelectPsStatus from "@/pages/MainPage/panes/DepPsList/SelectPsStatus";
import DatePicker from "@/components/Form/DatePicker";
import Permission from '@/components/Permission.jsx';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import openWindow from '@/script/openWindow.mjs';
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreatePhys({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [prj, setPrj] = useState({});
    const [visiblePrj, setVisiblePrj] = useState(false);

    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjId, prjName: name });
    }

    const handleOk = async () => {
        try {
            setLoading(true)
            const values = await form.validateFields();
            values.prjId = prj.prjId;
            values.sVer = prj.sVer;

            const data = await runAsync(
                async () => {
                    return await http.post(`/addDepPs`, values);
                }, {
                error: { content: (err) => `新增失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            onOk(data)
            openWindow('/DepPsDetail', { psId: data.pkid });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }
    }

    return (
        <Modal
            title="新建物理机"
            width="1280px"
            bodyStyle={{
                padding: 12,
            }}
            {...props}
            closable={true}
            footer={[
                <Space>
                    <Permission permission="PHYS_CRT" projects={[prj.prjId + prj.sVer]}>
                        <Button onClick={() => onOk()}>取消</Button>
                        <Button size="small" type="primary" onClick={handleOk} loading={loading}>保存</Button>
                    </Permission>
                </Space>
            ]}
            {...props}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Divider plain style={{ marginTop: 0, marginBottom: 12, fontWeight: 'bold' }}>基本</Divider>
                    <Row gutter={24}>
                        {/* <Col span={6}>
                            <Form.Item name="prjId" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <SelectProjectList onChange={onChangePrj} />
                            </Form.Item>
                        </Col> */}

                        <Col span={6}>
                            <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item name="psName" label="物理机名称" rules={[{ required: true, message: '请输入物理机名称' }]}>
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psDesc" label="物理机描述">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psMemo" label="备注">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider plain style={{ marginTop: 0, marginBottom: 12, fontWeight: 'bold' }}>业务</Divider>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="psStatus" label="运行状态">
                                <SelectPsStatus />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psBu" label="BU">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psDm" label="开发负责人">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psOm" label="运维负责人">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psEnv" label="环境">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider plain style={{ marginTop: 0, marginBottom: 12, fontWeight: 'bold' }}>系统</Divider>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="psHostname" label="主机名">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psIntranetIp" label="内网IP">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psInternetIp" label="公网IP">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psOs" label="操作系统">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psKernelVersion" label="内核版本">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psOpenPort" label="开放端口">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider plain style={{ marginTop: 0, marginBottom: 12, fontWeight: 'bold' }}>硬件</Divider>

                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item name="psSerialNumber" label="服务器序列号">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psModel" label="服务器型号">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psCpu" label="CPU">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psMemory" label="内存">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psHardDisk" label="硬盘">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psMac" label="MAC">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider plain style={{ marginTop: 0, marginBottom: 12, fontWeight: 'bold' }}>其他</Divider>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="psLocation" label="物理位置">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psManufacturer" label="服务器厂家">
                                <Input allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item name="psPurchaseDate" label="采购日期">
                                <DatePicker />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </Modal>
    )
};
