import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '用例模型',
    archName: '用例地图',
    archUrl: '/ProjectUcMap',
    detailUrl: '/CaDetail',
    detailIdProp: 'caId',
    mapProp: 'caMap',
    moduleType: 'CM',
    name: '用例结构',
    type: 'CA',
}
