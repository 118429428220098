import CollapseSelect from '../../SidePanel/RightPanel/PropertiesControllerCollapseSelect.jsx'
import ArType from './ArType.jsx'

const PropertiesControllerArTypeCode = (props) => {
    const options = ArType.map(
        ([label, ...options]) => ({
            label,

            options: options.map(
                ([value, label, icon]) => ({icon, label, value})
            )
        })
    )

    return (
        <CollapseSelect
            options={options}
            {...props}
        />
    )
}

export default PropertiesControllerArTypeCode
