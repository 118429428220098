import http from './http.mjs'
import {setUser, useUser} from './user.mjs'

export default () => {
    const user = useUser()

    const {
        currPrjId,
        currPrjName,
        currPrjNo,
        currPrjSver,
        currPrjTeamId,
        pkid,
    } = user

    const currentProject = {
        pkid: currPrjId,
        prjName: currPrjName,
        prjNo: currPrjNo,
        sVer: currPrjSver,
        teamId: currPrjTeamId,
    }

    const setCurrentProject = async prj => {
        const {
            pkid: currPrjId,
            sVer: currPrjSver,
            prjName: currPrjName,
            prjNo: currPrjNo,
            teamId: currPrjTeamId,
        } = prj

        await http.put('/tmusers/modifyPrj', {currPrjId, currPrjSver, pkid})

        setUser({
            ...user,
            currPrjId,
            currPrjName,
            currPrjNo,
            currPrjSver,
            currPrjTeamId,
        })
    }

    return [currentProject, setCurrentProject]
}
