import http from '@/biz/http.mjs'
import { useUser } from '@/biz/user.mjs'

export default (prjId, sVer) => {
    const user = useUser()

    if (prjId && sVer) {
        return async () => {
            const {
                prjName,
                prjNo,
                prjTypeCode,
                subTypeCode,
            } = await http.get(`/prjs/${prjId}/info`)

            return {
                prjId,
                prjName,
                prjNo,
                prjTypeCode,
                subTypeCode,
                sVer,
            }
        }
    }
    else {
        return () => {
            const {
                currPrjId: prjId,
                currPrjName: prjName,
                currPrjNo: prjNo,
                currPrjSver: sVer,
                prjTypeCode: prjTypeCode,
                subTypeCode: subTypeCode,
            } = user

            return {
                prjId,
                prjName,
                prjNo,
                prjTypeCode,
                subTypeCode,
                sVer,
            }
        }
    }
}
