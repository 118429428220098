import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import IconLetters from '../icons/IconLetters.jsx'
import meta from './metaUc.mjs'
import Model from './ModelUc.mjs'
import PropertiesPane from './PropertiesPaneUc.jsx'
import PropertiesPaneBatch from './PropertiesPaneBatchUc.jsx'
import FormChoose from './FormChooseUc.jsx'
import TableChoose from './TableChooseUc.jsx'
import api from './apiUc.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        PropertiesPaneBatch,

        get pull() {
            return async (map, node) => {
                const {pkid, sfTypeCode} = node.data

                if ('DESC' === sfTypeCode) {
                    const {root} = await this.readMap({pkid})
                    await this.replace(map, node, root)
                }
                else {
                    const {get} = Object.getOwnPropertyDescriptor(
                        ProductNode, 'pull'
                    )

                    return get.bind(this)()(map, node)
                }
            }
        },

        canMountType(map, node, type) {
            const {sfTypeCode} = node.data

            if ('DESC' === sfTypeCode) {
                return /^(AR)$/.test(type)
            }
            else {
                return /^(ROLE)$/.test(type)
            }
        },

        async choose(map, node) {
            const getQuery = ({cms = [], ...query}) => ({
                ...query,
                cmIds: cms.map(({cmId}) => cmId),
            })

            return this._choose(map, node, {getQuery})
        },

        defaultChildType(map, node) {
            const {sfTypeCode} = node.data

            if ('DESC' === sfTypeCode) {
                return 'AR'
            }
            else {
                return 'ROLE'
            }
        },

        getIcons(map, node) {
            return [
                <IconLetters
                    key="type"
                    fill="#fff"
                    letters="M"
                    textColor="#000"
                />
            ]
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {backgroundColor: Color.GOLD}),
                shape: 'BreakangleRectangle',
            }
        },

        menuItemsInsertCommon(map, node) {
            const {sfTypeCode} = node.data

            if ('DESC' === sfTypeCode) {
                return [
                    ['AR'],
                ]
            }
            else {
                return [
                    ['ROLE'],
                ]
            }
        },

        async _atCreate(map, node) {
            for (const n of map.chain(node.parent)) {
                const {
                    bizNodeType,
                    cmCode,
                    cmId,
                    cmName,
                } = n.data

                if (/^CAT_ROOT_CM$/.test(bizNodeType)) {
                    node.data = {
                        ...node.data,
                        cmCode,
                        cmId,
                        cmName,
                    }

                    break
                }
            }

            await ProductNode._atCreate.call(this, map, node)
        },

        async _atPull(map, node) {
            await ProductNode._atPull.call(this, map, node)
            node.isFolded = false
        },

        _pushDataSlots: {
            ucOprList: [],
        },
    })
}
