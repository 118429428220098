import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectPrj from './SelectPrj.jsx'
import { Input } from 'antd'

export default function PropertiesControllerPrj({
    className,
    style,
    codeProp = 'sysPrjNo',
    disabled,
    hidden,
    idProp = 'sysPrjId',
    label,
    labelSuffix,
    nameProp = 'sysPrjName',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: sysPrjNo,
        [idProp]: sysPrjId,
        [nameProp]: sysPrjName,
    } = values

    const handleChange = am => {
        const {
            prjNo = '',
            pkid = '',
            prjName = '',
        } = am ?? {}
        update({
            [codeProp]: prjNo,
            [idProp]: pkid,
            [nameProp]: prjName,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectPrj
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={{sysPrjNo, sysPrjId, sysPrjName}}
                onChange={handleChange}
                {...props}
            />
            {/* <Input value={values.teamId} /> */}
        </PropertiesController>
    )
}
