import usePreviewUi from './usePreviewUi.jsx'

export default () => {
    const previewUi = usePreviewUi()

    return (map, nodes) => {
        if (1 < nodes.size) {
            return []
        }

        const [ui] = nodes

        if (! ui.parent) {
            return []
        }

        const onClick = e => {
            const [ui] = nodes
            const target = e.altKey ? '_self' : '_blank'
            previewUi(map, ui, target)
        }

        return [
            {
                key: 'preview',
                label: '预览',
                onClick,
            }
        ]
    }
}
