import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectDataTypeGroup from '../components/SelectDataTypeGroup.jsx'

export default function PropertiesControllerDataTypeGrpCode({
    className,
    style,
    ...props
}) {
    const [values, update] = useProperties()
    const p = usePropertiesController({prop: 'dataTypeGrpCode'})

    const handleChange = (dataTypeGrpCode) => {
        if (/^(Basic|Other)$/.test(dataTypeGrpCode)) {
            update({
                dfDtoCode: '',
                dfDtoId: '',
                dfDtoName: '',
                dfDtoPrjId: '',
                dfDtoRev: '',
                dfDtoSVer: '',
                dfDtoUserCode: '',
                dataTypeGrpCode,
            })
        }
        else if ('DTO' === dataTypeGrpCode) {
            update({
                dataTypeCode: '',
                dataTypeGrpCode,
            })
        }
        else {
            update({dataTypeGrpCode})
        }
    }

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            required={p.required}
        >
            <SelectDataTypeGroup
                disabled={p.disabled}
                readOnly={p.readOnly}
                value={values.dataTypeGrpCode}
                onChange={handleChange}
                {...props}
            />
        </PropertiesController>
    )
}
