import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

const TableChooseBdrule = (props) => {
    const columns = [
        {
            title: '业务能力模块名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务能力模块代码',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '接口业务事务能力标题',
            dataIndex: 'ruleTitle',
            component: <Table.ViewText />,
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseBdrule
