import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import superMeta from '../_ATTRIBUTE/metaAttributeNode.mjs'
import useAttributeNode from '../_ATTRIBUTE/useAttributeNode.jsx'

export default () => {
    const AttributeNode = useAttributeNode()

    return extendNode(AttributeNode, {
        ...superMeta,
        prop: 'resume',
        type: 'ATTR_BDRULE_RESUME',

        getStyle(map, node) {
            const {ruleType} = node.parent.data

            const borderColor = (! ruleType || 'COMMON' === ruleType) ?
                Color.LIGHT_YELLOW : Color.CYAN

            return {
                ...AttributeNode.getStyle.call(this, map, node),
                borderColor,
            }
        },
    })
}
