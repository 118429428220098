import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Form, Input, Select, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfSubmitPreApproval({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [mrms, setMrms] = useState([]);

    const doQueryUsers = async () => {
        const data = await runAsync(
            async () => {
                return await http.get(`/listPrjMbrBySpecialRole`, {prjId: record.prjId});
            }, {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });

        const mrms = []
        if (data) {
            data.forEach(({userId, name, prjGrpTypeCode}) => {
                if (prjGrpTypeCode === 'RM') {
                    mrms.push({
                        label: name,
                        value: userId
                    })
                }
            })
        }
        setMrms(mrms)
    }

    const handleOk = async () => {
        form.validateFields().then(async values =>{
            try {
                setLoading(true)
                await runAsync(
                    async () => {
                        return await http.post(`/brf/submitPreApproval`, {...values}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                    }, {
                        error: {content: (err) => `提交预审失败: ${err.message}`},
                        loading: {show: true},
                        success: {show: true},
                    });
                onOk(true)
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        form.setFieldsValue(record)
        doQueryUsers()
    }, []);

    return (
        <Modal
            title="提交预审"
            width="600px"
            {...props}
            onOk={handleOk}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Form.Item name='pkid' hidden><Input/></Form.Item>
                    <Form.Item
                        label='需求经理'
                        name='mrmId'
                        rules={[
                            {
                                required: true,
                                message: '请选择需求经理',
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            options={mrms}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
