import extendNode from '../extendNode.mjs'
import useAbstractNode from '../_ABSTRACT/useAbstractNode.jsx'
import meta from './metaBdsummary_1.mjs'
import FormChoose from './FormChooseBdsummary_1.jsx'
import TableChoose from './TableChooseBdsummary_1.jsx'
import api from './apiBdsummary_1.mjs'

export default () => {
    const AbstractNode = useAbstractNode()

    return extendNode(AbstractNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
    })
}
