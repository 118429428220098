import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    alias: '',
    archName: '',
    archUrl: '',
    classes: ['arch', ...superMeta.classes],
    moduleType: '',
}
