import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaBdruleDe.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        isHidden(map, node) {
            const {ruleType} = node.parent?.data ?? {}

            return (
                'DE' !== ruleType ||
                ArCatNode.isHidden.call(this, map, node)
            )
        },
    })
}
