import extendNode from '../extendNode.mjs'
import useCategoryNode from '../_CATEGORY/useCategoryNode.jsx'
import meta from './metaBrfSysProductionBranch.mjs'

export default () => {
    const CategoryNode = useCategoryNode()

    return extendNode(CategoryNode, {
        ...meta,
        canMountType(map, node, type) {
            return /^(BRFBRANCH)$/.test(type)
        },
        menuItemsInsertProduct(map, node) {
            return [
                ['BRFBRANCH'],
            ]
        },
        canWriteTree(map) {
            const data = map.root.data;

            return ['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED', 'PENDING_DEFINE', 'PRODUCTION_APPROVAL_REJECTED'].includes(data.brfStsCode)
        },
    })
}
