import openWindow from '@/script/openWindow.mjs'

export const canPreviewUv = (map, uv) => {
    const {demoType, pkid: uvId, uiId} = uv.data

    if (! uvId) {
        return false
    }

    if ('IMG' === demoType) {
        if (! uiId) {
            return false
        }

        return [...map.children(uv)].some(
            n => n.data.fileId
        )
    }

    return true
}

export const previewUv = (map, uv, target) => {
    const {demoType, pkid: uvId, uiId} = uv.data
    const params = new URLSearchParams(location.search.split("?")[1]);
    let otherProps = {};
    if(location.pathname == '/FormilyPreview' && params.get('prjId')) {
        otherProps.prjId = params.get('prjId');
        otherProps.sVer = params.get('sVer') || 1;
    }

    ;({
        DEMO: () => {
            openWindow('/FormilyPreview', { ...otherProps, uvId }, target)
        },

        IMG: () => {
            const images = [...map.children(uv)]
                .map(n => n.data.fileId)
                .filter(a => a)
                .join(',')

            openWindow('/FormilyPreview', { ...otherProps, images, uiId }, target)
        },
    })[demoType]()
}
