import {useUser} from '@/biz/user.mjs'

const collectPermissions = (...dicts) => {
    const permissions = {}

    for (const id2codes of dicts) {
        for (const [id, codes] of Object.entries(id2codes ?? {})) {
            if (permissions[id]) {
                permissions[id].push(...codes)
            }
            else {
                permissions[id] = [...codes]
            }
        }
    }

    return permissions
}

const usePermission = (permissions, projects, teams, conditions = true) => {
    const user = useUser()

    if (! user) {
        return false
    }

    const {
        projectFaMap,
        projectMenuMap,
        teamFaMap,
        teamMenuMap,
    } = user

    const projectPermission = collectPermissions(projectMenuMap, projectFaMap)
    const teamPermission = collectPermissions(teamMenuMap, teamFaMap)

    // 权限代码
    const permCode = permissions[0];

    if (filter(user, permCode)) {
        return true;
    }

    if (projects.length > 0) {  // 项目
        let voteCount = 0;
        for (const project of projects) {
            const pers = projectPermission[project] ?? [];
            if (pers.indexOf(permCode) >= 0) {
                voteCount++;
            }
        }

        return voteCount == projects.length && conditions;
    }
    else if (teams.length > 0) { // 团队
        let voteCount = 0;
        for (const team of teams) {
            const pers = teamPermission[team] ?? [];
            if (pers.indexOf(permCode) >= 0) {
                voteCount++;
            }
        }

        return voteCount == teams.length && conditions;
    }

    return false;
}

const filter = (user, permCode) => {
    if (! user) {
        return false
    }

    // 平台管理员
    if (user.isPlatformAdm == 1) {
        return true;
    }

    // 注册未审批个人号
    if ('TM' == permCode || 'TM_USER' == permCode) {
        return true;
    }

    // 注册有效个人号
    if (user.stsCode == 'VALID' && 'TM_TEAM' == permCode) {
        return true;
    }

    return false;
}

// 提供菜单权限控制
export const useMenu = (code, teams) => {
    const user = useUser()

    if (! user) {
        return false
    }

    if (filter(user, code)) {
        return true;
    }

    const {teamFaMap, teamMenuMap} = user
    const teamPermission = collectPermissions(teamMenuMap, teamFaMap)

    if (teams.length > 0) {
        let voteCount = 0;
        for (const team of teams) {
            const pers = teamPermission[team] ?? [];
            if (pers.indexOf(code) >= 0) {
                voteCount++;
            }
        }

        return voteCount > 0;
    }

    return false;
}

export default usePermission;
