import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonImportProductsFromXls({
    type,
    ...props
}) {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        1 === selectedNodes.size &&
        map.permission.has('write')
    )) {
        return null
    }

    const [node] = selectedNodes
    const {bizNodeType} = node.data

    if (! map.BizNode[bizNodeType].canWriteTree(map, node)) {
        return null
    }


    const handleClick = async () => {
        await map.commands.importProductsFromXls(type)
        await map.commands.refreshProduct([map.root])
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}
