import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'
import SelectBm from '../BM/SelectBm.jsx'
import SelectFm from '../FM/SelectFm.jsx'
import SelectSs from '../SS/SelectSs.jsx'

const FormItemBms = (props) => {
    return (
        <Form.Item
            label="业务域"
            name="bms"
            {...props}
        >
            <SelectBm multiple />
        </Form.Item>
    )
}

const FormItemFms = (props) => {
    return (
        <Form.Item
            label="功能域"
            name="fms"
            {...props}
        >
            <SelectFm multiple />
        </Form.Item>
    )
}

const FormItemSs = (props) => {
    return (
        <Form.Item
            label="子系统"
            name="ss"
            {...props}
        >
            <SelectSs />
        </Form.Item>
    )
}

const FormItemUiCode = (props) => {
    return (
        <Form.Item
            label="界面代码"
            name="uiUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemUiName = (props) => {
    return (
        <Form.Item
            label="界面名称"
            name="uiName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormChooseUi = (props) => {
    const map = useMapContext()
    const {prjTypeCode, subTypeCode} = map.data

    const formItemModule = (() => {
        if (
            'BM' === prjTypeCode ||
            'BODA2' === subTypeCode
        ) {
            return <FormItemBms />
        }
        else {
            return <FormItemFms />
        }
    })()

    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                {formItemModule}
            </Col>

            <Col span={6}>
                <FormItemSs />
            </Col>

            <Col span={6}>
                <FormItemUiCode />
            </Col>

            <Col span={6}>
                <FormItemUiName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}

export default FormChooseUi
