import {useOpenModal} from '@/components/Modal/Modal.jsx'
import ImportBmDialog from '@/pages/MainPage/dialogs/ImportBmDialog.jsx'
//import ImportCmDialog from '@/pages/MainPage/dialogs/ImportCmDialog.jsx'
import ImportDpDialog from '@/pages/MainPage/dialogs/ImportDpDialog.jsx'
import ImportFmDialog from '@/pages/MainPage/dialogs/ImportFmDialog.jsx'
import ImportPmDialog from '@/pages/MainPage/dialogs/ImportPmDialog.jsx'
import ImportUmDialog from '@/pages/MainPage/dialogs/ImportUmDialog.jsx'

export const importProductsFromXls = () => () => {
    const openModal = useOpenModal()

    return async function (type) {
        const {prjId, prjName, prjNo, sVer} = this.data
        const project = {prjId, prjName, prjNo, sVer}

        const dialog = {
            bd: <ImportDpDialog type="importBd" {...project} />,
            bdrule: <ImportDpDialog type="importBdRule" {...project} />,
            bf_bm: <ImportDpDialog type="importBfForBm" {...project} />,
            bf_pm: <ImportDpDialog type="importBfForPm" {...project} />,
            bm: <ImportBmDialog {...project} />,
            //cm: <ImportCmDialog {...project} />,
            fm: <ImportFmDialog {...project} />,
            pm: <ImportPmDialog {...project} />,
            ui: <ImportDpDialog type="importUi" {...project} />,
            um: <ImportUmDialog {...project} />,
        }[type]

        return openModal(dialog)
    }
}
