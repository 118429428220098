import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    bmCode: {
        name: () => '业务域',
    },

    bdName: {
        name: () => '业务能力模块名称',
        required: () => true,
    },

    bdNo: {
        name: () => '业务能力模块代码',
        required: () => true,
    },

    bdType: {
        name: () => '业务能力模块类型',
        required: () => true,
    },

    memo: {
        name: () => '备注',
    },

    ssCode: {
        name: () => '子系统',
    },
})
