import superMeta from '../_ARCH/metaArchNode.mjs'

export default {
    ...superMeta,
    alias: '流程模型',
    archName: '流程地图',
    archUrl: '/ProjectBfMap',
    detailUrl: '/PaDetail',
    detailIdProp: 'paId',
    mapProp: 'paMap',
    moduleType: 'PM',
    name: '流程结构',
    type: 'PA',
}
