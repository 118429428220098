import {useRef} from 'react'
import {makeKeywordsMatcher} from 'hopedove-dom/str'
import useControlledState from '@/hooks/useControlledState.mjs'

const selectedCountMap = new Map()
const lastSelectedMap = new Map()

const defaultOptions = []
const defaultGetOptionLabel = a => a
const defaultGetOptionStyle = a => ({})
const nop = () => {}

const useSelectable = ({
    autoSelect = nop,
    getOptionKey = defaultGetOptionLabel,
    getOptionLabel = defaultGetOptionLabel,
    getOptionSearchText = getOptionLabel,
    getOptionStyle = defaultGetOptionStyle,
    getOptionTitle = getOptionLabel,
    getOptionWriteBack = getOptionLabel,
    hottestFirst = '',
    onChange = nop,
    options = defaultOptions,
    value,
}) => {
    const refLastOptions = useRef(defaultOptions)

    const [controlledValue, setControlledValue] = useControlledState(
        void 0, value, onChange
    )

    const inputValue = controlledValue && getOptionKey(controlledValue)

    const kvs = new Map(
        options.map(e => [getOptionKey(e), e])
    )

    if (
        options !== refLastOptions.current &&
        ! kvs.has(inputValue)
    ) {
        if (0 < options.length) {
            const option = autoSelect(options)
            setControlledValue(option)
        }
        else {
            setControlledValue(void 0)
        }
    }

    refLastOptions.current = options

    const antdOptions = (() => {
        const antdOptions = options.map(e => ({
            label: getOptionLabel(e),
            searchText: getOptionSearchText(e),
            style: getOptionStyle(e),
            title: getOptionTitle(e),
            value: getOptionKey(e),
            writeBack: getOptionWriteBack(e),
        }))

        if (hottestFirst) {
            const selectedCount = selectedCountMap.get(hottestFirst)
            const lastSelected = lastSelectedMap.get(hottestFirst)

            antdOptions.sort(({value: a}, {value: b}) => {
                if (lastSelected === a) {
                    return -1
                }

                if (lastSelected === b) {
                    return 1
                }

                if (selectedCount) {
                    const selectedCountA = selectedCount.get(a) ?? 0
                    const selectedCountB = selectedCount.get(b) ?? 0
                    return selectedCountB - selectedCountA
                }

                return 0
            })
        }

        return antdOptions
    })()

    let keyword

    const filterOption = (input, option) => {
        keyword = input
        const match = makeKeywordsMatcher(input)

        if (match) {
            return match(option.searchText)
        }
        else {
            return true
        }
    }

    const filterSort = (a, b) => {
        const ia = a.searchText.indexOf(keyword)
        const ib = b.searchText.indexOf(keyword)
        return ia - ib
    }

    const handleChange = (value) => {
        if (hottestFirst) {
            if (! selectedCountMap.has(hottestFirst)) {
                selectedCountMap.set(hottestFirst, new Map())
            }

            const selectedCount = selectedCountMap.get(hottestFirst)
            const count = selectedCount.get(value) ?? 0
            selectedCount.set(value, count + 1)
            lastSelectedMap.set(hottestFirst, value)
        }

        setControlledValue(kvs.get(value))
    }

    return {
        antdOptions,
        filterOption,
        filterSort,
        handleChange,
        inputValue,
    }
}

export default useSelectable
