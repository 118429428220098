import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import Model from './ModelBrf.mjs'
import Am from '../AM/PropertiesControllerAm.jsx'
import TmUser from '../BRFSYS/PropertiesControllerTm.jsx'
import BrfStatus from './PropertiesControllerBrfStatus.jsx'
import Rm from '../RM/PropertiesControllerRm.jsx'
import { useUser } from "@/biz/user.mjs";


export default function PropertiesPaneDo(props) {
    const user = useUser();
    const data = props.nodes[0]?.data;
    return (
        <ProductPropertiesPane
            status={<BrfStatus />}
            controllers={
                <>
                    {['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode) ? <Rm /> : 
                        <Text prop="rmName" />
                    }
                    
                    <Text prop="brfModeName" />
                    <Text prop="brfTitle"  disabled={!['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)} />
                    <LongText prop="resume"  disabled={!['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)} />
                    <Text prop="crtName"  />
                    <Text prop="crtTime" />
                    <Text prop="mrmName" />
                    {/* <TmUser prop='mrm' label="需求经理" disabled={user.pkid != data.crtId} />
                    <TmUser prop='mbm' label="主牵业务经理" disabled={user.pkid != data.mrmId || ['DRAFT', 'PRE_APPROVAL_REJECTED', 'PENDING_ACCESS_APPROVAL'].includes(data.brfStsCode)} />
                    <TmUser prop='mtm' label="主牵技术经理" disabled={user.pkid != data.mrmId || data.brfStsCode != 'PENDING_ACCESS_APPROVAL'} /> */}
                    <Text prop="mtmName" />
                    <Text prop="mbmName" />
                    {/* 
                    <TmUser prop='mtm' label="主牵技术经理" /> */}
                </>
            }

            Model={Model}

            snSuffix={
                <Analyse
                    modes={[
                    ]}
                />
            }

            {...props}
        />
    )
}
