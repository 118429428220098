import {useMapContext} from '@/components/MindMap/index.mjs'
import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonJumpStruct from './PropertiesPaneButtonJumpStruct.jsx'
import ButtonGotoArch from './ButtonGotoArch.jsx'

export default function ArchPropertiesPane({buttons, ...props}) {
    const map = useMapContext()
    const [node] = map.useSelectedNodes()
    const {bizNodeType} = node.data
    const {name} = map.BizNode[bizNodeType]
    const isSaMap = 'SA_MAP' === map.data.mapTypeCode

    return (
        <ProductPropertiesPane
            buttons={
                <>
                    <ButtonJumpStruct />
                    {buttons}
                </>
            }

            sn={isSaMap ? null : void 0}
            snSuffix={<ButtonGotoArch />}
            status={isSaMap ? null : void 0}
            versionLabel={`${name}版本`}
            {...props}
        />
    )
}
