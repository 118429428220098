import {useState} from 'react'
import http from '@/biz/http.mjs'
import Modal, {useOpenModal} from '@/components/Modal/Modal.jsx'
import Table from '@/components/Table/Table.jsx'
import runAsync from '@/script/runAsync.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

export const canAddNodeToBranch = () => () => {
    return function (nodes) {
        return [...nodes].filter(node => {
            const {bizNodeType, pkid, prjId} = node.data

            return (
                pkid &&
                prjId === this.data.prjId &&
                this.BizNode[bizNodeType]?.classes.includes('product')
            )
        })
    }
}

const readTemplates = async scene => {
    const templates = await http.get(
        '/llmPromptTemplate/listEnableLlmPromptTemplate',
        {usageScenarioCode: scene}
    )

    return templates.map(({llmPromptTemplateItems, ...data}) => ({
        ...data,

        llmPromptTemplateItems: llmPromptTemplateItems.map(
            ({modelInfomationConfig, ...data}) => ({
                ...data,

                modelInfomationConfig: modelInfomationConfig ?
                    JSON.parse(modelInfomationConfig) : []
            })
        ),
    }))
}

const TableTemplates = ({scene, onChange, ...props}) => {
    const {data, isLoading} = useSwrNoCache(
        () => runAsync(
            () => readTemplates(scene),
            {action: '导入模板'},
        ),

        {dependencies: [scene]},
    )

    const columns = [
        {
            title: '工作流名称',
            dataIndex: 'promptTemplateName',
            component: <Table.ViewText />,
        },
    ]

    const expandedRowRender = row => {
        const columns = [
            {
                title: '模板条目名称',
                dataIndex: 'itemName',
                component: <Table.ViewText />,
            },

            {
                title: '提示词模板',
                dataIndex: 'userMessageTemplate',
                component: <Table.ViewLongText />,
            },

            {
                title: '关联大模型',
                dataIndex: 'llmName',
                component: <Table.ViewText />,
            },

            {
                title: '信息结构',
                dataIndex: 'is',
                getValue: row => row.modelInfomationConfig.includes('IS'),
                width: 50,
                component: <Table.ViewBoolean />,
            },

            {
                title: '业务能力',
                dataIndex: 'bdalg',
                getValue: row => row.modelInfomationConfig.includes('BDALG'),
                width: 50,
                component: <Table.ViewBoolean />,
            },

            {
                title: '数据表',
                dataIndex: 'dt',
                getValue: row => row.modelInfomationConfig.includes('DT'),
                width: 50,
                component: <Table.ViewBoolean />,
            },
        ]

        return (
            <Table
                columns={columns}
                dataSource={row.llmPromptTemplateItems}
                pagination={false}
                rowKey="pkid"
                {...props}
            />
        )
    }

    const rowSelection = {
        onChange: (_, selectedRows) => onChange(selectedRows),
        type: 'radio',
    }

    return (
        <Table
            columns={columns}
            dataSource={data}
            // expandable={{expandedRowRender}}
            loading={isLoading}
            rowKey="pkid"
            rowSelection={rowSelection}
            selectRowOnClick
            {...props}
        />
    )
}

const ModalChooseTemplate = ({scene, onOk, ...props}) => {
    const [template, setTemplate] = useState()
    const okButtonProps = {disabled: ! template}
    const onTableChange = rows => setTemplate(rows[0])
    const _onOk = () => onOk(template)

    return (
        <Modal
            okButtonProps={okButtonProps}
            title="选择工作流"
            width={800}
            onOk={_onOk}
            {...props}
        >
            <TableTemplates
                scene={scene}
                onChange={onTableChange}
            />
        </Modal>
    )
}

export default () => {
    const openModal = useOpenModal()

    return async scene => {
        const template = await openModal(<ModalChooseTemplate scene={scene} />)

        if (! template) {
            return
        }

        const prompts = template.llmPromptTemplateItems.map(e => {
            const {
                extensionConfig,
                itemCode: stepCode,
                itemName: stepName,
                llmId,
                llmName,
                pkid: stepId,
                systemMessageTemplate: systemMessage,
                toolMessageTemplate: toolMessage,
                userMessageTemplate: userMessage,
            } = e

            return {
                extensionConfig,
                files: [],
                llmId,
                llmName,
                stepCode,
                stepId,
                stepName,
                systemMessage,
                toolMessage,
                userMessage,
            }
        })

        return {prompts, template}
    }
}
