import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";

export const selectDb = async (url, param) => {
    const http = useHTTP();

    const data = await runAsync(
        async () => {
            return await http.get(url, param);
        }, {
        error: { content: (err) => `查询失败: ${err.message}` },
        loading: { show: false },
        success: { show: false },
    });

    return data;
}

export const getBaseline = async (prjId, sVer) => {
    const key = "Baseline" + prjId + sVer;
    const json = sessionStorage.getItem(key);

    if (json) {
        return JSON.parse(json);
    } else {
        const data = await selectDb('/onePrjBaseline', { prjId, sVer });
        sessionStorage.setItem(key, JSON.stringify(data));
        return data;
    }
}

export const isIncrement = async (prjId, sVer) => {
    const key = "Baseline" + prjId + sVer;
    const json = sessionStorage.getItem(key);

    if (json) {
        const baseline = JSON.parse(json);
        return 'INCREMENT' == baseline.pblType;
    } else {
        const data = await selectDb('/onePrjBaseline', { prjId, sVer });
        sessionStorage.setItem(key, JSON.stringify(data));
        return 'INCREMENT' == data.pblType;
    }
}