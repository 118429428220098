import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {usePopover} from '@/components/Popover/index.mjs'
import {MapContext, useMapContext} from '@/components/MindMap/index.mjs'
import useMenuItemAddNodeToBranch from '../../menuItems/useMenuItemAddNodeToBranch.jsx'
import useMenuItemClearNodeText from '../../menuItems/useMenuItemClearNodeText.jsx'
import useMenuItemCopy from '../../menuItems/useMenuItemCopy.jsx'
import useMenuItemCopyNodeId from '../../menuItems/useMenuItemCopyNodeId.jsx'
import {useMenuItemCopyNodeTextNewLine, useMenuItemCopyNodeTextChineseSerialComma} from '../../menuItems/useMenuItemCopyNodeText.jsx'
import useMenuItemCopyNodeUrl from '../../menuItems/useMenuItemCopyNodeUrl.jsx'
import useMenuItemCopyStyle from '../../menuItems/useMenuItemCopyStyle.jsx'
import useMenuItemCut from '../../menuItems/useMenuItemCut.jsx'
import useMenuItemDelete from '../../menuItems/useMenuItemDelete.jsx'
import useMenuItemDeleteSingleNode from '../../menuItems/useMenuItemDeleteSingleNode.jsx'
import useMenuItemDuplicate from '../../menuItems/useMenuItemDuplicate.jsx'
import useMenuItemEditDesign from '../../menuItems/useMenuItemEditDesign.jsx'
import useMenuItemExportNode from '../../menuItems/useMenuItemExportNode.jsx'
import useMenuItemFold from '../../menuItems/useMenuItemFold.jsx'
import useMenuItemGrowNode from '../../menuItems/useMenuItemGrowNode.jsx'
import useMenuItemGrowNodeRecursive from '../../menuItems/useMenuItemGrowNodeRecursive.jsx'
import useMenuItemHide from '../../menuItems/useMenuItemHide.jsx'
import useMenuItemMakeProduct from '../../menuItems/useMenuItemMakeProduct.jsx'
import useMenuItemMark from '../../menuItems/useMenuItemMark.jsx'
import useMenuItemMoveDown from '../../menuItems/useMenuItemMoveDown.jsx'
import useMenuItemMoveUp from '../../menuItems/useMenuItemMoveUp.jsx'
import useMenuItemPasteAfter from '../../menuItems/useMenuItemPasteAfter.jsx'
import useMenuItemPaste from '../../menuItems/useMenuItemPaste.jsx'
import useMenuItemPasteStyle from '../../menuItems/useMenuItemPasteStyle.jsx'
import useMenuItemResetStyle from '../../menuItems/useMenuItemResetStyle.jsx'
import useMenuItemShrinkNode from '../../menuItems/useMenuItemShrinkNode.jsx'
import useMenuItemsInsertNode from '../../menuItems/useMenuItemsInsertNode.jsx'

const useBizNodeMenuItems = () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (0 === selectedNodes.size) {
        return []
    }

    let type = ''

    for (const n of selectedNodes) {
        const {bizNodeType} = n.data

        if (type) {
            if (type !== bizNodeType) {
                return []
            }
        }
        else {
            type = bizNodeType
        }
    }

    return map.BizNode[type].contextMenu(map, selectedNodes)
}

const useSubMenuExportNode = () => {
    const children = [
        useMenuItemExportNode('image'),
        //useMenuItemExportNode('pdf'),
    ].filter(a => a)

    if (0 === children.length) {
        return null
    }

    return {
        children,
        key: 'export-node',
        label: '导出',
    }
}

const useSubMenuInsertNode = (type) => {
    const children = useMenuItemsInsertNode(type)

    if (0 === children.length) {
        return null
    }

    const typeText = {
        common: '通用',
        concept: '模件定义',
        product: '模件链接',
    }[type]

    return {
        children,
        key: `insert-${type}`,
        label: `插入${typeText}节点`,
    }
}

const NodeContextMenu = (props) => {
    const items = [
        ...useBizNodeMenuItems(),
        useMenuItemAddNodeToBranch(),
        {divider: true},
        useMenuItemEditDesign(),
        useMenuItemCopyNodeUrl(),
        useMenuItemCopyNodeId(),
        useMenuItemCopyNodeTextNewLine(),
        useMenuItemCopyNodeTextChineseSerialComma(),
        useMenuItemClearNodeText(),
        {divider: true},
        useMenuItemCut(),
        useMenuItemCopy(),
        useMenuItemPaste(),
        useMenuItemPasteAfter(),
        useMenuItemDuplicate(),
        useMenuItemDelete(),
        useMenuItemDeleteSingleNode(),
        {divider: true},
        useMenuItemCopyStyle(),
        useMenuItemPasteStyle(),
        useMenuItemResetStyle(),
        {divider: true},
        useMenuItemMoveUp(),
        useMenuItemMoveDown(),
        {divider: true},
        useMenuItemFold(),
        {divider: true},
        useSubMenuInsertNode('common'),
        useSubMenuInsertNode('concept'),
        useSubMenuInsertNode('product'),
        {divider: true},
        useMenuItemMakeProduct(),
        useMenuItemGrowNode(),
        useMenuItemGrowNodeRecursive(),
        useMenuItemShrinkNode(),
        {divider: true},
        useMenuItemHide(),
        useMenuItemMark(),
        useSubMenuExportNode(),
    ].filter(a => a)

    return (
        <ContextMenu
            items={items}
            {...props}
        />
    )
}

export const onContextMenu = () => {
    const popover = usePopover()

    return () => async function (node, event) {
        await this.behaviours.onClickNode(node, event)
        const {clientX: x, clientY: y} = event

        const content = (
            <MapContext.Provider value={this}>
                <NodeContextMenu />
            </MapContext.Provider>
        )

        popover.open(content, {placement: 'bottom right', x, y})
    }
}
