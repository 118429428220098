import {Button} from 'antd'
import ContextMenu from '@/components/ContextMenu/ContextMenu.jsx'
import {useOpenModal} from '@/components/Modal/Modal.jsx'
import {PopoverTrigger} from '@/components/Popover/index.mjs'
import ModalCreateAmAi from '@/pages/MainPage/panes/AmAiList/AddAmAi.jsx'
import ModalCreateAmAr from '@/pages/MainPage/panes/AmArList/AddAmAr.jsx'
import ModalCreateAo from '@/pages/MainPage/panes/AoList/ModalCreateAo.jsx'
import ModalCreateBd from '@/pages/MainPage/panes/BdList/ModalCreateBd.jsx'
import ModalCreateBf from '@/pages/MainPage/panes/BfList/ModalCreateBf.jsx'
import ModalCreateCdg from '@/pages/MainPage/panes/DmPcdgList/ModalCreateCdg.jsx'
import ModalCreateCdt from '@/pages/MainPage/panes/CdtList/ModalCreateCdt.jsx'
import ModalCreateDb from '@/pages/MainPage/panes/DbList/ModalCreateDb.jsx'
import ModalCreateDo from '@/pages/MainPage/panes/DoList/ModalCreateDo.jsx'
import ModalCreateDt from '@/pages/MainPage/panes/DtList/ModalCreateDt.jsx'
import ModalCreateDto from '@/pages/MainPage/panes/DtoList/ModalCreateDto.jsx'
import ModalCreateDv from '@/pages/MainPage/panes/DvList/ModalCreateDv.jsx'
import ModalCreateFc from '@/pages/MainPage/panes/FcList/ModalCreateFc.jsx'
import ModalCreateLs from '@/pages/MainPage/panes/LsList/ModalCreateLs.jsx'
import ModalCreateLsi from '@/pages/MainPage/panes/LsiList/ModalCreateLsi.jsx'
import ModalCreateMid from '@/pages/MainPage/panes/ArchMiddlewareList/ModalCreateMid.jsx'
import ModalCreateOo from '@/pages/MainPage/panes/OoList/ModalCreateOo.jsx'
import ModalCreatePhys from '@/pages/MainPage/panes/DepPsList/ModalCreatePhys.jsx'
import ModalCreateSf from '@/pages/MainPage/panes/SfList/ModalCreateSf.jsx'
import ModalCreateSs from '@/pages/MainPage/panes/SsList/ModalCreateSs.jsx'
import ModalCreateSvr from '@/pages/MainPage/panes/SvrList/ModalCreateSvr.jsx'
import ModalCreateUc from '@/pages/MainPage/panes/UcList/ModalCreateUc.jsx'
import ModalCreateUi from '@/pages/MainPage/panes/UiList/ModalCreateUi.jsx'
import ModalCreateVr from '@/pages/MainPage/panes/DepVsList/ModalCreateVr.jsx'
import metaBizNode from '@/components/SoftwareMap/metaBizNode.mjs'
import useTypes from './useTypes.mjs'

const type2modal = {
    OO: ModalCreateOo,
    BF: ModalCreateBf,
    BD: ModalCreateBd,
    SVR: ModalCreateSvr,
    AO: ModalCreateAo,
    DO: ModalCreateDo,
    AMAR: ModalCreateAmAr,
    AMAI: ModalCreateAmAi,
    SF: ModalCreateSf,
    UI: ModalCreateUi,
    UC: ModalCreateUc,
    LS: ModalCreateLs,
    LSI: ModalCreateLsi,
    DB: ModalCreateDb,
    DT: ModalCreateDt,
    DV: ModalCreateDv,
    CDT: ModalCreateCdt,
    CDG: ModalCreateCdg,
    SS: ModalCreateSs,
    FC: ModalCreateFc,
    DTO: ModalCreateDto,
    MID: ModalCreateMid,
    PHYS: ModalCreatePhys,
    VR: ModalCreateVr,
}

export default function ButtonCreateProduct({
    children,
    icon,
    prjId,
    sVer,
    type,
    ...props
}) {
    const openModal = useOpenModal()
    const types = useTypes(prjId)

    const items = Object.entries(type2modal)
        .filter(([type]) => types.has(type))
        .map(
            ([type, ModalCreate]) => {
                const label = 'SVR' === type ?
                    '业务服务' : metaBizNode[type].name

                const onClick = () => openModal(
                    <ModalCreate
                        prjId={prjId}
                        sVer={sVer}
                    />
                )

                return {label, onClick}
            }
        )

    if (0 === items.length) {
        return null
    }

    return (
        <PopoverTrigger
            align="left"
            content={<ContextMenu items={items} />}
            position="bottom"
            {...props}
        >
            <Button
                icon={icon}
                type={type}
            >
                {children}
            </Button>
        </PopoverTrigger>
    )
}
