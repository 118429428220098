import { useState, useEffect } from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import { Select } from 'antd';
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import {useMapContext} from '@/components/MindMap/index.mjs'


export default function PropertiesControllerTm({
    className,
    style,
    prop = 'bm',
    disabled,
    hidden,
    label,
    labelSuffix,
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()
    const map = useMapContext()
    const http = useHTTP();
        // 数据模型
        const [options, setOptions] = useState([]);
        useEffect(() => {
            if(!disabled) {
                doSearch();
            }
        }, []);
    
        const doSearch = async () => {
            try {    
                const data = await runAsync(
                    async () => {
                        return await http.get(`/listPrjMbrBySpecialRole`, {prjId: map.data.prjId, prjGrpTypeCode: (prop == 'mtm' || prop == 'mbm' || prop == 'mrm') ? prop.substring(1).toUpperCase() : prop.toUpperCase()});
                    }, {
                    error: { content: (err) => `查询项目失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });
                const newData = data.map(item => ({
                    label: item.name,
                    value: item.userId
                }))
    
                setOptions(newData);
            } catch (err) {
                console.error(err);
            } finally {
            }
        }

    const {
        [`${prop}Name`]: userName,
        [`${prop}Id`]: userId
    } = values

    const handleChange = (am, options) => {
        update({
            [`${prop}Name`]: options.label,
            [`${prop}Id`]: options.value
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <Select disabled={p.disabled} value={disabled ? userName : userId} options={options} placeholder="请选择" onChange={handleChange} />
        </PropertiesController>
    )
}
