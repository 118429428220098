import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from './TableChooseDesign.jsx';
import dict from '@/script/dict.mjs';
export default function TableChooseDe({sVerMap, ...props}) {
    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            component: <Table.ViewText />,
        },
 
        {
            title: '分支号',
            dataIndex: 'sVer',
            width: 100,
            filter: <Table.FilterText/>,
        },
        {
            title: '分支名称',
            dataIndex: 'pblName',
            width: 182,
            filter: <Table.FilterText/>,
        },
        {
            title: '分支类型',
            width: 120,
            dataIndex:'pblType',
            ellipsis: true,
            filter: <Table.FilterText getText={text => {
                return dict.getDictDtl('PRJ_BASELINE', text)?.nameCn ?? text;
            }} />,
            render: (text, record) => {
                const pblType = record.pblType;
                record.pblTypeName = dict.getDictDtl('PRJ_BASELINE', pblType)?.nameCn ?? pblType;
                return record.pblTypeName;
            }
        },
        {
            title: '创建人',
            dataIndex: 'crtName',
            width: 180,
            ellipsis: true,
            filter: <Table.FilterText/>,
            component: <Table.ViewText />
        },
        {
            title: '创建时间',
            dataIndex: 'crtTime',
            width: 160,
            component: <Table.ViewText />
        },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
            multiple={false}
        />
    )
}
