import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectDmDialog from '@/pages/MainPage/dialogs/SelectDmDialogNew';
import SelectDbDialog from '@/pages/MainPage/dialogs/SelectDbDialogNew';
import Permission from '@/components/Permission.jsx';
import { useUser } from '@/biz/user.mjs';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import openWindow from '@/script/openWindow.mjs';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateDv({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const user = useUser();
    const openModal = useOpenModal();
    const [form] = Form.useForm();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 初始化
    useEffect(() => {
        (async () => {
            await init();
            await initDb();
        })();
    }, []);

    const init = async () => {
        ref.current = {};
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject();

        ref.current.prjId = prjId;
        ref.current.sVer = sVer;
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    const initDb = async () => {
        try {
            const param = {
                prjId: ref.current.prjId,
                sVer: ref.current.sVer,
                dbStsCodes: ['RLS', 'REVISE']
            }

            const data = await runAsync(
                async () => {
                    return await http.get(`/archdbs`, param);
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            if (data && data.length == 1) {
                ref.current.dbCode = data[0].dbCode;
                form.setFieldsValue({ dbName: data[0].dbName });
            }
        } catch (err) {
            console.log(err);
        }
    }

    const doAdd = async () => {
        try {
            setLoading(true);

            // 表单校验
            await form.validateFields().then(async values => {
                const param = {
                    ...values,
                    prjId: prj.prjId,
                    sVer: prj.sVer,
                    dbCode: ref.current.dbCode,
                    dmCode: ref.current.dmCode,
                }

                const data = await runAsync(
                    async () => {
                        return await http.post(`/dmdvs`, param);
                    }, {
                    error: { content: (err) => `新建数据视图失败: ${err.message}` },
                    loading: { show: true },
                    success: { show: true },
                });

                onOk(data)
                openWindow('/DvDetail', { dvId: data.pkid });
            });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.dbCode = null;
        ref.current.dmCode = null;
        form.setFieldsValue({ dbName: '', dmName: '' });
    }

    // 回调：数据库
    const callDb = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectDbDialog params={{ prjId, sVer }} />);

        if (result) {
            if (result.dbCode != ref.current.dbCode) {
                ref.current.dmCode = null;
                form.setFieldsValue({ dmName: '' });
            }

            ref.current.dbCode = result.dbCode;
            form.setFieldsValue({ dbName: result.dbName });
        }
    }

    // 回调：数据域
    const callDm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const dbCode = ref.current.dbCode;
        const result = await openModal(<SelectDmDialog params={{ prjId, sVer, dbCode }} />);

        if (result) {
            ref.current.dbCode = result.dbCode;
            ref.current.dmCode = result.dmCode;
            form.setFieldsValue({ dbName: result.dbName, dmName: result.dmName });
        }
    }

    return (
        <>
            <Modal
                title="新建数据视图"
                okText="保存"
                cancelText="取消"
                bodyStyle={{ padding: '12px 24px 2px' }}
                footer={[
                    <Space>
                        <Permission permission="DT_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} onFinish={doAdd} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" readOnly addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} />
                    </Form.Item>

                    <Form.Item name="dbName" label="数据库" >
                        <Input size="middle" placeholder="请选择数据库" readOnly addonAfter={<SearchOutlined onClick={callDb} />} />
                    </Form.Item>

                    <Form.Item name="dmName" label="库表域" >
                        <Input size="middle" placeholder="请选择库表域" readOnly addonAfter={<SearchOutlined onClick={callDm} />} />
                    </Form.Item>

                    <Form.Item name="dvUserCode" label="数据视图代码" rules={[{ required: true, message: '请输入数据视图代码' }]}>
                        <Input placeholder="请输入数据视图代码" />
                    </Form.Item>

                    <Form.Item name="dvName" label="数据视图名称" rules={[{ required: true, message: '请输入数据视图名称' }]}>
                        <Input placeholder="请输入数据视图名称" />
                    </Form.Item>
                </Form>

                <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} selectItems={prj} />
            </Modal>
        </>
    );
}
