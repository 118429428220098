import {Select, Tag} from "antd";
import React from "react";

const vsStatuss = [
    {
        label: '正常', value: 'NORMAL', color: 'green',
    },
    {
        label: '损坏', value: 'DAMAGED', color: 'error',
    },
    {
        label: '修理', value: 'REPAIR', color: 'blue',
    },
    {
        label: '保养', value: 'MAINTAIN', color: 'warning',
    },
    {
        label: '未知', value: 'UNKNOWN', color: 'default',
    },
]

const vsStatusMap = new Map(vsStatuss.map(item => ([item.value, item])))

export default function ({...props}) {
    return (
        <Select allowClear optionLabelProp="label" {...props}>
            {
                vsStatuss.map(({label, value, color}) => (<Option value={value} label={label}><Tag color={color}>{label}</Tag></Option>))
            }
        </Select>
    )
}

export {vsStatuss, vsStatusMap}
