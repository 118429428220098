import Table from '@/components/Table/Table.jsx'
import TableChooseProduct from '../_PRODUCT/TableChooseProduct.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const TableChooseBd = (props) => {
    const map = useMapContext()
    const {prjTypeCode} = map.data

    const columns = [
        {
            title: '业务域',
            dataIndex: 'bmNamePath',
            component: <Table.ViewText />,
        },

        {
            title: '子系统',
            dataIndex: 'ssName',
            component: <Table.ViewText />,
        },

        'BCT' === prjTypeCode && {
            title: '界面方案',
            dataIndex: 'uiCase',
            component: <Table.ViewText />,
        },

        {
            title: '业务能力模块编号',
            dataIndex: 'bdNo',
            component: <Table.ViewText />,
        },

        {
            title: '业务能力模块名称',
            dataIndex: 'bdName',
            component: <Table.ViewText />,
        },

        {
            title: '业务能力模块类型',
            dataIndex: 'bdTypeName',
            component: <Table.ViewText />,
        },
    ].filter(a => a)

    return (
        <TableChooseProduct
            columns={columns}
            {...props}
        />
    )
}

export default TableChooseBd
