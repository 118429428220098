import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    cmUserCode: {
        name: () => '用例域代码',
    },

    cmName: {
        name: () => '用例域名称',
        required: () => true,
    },
})
