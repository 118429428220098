import {css} from '@emotion/react'
import {Modal} from 'antd'
import {Alert, useOpenModal} from '@/components/Modal/Modal.jsx'
import Tree from '@/script/Tree.mjs'
import {canPreviewUv, previewUv} from '../UV/previewUv.mjs'

const cssUv = css({
    padding: 12,
    border: '1px solid transparent',
    cursor: 'pointer',

    '&:hover': {
        backgroundColor: 'rgba(45, 189, 255, .2)',
        border: '1px solid rgba(45, 189, 255, .5)',
        borderRadius: 4,
    },
})

const ModalPreviewUv = ({map, uvs, target, onOk, ...props}) => {
    const {UV} = map.BizNode

    const children = uvs.map(uv => {
        const {pkid} = uv.data
        const text = UV.getText(map, uv)

        const onClick = () => {
            previewUv(map, uv, target)
            onOk()
        }

        return (
            <div
                key={pkid}
                css={cssUv}
                onClick={onClick}
            >
                {text}
            </div>
        )
    })

    return (
        <Alert
            title="预览界面视图"
            width={400}
            {...props}
        >
            {children}
        </Alert>
    )
}

export default () => {
    const openModal = useOpenModal()

    return async (map, ui, target) => {
        const {UI} = map.BizNode
        const rev = UI.getRev(map, ui)
        const {pkid} = ui.data
        const treeData = await UI.readTree({pkid, rev})
        const tree = new Tree(treeData)

        for (const n of tree.children(tree.root)) {
            if ('UI_DEMO' !== n.data.bizNodeType) {
                continue
            }

            const next = chain => {
                const {bizNodeType} = chain[0].data
                const yieldChildren = 'CAT' === bizNodeType
                const yieldNode = 'UV' === bizNodeType
                return {yieldChildren, yieldNode}
            }

            const uvs = [...map.bfs(tree.children(n), next)]
                .filter(uv => canPreviewUv(tree, uv))

            if (1 < uvs.length) {
                openModal(
                    <ModalPreviewUv
                        map={map}
                        target={target}
                        uvs={uvs}
                    />
                )
            }
            else if (0 < uvs.length) {
                previewUv(tree, uvs[0], target)
            }
            else {
                Modal.error('未找到可预览的界面视图')
            }
        }
    }
}
