import {css} from '@emotion/react'
import {useProperties} from '../../SidePanel/RightPanel/Properties.mjs'

import {
    PropertiesController,
    usePropertiesController,
} from '../../SidePanel/RightPanel/PropertiesController.jsx'

import SelectRm from './SelectRm.jsx'

const cssPath = css({
    fontSize: 13,
    color: '#666',
    marginTop: 4,
})

export default function PropertiesControllerRm({
    className,
    style,
    codeProp = 'rmCode',
    disabled,
    hidden,
    idProp = 'rmId',
    label,
    labelSuffix,
    nameProp = 'rmName',
    pathProp = 'rmNamePath',
    readOnly,
    required,
    ...props
}) {
    const [values, update] = useProperties()

    const {
        [codeProp]: rmCode,
        [idProp]: rmId,
        [nameProp]: rmName,
        [pathProp]: rmNamePath,
    } = values

    const handleChange = rm => {
        const {
            rmCode = '',
            pkid: rmId = '',
            rmName = '',
            rmNamePath = '',
        } = rm?.node ?? {}

        update({
            [codeProp]: rmCode,
            [idProp]: rmId,
            [nameProp]: rmName,
            [pathProp]: rmNamePath,
        })
    }

    const p = usePropertiesController({
        disabled,
        hidden,
        label,
        prop: codeProp,
        readOnly,
        required,
    })

    return (
        <PropertiesController
            className={className}
            style={style}
            hidden={p.hidden}
            label={p.label}
            labelSuffix={labelSuffix}
            required={p.required}
        >
            <SelectRm
                disabled={p.disabled}
                isLeaf={false}
                readOnly={p.readOnly}
                value={{rmCode, rmId, rmName, rmNamePath}}
                onChange={handleChange}
                {...props}
            />

            <div css={cssPath}>{rmNamePath}</div>
        </PropertiesController>
    )
}
