import {Button} from 'antd'
import http from '@/biz/http.mjs'
import useChoosePromptTemplate from '@/biz/useChoosePromptTemplate.jsx'
import openWindow from '@/script/openWindow.mjs'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonGenCode({dps, ...props}) {
    const chooseTemplate = useChoosePromptTemplate()
    const map = useMapContext()
    const {prjPatternId} = map.data

    if (! prjPatternId) {
        return null
    }

    const handleClick = async () => {
        const scene = 'CODE_GENERATION'
        const tmpl = await chooseTemplate(scene)

        if (! tmpl) {
            return
        }

        const {
            prompts,

            template: {
                pkid: promptTemplateId,
                promptTemplateCode,
                promptTemplateResponseMode,
                usageScenarioCode,
            },
        } = tmpl

        const bizMap = (() => {
            const ids = []
            const dpNames = []
            let type = ''

            for (const dp of dps) {
                const {bizNodeType, pkid} = dp

                if (type) {
                    if (type !== bizNodeType) {
                        throw new Error('制品类型不统一')
                    }
                }
                else {
                    type = bizNodeType
                }

                ids.push(pkid)
                const {textProp} = map.BizNode[type]
                dpNames.push(dp[textProp])
            }

            return ({
                BD: () => ({
                    patternId: prjPatternId,
                    bdIds: ids,
                    dpNames,
                }),

                SS: () => ({
                    patternId: prjPatternId,
                    ssId: ids[0],
                    dpNames,
                }),
            }[type])()
        })()

        const {workflowInstanceId} = await runAsync(
            () => http.post(
                '/ai/execute',

                {
                    bizMap,
                    chatHistorys: [],
                    steps: prompts,
                    usageScenarioCode,
                    workflowCode: promptTemplateCode,
                    workflowId: promptTemplateId,
                    workflowResponseMode: promptTemplateResponseMode,
                },
            ),

            {action: '执行工作流'}
        )

        openWindow(
            '/AiCodeHelper',

            {
                prjPatternId,
                scene: 'CODE_GENERATION',
                workflowInstanceId,
            }
        )
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        />
    )
}
