import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'

const FormItemBrfTitle = (props) => {
    return (
        <Form.Item
            label="需求单名称"
            name="arName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBrfsysName = (props) => {
    return (
        <Form.Item
            label="系统名称"
            name="riName"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBrfsysNo = (props) => {
    return (
        <Form.Item
            label="系统代码"
            name="riUserCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

export default function FormChooseDe(props) {
    const brief = (
        <Row gutter={12}>
            <Col span={6}>
                <FormItemBrfTitle />
            </Col>

            <Col span={6}>
                <FormItemBrfsysName />
            </Col>

            <Col span={6}>
                <FormItemBrfsysNo />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}
