import http from '@/biz/http.mjs'
import runAsync from '@/script/runAsync.mjs'
import Tree from '@/script/Tree.mjs'
import parseMap from '../../scripts/parseMap.mjs'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'

const getSubTreeModifiedCompareIds = (modifiedIds, treeData) => {
    const ids = new Set()
    const tree = new Tree(treeData)

    const next = (chain) => {
        const {mapCompUuid} = chain[0].data

        if (! mapCompUuid) {
            return {yieldChildren: false}
        }

        if (modifiedIds.has(mapCompUuid)) {
            for (const ancestor of chain.slice(1)) {
                const {mapCompUuid} = ancestor.data
                ids.add(mapCompUuid)
            }
        }
    }

    [...tree.dfs(tree.root, next)]
    return ids
}

export default ({
    basDpId,
    basDppId,
    basMapVerId,
    mergeId,
    tarDpId,
    tarDppId,
    tarMapVerId,
    type,
}) => {
    const {data} = useSwrNoCache(
        () => runAsync(
            async () => {
                const {basMap, tarMap, ...data} = await http.get(
                    '/mapcompare/map',

                    {
                        basDpId,
                        basDppId,
                        basMapVerId,
                        mergeId,
                        tarDpId,
                        tarDppId,
                        tarMapVerId,
                        type,
                    }
                )

                const {
                    basMap: _basMap,
                    changeList,
                    tarMap: _tarMap,
                } = await http.post(
                    '/mapcompare/common/map',

                    {
                        basDpId,
                        basDppId,
                        basMap,
                        basMapVerId,
                        mergeId,
                        tarDpId,
                        tarDppId,
                        tarMap,
                        tarMapVerId,
                        type,
                    }
                )

                return {
                    ...data,
                    basMap: _basMap,
                    changeList,
                    tarMap: _tarMap,
                }
            },

            {action: '对比地图'}
        ),

        {
            dependencies: [
                basDpId,
                basDppId,
                basMapVerId,
                mergeId,
                tarDpId,
                tarDppId,
                tarMapVerId,
                type,
            ]
        }
    )

    if (data) {
        const {
            basMap,
            basMemo,
            basRevInfo,
            basRlsInfo,
            changeList,
            tarMap,
            tarMemo,
            tarRevInfo,
            tarRlsInfo,
            title,
        } = data

        const differences = changeList.map(
            ({changeType, leftMapCompUuid, rightMapCompUuid}) => [
                changeType, leftMapCompUuid, rightMapCompUuid
            ]
        )

        const left = (() => {
            const map = parseMap(basMap)
            const _compareIdToDiffType = new Map()

            for (const [type, compareId] of differences) {
                if (compareId) {
                    _compareIdToDiffType.set(compareId, type)
                }
            }

            const _subTreeModifiedCompareIds = getSubTreeModifiedCompareIds(
                new Set(_compareIdToDiffType.keys()),
                map.root
            )

            Object.assign(map.data, {
                memo: basMemo,
                rev: basRevInfo,
                rls: basRlsInfo,
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            })

            return map
        })()

        const right = (() => {
            const map = parseMap(tarMap)
            const _compareIdToDiffType = new Map()

            for (const [type, , compareId] of differences) {
                if (compareId) {
                    _compareIdToDiffType.set(compareId, type)
                }
            }

            const _subTreeModifiedCompareIds = getSubTreeModifiedCompareIds(
                new Set(_compareIdToDiffType.keys()),
                map.root
            )

            Object.assign(map.data, {
                memo: tarMemo,
                rev: tarRevInfo,
                rls: tarRlsInfo,
                _compareIdToDiffType,
                _subTreeModifiedCompareIds,
            })

            return map
        })()

        return {
            differences,
            left,
            right,
            title,
        }
    }
    else {
        return null
    }
}
