import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Form, Input, Select, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfAccessMerge({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [brfSyss, setBrfSyss] = useState([]);

    const doQuery = async () => {
        const brfSyss = await runAsync(
            async () => {
                return await http.get(`/brf/listPendingAccessMergeBrfSys`, {brfId: record.pkid});
            }, {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });
        if (brfSyss) {
            setBrfSyss(brfSyss.map(({pkid, sysPrjNo, sysPrjName}) => ({label: `[${sysPrjNo}]${sysPrjName}`, value: pkid})))
        }
    }

    const handleOk = async () => {
        form.validateFields().then(async values =>{
            try {
                setLoading(true)
                await runAsync(
                    async () => {
                        return await http.post(`/brf/accessMerge`, {...values, brfId: record.pkid}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                    }, {
                        error: {content: (err) => `完成准入合并失败: ${err.message}`},
                        loading: {show: true},
                        success: {show: true},
                    });
                onOk(true)
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await Promise.all([doQuery(),])
            } finally {
                setLoading(false)
            }
        })()
    }, []);

    return (
        <Modal
            title="准入合并基线"
            width="600px"
            {...props}
            onOk={handleOk}
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    {/*<Form.Item name='pkid' hidden><Input/></Form.Item>*/}
                    <Form.Item
                        label='系统'
                        name='sysId'
                        rules={[
                            {
                                required: true,
                                message: '请选择完成并入的系统',
                            },
                        ]}
                    >
                        <Select
                            allowClear
                            options={brfSyss}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
