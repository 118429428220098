import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    pblName: {
        name: () => '分支名称',
        required: () => true,
        readOnly: () => true,
    },
    pkid: {
        name: () => '分支ID',
        required: () => true,
        readOnly: () => true,
    },
    sVer: {
        name: () => '分支号',
        required: () => true,
        readOnly: () => true,
    },
})
