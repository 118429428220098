import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";

/**
 * 界面方案
 * 
 * @param {*} param0 
 * @returns 
 */
const Pane = ({ prjId, sVer, prjTypeCode }) => {
    const http = useHTTP();
    const [uiCases, setUiCases] = useState([]);

    useEffect(() => {
        if (prjId) {
            doCase();
        }
    }, [prjId]);

    // 查询：界面方案
    const doCase = async () => {
        try {
            let _prjTypeCode = prjTypeCode;

            if (!_prjTypeCode) {
                const data = await runAsync(
                    async () => {
                        return await http.get(`/prjs/${prjId}`);
                    }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

                _prjTypeCode = data.prjTypeCode;
            }

            if ('BCT' == _prjTypeCode) {
                const data = await runAsync(
                    async () => {
                        return await http.get(`/prjparams`, { prjId, sVer, paramType: 'UICASE' });
                    }, {
                    error: { content: (err) => `查询失败: ${err.message}` },
                    loading: { show: false },
                    success: { show: false },
                });

                setUiCases(data);
            } else {
                setUiCases([]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    return (
        uiCases.length > 0 ?
            <Form.Item name="uiCase" label="界面方案">
                <Select>
                    {uiCases.map(v => <Option value={v.pkid}>{v.paramName}</Option>)}
                </Select>
            </Form.Item>
            :
            <div />
    );
}

export default Pane;