import SelectRmDialog from '@/pages/MainPage/dialogs/SelectRmDialog'
import InputModalSelect from '../components/InputModalSelect.jsx'

export default function SelectRm({value, ...props}) {
    const getText = multiple => multiple ?
        (value ?? []).map(({rmName}) => rmName).join(',') :
        (value ?? {}).rmName

    return (
        <InputModalSelect
            getText={getText}
            Modal={SelectRmDialog}
            paramsProp="queryParams"
            {...props}
        />
    )
}
