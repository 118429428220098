import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, Button, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectOmDialog from '@/pages/MainPage/dialogs/SelectOmDialog';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import Permission from '@/components/Permission.jsx';
import openWindow from '@/script/openWindow.mjs';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateOo({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const [form] = Form.useForm();
    const openModal = useOpenModal();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 权限码
    const CRT = 'OO_CRT';

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        ref.current = {};
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    // 新建：业务流程
    const doAdd = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();
            formField.prjId = prj.prjId;
            formField.sVer = prj.sVer;
            formField.omCode = ref.current.omCode;

            const data = await runAsync(
                async () => {
                    return await http.post(`/omoo/addOmOo`, formField);
                }, {
                error: { content: (err) => `新建失败: ${err.message}` },
                loading: { show: false },
                success: { show: true },
            });

            onOk(data);
            openWindow('/OoDetail', { ooId: data.pkid });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调：设置项目
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer, prjTypeCode: value.prjTypeCode });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.bmCode = null;
        form.setFieldsValue({ bmName: '' });
    }

    // 回调：设置组织域
    const callOm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectOmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.omCode = result.omCode;
            form.setFieldsValue({ omName: result.omName });
        }
    }

    return (
        <>
            <Modal
                title={'新建组织对象'}
                okText="保存"
                cancelText="取消"
                bodyStyle={{ padding: '12px 24px 2px' }}
                footer={[
                    <Space>
                        <Permission permission={CRT} projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                        <Input placeholder="请选择项目" addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} readOnly />
                    </Form.Item>

                    <Form.Item name="omName" label="组织域">
                        <Input placeholder="请选择组织域" readOnly addonAfter={<SearchOutlined onClick={callOm} />} />
                    </Form.Item>

                    <Form.Item name="ooNo" label="组织对象代码" rules={[{ required: true, message: '请输入组织对象代码' }]}>
                        <Input placeholder="请输入组织分析代码" />
                    </Form.Item>

                    <Form.Item name="ooName" label="组织对象名称" rules={[{ required: true, message: '请输入组织对象名称' }]}>
                        <Input placeholder="请输入组织对象名称" />
                    </Form.Item>
                </Form>
            </Modal>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
