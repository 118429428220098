import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Alert, Button, Card, Col, Form, Input, Row, Select, Space, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfPreApproval({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [tms, setTms] = useState([]);
    const [bms, setBms] = useState([]);

    const doQuery = async () => {
        const brfSyss = await runAsync(
            async () => {
                return await http.get(`/brf/listBrfSys`, {brfId: record.pkid});
            }, {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });
        if (brfSyss) {
            const data = {
                ...record,
                brfSyss,
            }
            setData(data)
            form.setFieldsValue(data)
        }
    }

    const doQueryUsers = async () => {
        const data = await runAsync(
            async () => {
                return await http.get(`/listPrjMbrBySpecialRole`, {prjId: record.prjId});
            }, {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });

        const tms = []
        const bms = []
        if (data) {
            data.forEach(({userId, name, prjGrpTypeCode}) => {
                if (prjGrpTypeCode === 'TM') {
                    tms.push({
                        label: name,
                        value: userId
                    })
                } else if (prjGrpTypeCode === 'BM') {
                    bms.push({
                        label: name,
                        value: userId
                    })
                }
            })
        }
        setTms(tms)
        setBms(bms)
    }

    const doApproval = async (actionResult, values) => {
        try {
            setLoading(true)
            await runAsync(
                async () => {
                    return await http.post(`/brf/preApproval`, {...values, actionResult}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                }, {
                    error: {content: (err) => `预审失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                });
            onOk(true)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    const handleApprovalClick = async (actionResult) => {
        if (actionResult === 'yes') {
            form.validateFields().then(async values => {
                doApproval(actionResult, values)
            })
        } else {
            const values = form.getFieldsValue()
            doApproval(actionResult, values)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                await Promise.all([doQuery(), doQueryUsers()])
            } finally {
                setLoading(false)
            }
        })()
    }, []);

    return (
        <Modal
            title="预审"
            width="600px"
            {...props}
            bodyStyle={{padding: '12px 12px 0 12px'}}
            footer={
                <Space>
                    <Button type='primary' ghost size='small' onClick={() => handleApprovalClick('yes')}>通过</Button>
                    <Button type='ghost' size='small' danger onClick={() => handleApprovalClick('no')}>驳回</Button>
                    <Button type='ghost' size='small' onClick={() => onOk(false)}>取消</Button>
                </Space>
            }
        >
            <Spin spinning={loading}>
                {/*{*/}
                {/*    (!data.brfSyss || data.brfSyss.length === 0) && (*/}
                {/*        <Alert message="本需求单还未设置系统以及相关需求增量基线" type="warning" showIcon closable style={{marginBottom: 12}}/>*/}
                {/*    )*/}
                {/*}*/}
                <Form form={form} layout="vertical">
                    <Form.Item name='pkid' hidden><Input/></Form.Item>
                    <Form.List name="brfSyss">
                        {
                            (fields) => {
                                console.log(fields)
                                return fields.map(({key, name, ...restField}) => (
                                    <Space
                                        direction="vertical"
                                        style={{
                                            display: 'flex',
                                            marginBottom: 12,
                                        }}
                                    >
                                        <Card
                                            size="small"
                                            title={
                                                data.brfSyss[key] && (
                                                    <>
                                                        <Text type='danger'>* </Text>
                                                        <span>系统：[{data.brfSyss[key].sysPrjNo}]{data.brfSyss[key].sysPrjName}</span>
                                                    </>
                                                )
                                            }
                                            key={key}
                                            bodyStyle={{padding: 8}}
                                        >
                                            <Form.Item name={[name, 'pkid']} hidden><Input/></Form.Item>
                                            <Row gutter={12} style={{marginTop: 8}}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        label="业务经理"
                                                        name={[name, 'bmId']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请分配业务经理',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder='请分配业务经理'
                                                            allowClear
                                                            options={bms}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        label="技术经理"
                                                        name={[name, 'tmId']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请分配技术经理',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            placeholder='请分配技术经理'
                                                            allowClear
                                                            options={tms}
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Space>
                                ))
                            }
                        }
                    </Form.List>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
