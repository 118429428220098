import {useMapContext} from '@/components/MindMap/index.mjs'
import ButtonGenCode from '../_DESIGN/PropertiesPaneButtonGenCode.jsx'

export default function PropertiesPaneButtonGenCode(props) {
    const map = useMapContext()
    const nodes = map.useSelectedNodes()

    if (1 !== nodes.size) {
        return null
    }

    const [node] = nodes
    const dps = [node.data]

    return (
        <ButtonGenCode
            dps={dps}
            {...props}
        >
            生成代码
        </ButtonGenCode>
    )
}
