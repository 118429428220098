import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useComponentNode from '../_COMPONENT/useComponentNode.jsx'
import OprType from './OprType.jsx'
import Model from './ModelOpr.mjs'
import PropertiesPane from './PropertiesPaneOpr.jsx'

const OprTypes = new Map(
    OprType.flat().map(([type, name, icon]) => [type, {icon, name, type}])
)

export default () => {
    const ComponentNode = useComponentNode()

    return extendNode(ComponentNode, {
        Model,
        PropertiesPane,

        getDesc(map, node) {
            const {oprType} = node.data

            if (! oprType || 'GENERAL' === oprType) {
                return this.name
            }

            const {name = ''} = OprTypes.get(oprType) ?? {}
            return name
        },

        getIcons(map, node) {
            const type2icon = new Map(
                OprType.flat().map(([type, , icon]) => [type, icon])
            )

            const icon = type2icon.get(node.data.oprType)

            if (icon) {
                return [icon]
            }
            else {
                return []
            }
        },

        getRev(map, node) {
            return null
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {borderColor: Color.GOLD}),
                shape: 'HorizontalHexagon',
            }
        },

        async _atPull(map, node) {
            await ComponentNode._atPull.call(this, map, node)
            node.isFolded = false
        },

        _pushDataSlots: {
            child: [],
        },

        _getDefaultDataFields(map, node) {
            return {
                ...ComponentNode._getDefaultDataFields.call(this, map, node),
                oprType: 'GENERAL'
            }
        },
    })
}
