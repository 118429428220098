import {useState} from 'react'
import Modal from '@/components/Modal/Modal.jsx'
import {useMapContext} from '@/components/MindMap/index.mjs'

const bodyStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}

export default function ModalChooseDesign({
    fetch,
    getQuery = a => a,
    multiple = true,
    FormChoose,
    TableChoose,
    onOk,
    ...props
}) {

    const [query, setQuery] = useState(
        () => ({
            prjStsCodes: 'RLS',
            prjTarget: 'baseline',
            ...getQuery({})
        })
    )

    const [selectedItems, setSelectedItems] = useState([])

    const handleChangeQuery = values => setQuery({
        prjStsCodes: 'RLS',
        ...getQuery(values)
    })

    const handleSelect = item => onOk([item])
    const handleOk = () => onOk(selectedItems)

    const handleChangeSver = (data) => {
        if (!selectedItems || selectedItems.length < 1) return;
        const newData = selectedItems.map(item => 
            item.pkid == data.pkid ? { ...item, newSVer: data.sVer } : item
        );
        setSelectedItems(newData)
    }

    return (
        <Modal
            bodyStyle={bodyStyle}
            closable={false}
            maskClosable={false}
            onOk={handleOk}
            {...props}
        >
            <FormChoose
                query={query}
                onChange={handleChangeQuery}
            />

            <TableChoose
                fetch={fetch}
                multiple={multiple}
                query={query}
                selectRowOnClick
                onChange={setSelectedItems}
                handleChangeSver={handleChangeSver}
                onSelect={handleSelect}
            />
        </Modal>
    )
}
