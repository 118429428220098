import {Col, Form, Input, Row} from 'antd'
import QueryForm from '@/components/QueryForm/QueryForm.jsx'



const FormItemBrfCode = (props) => {
    return (
        <Form.Item
            label="需求单代码"
            name="brfCode"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

const FormItemBrfName = (props) => {
    return (
        <Form.Item
            label="需求单名称"
            name="brfTitle"
            {...props}
        >
            <Input allowClear />
        </Form.Item>
    )
}

// const FormItemPrjNo = (props) => {
//     return (
//         <Form.Item
//             label="制品编号"
//             name="prjNo"
//             {...props}
//         >
//             <Input allowClear />
//         </Form.Item>
//     )
// }

export default function FormChooseAmar(props) {
    const brief = (
        <Row gutter={12}>
            {/* <Col span={6}>
                <FormItemPrjNo />
            </Col> */}



            <Col span={6}>
                <FormItemBrfCode />
            </Col>

            <Col span={6}>
                <FormItemBrfName />
            </Col>
        </Row>
    )

    return (
        <QueryForm
            brief={brief}
            {...props}
        />
    )
}