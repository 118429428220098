import extendNode from '../extendNode.mjs'
import openWindow from '@/script/openWindow.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaArchNode.mjs'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,

        canChangeVersion(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return true
            }
            else {
                return ProductNode.canChangeVersion.call(this, map, node)
            }
        },

        canDeleteTree(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return false
            }
            else {
                return ProductNode.canDeleteTree.call(this, map, node)
            }
        },

        canMountType(map, node, type) {
            return this.moduleType === type
        },

        canMove(map, node) {
            return false
        },

        defaultChildType(map, node) {
            return this.moduleType
        },

        getText(map, node) {
            if ('SA_MAP' === map.data.mapTypeCode) {
                return this.alias
            }
            else {
                return ProductNode.getText.call(this, map, node)
            }
        },

        getDesc(map, node) {
            return ''
        },

        menuItemsInsertConcept(map, node) {
            return [
                [this.moduleType],
            ]
        },

        async onDoubleClick(map, node, event) {
            event.preventDefault()
            const {prjId, prjNo, rev, sVer} = node.data
            const target = event.altKey ? '_self' : '_blank'
            openWindow(this.archUrl, {prjId, prjNo, rev, sVer}, target)
        },

        //showVersion(map, node) {
            //return false
        //},

        _fixChild(map, node, child) {
            return map.BizNode[this.moduleType]._fixChild(map, node, child)
        },

        async _readGrowTree(map, node) {
            const {pkid} = node.data
            let {rev} = node.data

            if (! rev) {
                const arch = await this.read({pkid})
                rev = arch.rev
            }

            if (! rev) {
                throw new Error(`请先发布${this.name}`)
            }

            return this.readTree({pkid, rev})
        },
    })
}
