import React, { useEffect, useState, useRef } from 'react';
import { Button, Form, Input, Space, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import SelectProjectDialog, { formatName } from '@/pages/MainPage/dialogs/SelectProjectDialog';
import SelectDmDialog from '@/pages/MainPage/dialogs/SelectDmDialogNew';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import Permission from '@/components/Permission.jsx';
import { useOpenModal } from '@/components/Modal/Modal.jsx';
import openWindow from '@/script/openWindow.mjs';
import Modal from '@/components/Modal/Modal.jsx'
import useDefaultProject from '@/biz/useDefaultProject.mjs'

export default function ModalCreateCdg({ prjId, sVer, onOk, ...props }) {
    const getDefaultProject = useDefaultProject(prjId, sVer);
    const http = useHTTP();
    const openModal = useOpenModal();
    const [form] = Form.useForm();

    // 数据模型
    const ref = useRef({});
    const [prj, setPrj] = useState({});

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [visiblePrj, setVisiblePrj] = useState(false);

    // 组件初始化
    useEffect(() => {
        (async () => {
            await init();
        })();
    }, []);

    const init = async () => {
        ref.current = {};
        const {
            prjId,
            prjName,
            prjTypeCode,
            subTypeCode,
            sVer,
        } = await getDefaultProject()
        setPrj({ prjId, sVer });

        // 格式化名称
        const name = await formatName({ prjId, prjName, sVer });
        form.resetFields();
        form.setFieldsValue({ prjName: name });
    }

    const doAdd = async () => {
        try {
            setLoading(true);
            const formData = await form.validateFields();

            // 数据域
            formData.prjId = prj.prjId;
            formData.sVer = prj.sVer;
            formData.dmCode = ref.current.dmCode;

            const data = await runAsync(
                async () => {
                    return await http.post(`/dmpcdgs`, formData);
                }, {
                error: { content: (err) => `新建失败: ${err.message}` },
                loading: { show: true },
                success: { show: true },
            });

            onOk(data)
            openWindow('/DmPcdgDetail', { pcdgId: data.pkid });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    // 回调，设置项目信息
    const callPrj = async (value) => {
        if (value) {
            const name = await formatName(value);
            setPrj({ prjId: value.pkid, sVer: value.sVer });
            form.setFieldsValue({ prjName: name });
        } else {
            setPrj({});
            form.setFieldsValue({ prjName: '' });
        }

        ref.current.dmCode = null;
        form.setFieldsValue({ dmName: '' });
    }

    const callDm = async () => {
        const prjId = prj.prjId;
        const sVer = prj.sVer;
        const result = await openModal(<SelectDmDialog params={{ prjId, sVer }} />);

        if (result) {
            ref.current.dmCode = result.dmCode;
            form.setFieldsValue({ dmName: result.dmName });
        }
    }

    return (
        <>
            <Modal
                title="新建标准字段组"
                okText="保存"
                cancelText="取消"
                width={800}
                footer={[
                    <Space>
                        <Permission permission="PCDG_CRT" projects={[prj.prjId + prj.sVer]}>
                            <Button onClick={() => onOk()}>取消</Button>
                            <Button type="primary" onClick={doAdd} loading={loading}>保存</Button>
                        </Permission>
                    </Space>
                ]}
                {...props}
            >
                <Form form={form} onFinish={doAdd} layout="vertical">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="prjName" label="项目" rules={[{ required: true, message: '请选择项目' }]}>
                                <Input placeholder="请选择项目" readOnly addonAfter={<SearchOutlined onClick={() => setVisiblePrj(true)} />} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="pcdgUserCode" label="代码" rules={[{ required: true }]}>
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="pcdgName" label="名称" rules={[{ required: true }]}>
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="pcdgCat" label="类别">
                                <Input placeholder="请输入" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item name="memo" label="备注">
                                <Input placeholder="请输入" rows={3} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <SelectProjectDialog visible={visiblePrj} setVisible={setVisiblePrj} onCall={callPrj} />
        </>
    );
}
