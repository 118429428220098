import {useEffect, useRef} from 'react'
import {Col, Form, Input, Radio, Row} from 'antd'
import Select from '@/components/Form/Select.jsx'
import useSwrNoCache from '@/script/useSwrNoCache.mjs'
import http from '@/biz/http.mjs'
import Modal from '@/components/Modal/Modal.jsx'
import runAsync from '@/script/runAsync.mjs'
import SelectBd from './SelectBd.jsx'

const RowInputAlgName = ({form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)

    if (0 !== bdLogicMode) {
        return null
    }

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务能力名称"
                    name="algName"
                    rules={[{required: true}]}
                >
                    <Input />
                </Form.Item>
            </Col>
        </Row>
    )
}

const RowInputResume = ({form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)

    if (0 !== bdLogicMode) {
        return null
    }

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务能力简述"
                    name="resume"
                >
                    <Input.TextArea />
                </Form.Item>
            </Col>
        </Row>
    )
}

const RowSelectAlg = ({defaultValue, form, ...props}) => {
    const bdLogicMode = Form.useWatch('bdLogicMode', form)
    const bd = Form.useWatch('bd', form)

    const {data: bdlogics} = useSwrNoCache(
        () => {
            const {prjId, sVer, bdCode} = bd

            return runAsync(
                async () => http.get(
                    '/dmbds/rules',

                    {
                        bdCode,
                        prjId,
                        ruleType: 'BT',
                        sVer,
                    },
                ),

                {action: '读取业务事务能力'}
            )
        },

        {
            dependencies: [bd],
            start: 1 === bdLogicMode && bd,
        }
    )

    useEffect(
        () => {
            if (! bdlogics) {
                return
            }

            const ruleId = (() => {
                for (const {pkid} of bdlogics) {
                    if (pkid === defaultValue) {
                        return pkid
                    }
                }

                return void 0
            })()

            form.setFieldsValue({ruleId})
        },

        [bdlogics]
    )

    if (1 !== bdLogicMode) {
        return null
    }

    const options = bdlogics?.map(
        e => [e.pkid, e.ruleTitle]
    )

    return (
        <Row {...props}>
            <Col span={24}>
                <Form.Item
                    label="业务事务能力"
                    name="ruleId"
                    rules={[{required: true}]}
                >
                    <Select options={options} />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default function ModalMakeSf({defaultName = '', defaultRuleId, onOk, ...props}) {
    const refLastBd = useRef()
    const [form] = Form.useForm()

    const initialValues = {
        algName: defaultName,
        bdLogicMode: 0,
        sfName: defaultName,
    }

    const handleValuesChange = (changedValues, allValues) => {
        const {bd} = changedValues

        if (! bd) {
            return
        }

        const {bdName, bdNo} = bd
        const newBd = {bdName, bdNo}
        const lastBd = refLastBd.current

        if (lastBd) {
            const replace = (s, oldValue, newValue) => {
                const re = new RegExp(`${oldValue}$`)
                return s.replace(re, newValue)
            }

            const {algName, sfName, sfNo} = allValues

            form.setFieldsValue({
                algName: replace(algName, lastBd.bdName, newBd.bdName),
                sfName: replace(sfName, lastBd.bdName, newBd.bdName),
                sfNo: replace(sfNo, lastBd.bdNo, newBd.bdNo),
            })
        }
        else {
            form.setFieldsValue({
                algName: `${defaultName}${newBd.bdName}`,
                sfName: `${defaultName}${newBd.bdName}`,
                sfNo: newBd.bdNo,
            })
        }

        refLastBd.current = newBd
    }

    const handleFinish = values => {
        const {
            bdLogicMode: _1,
            bd: {pkid},
            ...data
        } = values

        if (pkid) {
            onOk({...data, bdId: pkid})
        }
        else {
            onOk()
        }
    }

    const handleOk = () => {
        form.submit()
    }

    return (
        <Modal
            title="生成交互功能"
            width={600}
            {...props}
            onOk={handleOk}
        >
            <Form
                form={form}
                initialValues={initialValues}
                layout="vertical"
                onValuesChange={handleValuesChange}
                onFinish={handleFinish}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="交互功能名称"
                            name="sfName"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="交互功能代码"
                            name="sfNo"
                            rules={[{required: true}]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label=""
                            name="bdLogicMode"
                            rules={[{required: true}]}
                        >
                            <Radio.Group>
                                <Radio value={0}>同步生成业务事务能力</Radio>
                                <Radio value={1}>实现已有业务事务能力</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="业务能力模块"
                            name="bd"
                            rules={[{required: true}]}
                        >
                            <SelectBd />
                        </Form.Item>
                    </Col>
                </Row>

                <RowInputAlgName form={form} />
                <RowInputResume form={form} />

                <RowSelectAlg
                    defaultValue={defaultRuleId}
                    form={form}
                />
            </Form>
        </Modal>
    )
}
