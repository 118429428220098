import {useMapContext} from '@/components/MindMap/index.mjs'
import ButtonGenCode from '../_DESIGN/PropertiesPaneButtonGenCode.jsx'

export default function PropertiesPaneButtonGenCode(props) {
    const map = useMapContext()
    const nodes = [...map.useSelectedNodes()]
    const dps = nodes.map(n => n.data)

    return (
        <ButtonGenCode
            dps={dps}
            {...props}
        >
            生成代码
        </ButtonGenCode>
    )
}
