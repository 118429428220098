import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    cmCode: {
        name: () => '用例域',
    },

    reqNo: {
        name: () => '需求单编号',
    },

    sfNo: {
        name: () => '集成用例代码',
        required: () => true,
    },

    sfName: {
        name: () => '集成用例名称',
        required: () => true,
    },
})
