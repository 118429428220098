import {useMapContext} from '@/components/MindMap/index.mjs'

export default () => {
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()
    const {mapTypeCode, patternRev} = map.data

    if (! (
        patternRev &&
        'SA_MAP' === mapTypeCode
    )) {
        return null
    }

    const {canAddNodeToBranch, addNodeToBranch} = map.commands
    const nodes = canAddNodeToBranch(selectedNodes)

    return {
        disabled: 0 === nodes.length,
        key: 'add-node-to-branch',
        label: '加入分支',
        onClick: () => addNodeToBranch(nodes),
    }
}
