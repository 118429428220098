import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import usePreviewUi from './usePreviewUi.jsx'

export default function PropertiesControllerPreview(props) {
    const previewUi = usePreviewUi()
    const map = useMapContext()
    const [node] = map.useSelectedNodes()

    if (! node.parent) {
        return null
    }

    const handleClick = async e => {
        const target = e.altKey ? '_self' : '_blank'
        previewUi(map, node, target)
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            预览
        </Button>
    )
}
