import {Button} from 'antd'
import {useMapContext} from '@/components/MindMap/index.mjs'
import runAsync from '@/script/runAsync.mjs'

export default function ButtonEditDesign({node, ...props}) {
    const map = useMapContext()
    const _n = map.nodeProxy(node)

    if (
        ! _n.gotoCurrent ||
        (_n.canWriteData() && ! map.data.rlsTime)
    ) {
        return null
    }

    const handleClick = e => {
        const target = e.altKey ? '_self' : '_blank'
        runAsync(() => _n.gotoCurrent(target))
    }

    return (
        <Button
            size="small"
            type="link"
            onClick={handleClick}
            {...props}
        >
            编辑
        </Button>
    )
}
