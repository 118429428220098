import ButtonExportModel from '../components/PropertiesPaneButtonExportModel.jsx'
import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'
import ButtonImportProductsFromXls from '../_DESIGN/PropertiesPaneButtonImportProductsFromXls.jsx'

export default function PropertiesPaneFa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonExportModel global type="fm" />
                    <ButtonExportModel global type="fm_excel" />

                    <ButtonImportProductsFromXls type="fm">
                        导入功能结构
                    </ButtonImportProductsFromXls>
                </>
            }

            {...props}
        />
    )
}
