import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Input, Select, Modal, Row, Col, Space, Tag } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Table from "@/biz/Table.jsx";
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';
import { getBaseline } from "@/script/SelectDb.mjs";

/**
 * SelectProjectDialog ：选择项目【单选】
 * 
 * 使用场景：
 * 1：界面管理->新增用户界面，选择项目；
 * 
 * 参数说明：
 * visible:     【必填】 当前Dialog是否展示 
 * setVisible:  【必填】 当前Dialog展示的操作方法 
 * onCall:      【必填】 回调方法 
 * isSingle:    【可选】 true(单选、默认)  false(多选)
 * prjTypeCode: 【可选】 项目类型
 * title:       【可选】 弹窗标题
 * 
 * @param {*} param
 * @returns 单选，返回对象；多选返回数组；
 **/
const SelectProjectDialog = ({ visible, setVisible, onCall, isSingle = true, prjStsCodes, prjTypeCode, title = "选择项目", ...props }) => {
    const http = useHTTP();
    // const { selectItems } = props; // 【可选】 被选择的条目，参数类型可为对象或数组
    // const { visibleHome } = props;

    // 表单
    const ref = useRef({ sVer: 1 });
    const [form] = Form.useForm();

    // 数据模型
    const [projects, setProjects] = useState([]);

    // 加载显示
    const [loading, setLoading] = useState(false);
    const [expand, setExpand] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); // 选中的对象集合

    // 组件初始化
    useEffect(() => {
        if (visible) {
            ref.current.sVer = 1; // 默认：分支号为1
            setProjects([]);
            setSelectedRows([]); // 处理被选中的项目，当主Dialog关闭，需要清空选择的数据
            doSearch();
        }
    }, [visible]);

    const doSearch = async () => {
        try {
            setLoading(true);
            const formField = await form.validateFields();

            if (prjStsCodes) {
                formField.prjStsCodes = prjStsCodes;
            } else {
                formField.prjStsCode = 'RLS';
            }

            if (prjTypeCode) {
                formField.prjTypeCode = prjTypeCode;
            }

            // 查询：项目基线
            formField.prjTarget = 'baseline';

            const data = await runAsync(
                async () => {
                    return await http.get(`/prjs`, formField);
                }, {
                error: { content: (err) => `查询项目失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            setProjects(data);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    const doReset = async () => {
        doSearch();
        setSelectedRows([]);
        form.resetFields();
    }

    // 确认回调
    const doConfirm = async () => {
        if (isSingle) {
            if (selectedRows.length == 0) {
                onCall(null);
            } else {
                const data = selectedRows[0];
                const prj = projects.filter(v => data.pkid == v.pkid)[0];
                data.sVer = prj.selectVer ?? 1;
                data.pblName = prj.pblName ?? '主分支';
                onCall(data);
            }
        } else {
            onCall(selectedRows);
        }

        doClose();
    }

    // 关闭Dialog
    const doClose = () => {
        form.resetFields();
        setVisible(false);
        setSelectedRows([]);
    }

    const rowSelection = {
        type: isSingle ? 'radio' : 'checkbox',
        selectedRowKeys: selectedRows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    }

    const columns = [
        {
            title: '项目编号',
            dataIndex: 'prjNo',
            filter: <Table.FilterText />,
        },
        {
            title: '项目名称',
            dataIndex: 'prjName',
            filter: <Table.FilterText />,
        },
        {
            title: '分支号',
            dataIndex: 'sVer',
            render: (text, record) => {
                record.sVer = 1; // 默认显示分支号1
                const pkid = record.pkid;

                return <SverList record={record} onCall={(e) => {
                    const prj = projects.filter(v => pkid == v.pkid)[0];
                    prj.selectVer = e.sVer;
                    prj.pblName = e.pblName;
                }} />;
            }
        },
        {
            title: '项目类型',
            dataIndex: 'typeName',
            ellipsis: true,
            filter: <Table.FilterText />,
            getValue: ({ typeName, subTypeCode }) => {
                return subTypeCode ? typeName + "/" + subTypeCode : typeName;
            },
        },
        {
            title: '项目所有者',
            dataIndex: 'prjOwnerTypeCode',
            ellipsis: true,
            filter: <Table.FilterText />,
            getValue: ({ prjOwnerTypeCode, crtName, teamName }) => {
                switch (prjOwnerTypeCode) {
                    case "USER": return '[个人]' + crtName;
                    case "TEAM": return '[团队]' + teamName;
                }
            },
            render: (text, record) => {
                switch (record.prjOwnerTypeCode) {
                    case "USER": return <div><Tag color="yellow">个人</Tag>{record.crtName}</div>;
                    case "TEAM": return <div><Tag color="blue">团队</Tag>{record.teamName}</div>;
                }
            }
        },
    ];

    const formCss = {
        '& .ant-form-item': {
            marginBottom: '12px'
        }
    }

    return (
        <Modal
            open={visible}
            title={title}
            width={1200}
            onOk={doConfirm}
            onCancel={() => setVisible(false)}
            bodyStyle={{ background: '#f0f2f5', padding: '6px' }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', background: '#f0f2f5', padding: '0px', height: '500px' }}>
                {/* 查询条件 */}
                <div style={{ padding: '12px 24px 0px 24px', background: '#fff' }}>
                    <Form form={form} onFinish={doSearch} css={formCss}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item name="prjNo" label="项目编号">
                                    <Input size="middle" placeholder="请输入" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item name="prjName" label="项目名称">
                                    <Input size="middle" placeholder="请输入" />
                                </Form.Item>
                            </Col>

                            <Col span={12} offset={expand ? 18 : 0} style={{ textAlign: 'right' }}>
                                <Button onClick={doReset} style={{ margin: '0 8px' }}>重置</Button>
                                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>查询</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>

                {/* 数据表格 */}
                <div style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column', background: '#fff', padding: '12px', flexGrow: 1, marginTop: '6px' }}>
                    <Table
                        size="middle"
                        loading={loading}
                        columns={columns}
                        dataSource={projects}
                        rowKey={"pkid"}
                        rowSelection={{ type: "checkbox", ...rowSelection }}
                    />
                </div>
            </div>
        </Modal>
    );
}

// 分支号列表
const SverList = ({ record, onCall }) => {
    const prjBaseLines = record.prjBaseLines ?? [];
    const [sVer, setsVer] = useState(record.sVer);

    return (
        <Select
            value={sVer}
            style={{ width: '150px' }}
            size='small'
            showSearch
            filterOption={(input, option) => {
                return (option?.children ?? '').includes(input + '');
            }}
            onChange={e => {
                const baseline = prjBaseLines.filter(v => e == v.sVer)[0];
                setsVer(e);
                onCall(baseline);
            }}>
            {
                prjBaseLines.map(v => <Option value={v.sVer}>{'[' + v.sVer + ']' + v.pblName}</Option>)
            }
        </Select>
    );
}

export default SelectProjectDialog;

// 格式化：项目名称显示
export const formatName = async (prj) => {
    const prjId = prj?.prjId;
    const sVer = prj?.sVer;
    const prjName = prj?.prjName;
    const pblName = prj?.pblName; // 分支名称

    if (prjName && pblName) {
        return prjName + '[' + sVer + '-' + pblName + ']';
    } else if (prjId && sVer) {
        const baseline = await getBaseline(prjId, sVer);
        return baseline.prjName + '[' + sVer + '-' + baseline.pblName + ']';
    }

    return prjName + '[' + sVer + ']';
}