export function onDoubleClickNode(node, event) {
    if (
        1 < this.selectedNodes.size ||
        this.isNodeEditingText(node)
    ) {
        return
    }

    event.stopPropagation()
    const text = this.getNodeText(node)
    this.behaviours.startEditNodeText(node, text)
}
