import superMeta from '../_PRODUCT/metaProductNode.mjs'

export default {
    ...superMeta,
    codeProp: 'brfCode',
    mapProp: 'brfMap',
    name: '基线需求单',
    ownerTextProp: 'prjName',
    textProp: 'brfTitle',
    type: 'BRF',
}
