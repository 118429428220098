import {ProductPropertiesPane} from '../components/NodePropertiesPane.jsx'
import LongText from '../../SidePanel/RightPanel/PropertiesControllerLongText.jsx'
import Select from '../../SidePanel/RightPanel/PropertiesControllerSelect.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Analyse from '../components/PropertiesControllerAnalyse.jsx'
import ButtonSaveProduct from '../components/PropertiesPaneButtonSaveProduct.jsx'
import ButtonGotoArch from '../_ARCH/ButtonGotoArch.jsx'
import DbmsType from './DbmsType.mjs'
import Model from './ModelDb.mjs'

const PropertiesPaneDb = (props) => (
    <ProductPropertiesPane
        buttons={
            <>
                <ButtonSaveProduct />
            </>
        }

        controllers={
            <>
                <Text
                    labelSuffix={<ButtonGotoArch />}
                    prop="dbUserCode"
                />

                <Text prop="dbName" />

                <Select
                    prop="dbmsCode"
                    options={DbmsType}
                />

                <LongText prop="dbDesc" />
                <LongText prop="softwareDesc" />
                <LongText prop="hardwareDesc" />
                <LongText prop="networkDesc" />
                <Text prop="deployIp" />
                <LongText prop="deployDesc" />
            </>
        }

        Model={Model}

        snSuffix={
            <Analyse
                modes={[
                    'ChgReference',
                    'Reference',
                    'ReferenceCall',
                ]}
            />
        }

        {...props}
    />
)

export default PropertiesPaneDb
