import extendNode from '../extendNode.mjs'
import useArCatNode from '../_AR_CAT/useArCatNode.jsx'
import meta from './metaUiVariable.mjs'

export default () => {
    const ArCatNode = useArCatNode()

    return extendNode(ArCatNode, {
        ...meta,

        async _atPull(map, node) {
            await ArCatNode._atPull.call(this, map, node)
            node.isFolded = false
        },

        async _atPush(map, node, event) {
            event.stopPropagation()
            const d = await this._getPushData(map, node)
            event.detail.varAlgList.push(d)
        },
    })
}
