import {Input, Tree, Typography} from 'antd'
import React, {useEffect, useState} from "react";
import Modal from '@/components/Modal/Modal'
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";

//平铺数据转树形数据
const convertToTreeData = ({valueLabelProp, data}) => {
    console.log('data', data)
    const treeData = []
    const map = new Map()
    if (data) {
        data.map(item => {
            const node = {
                ...item,
                key: item[valueLabelProp],
                value: item[valueLabelProp],
                title: item.rmName,
                // title: item.rmName + (item.sfMapStsName ? `【${item.mapSVer}.${item.mapRev} ${item.sfMapStsName}】` : ''),
                children: [],
            };
            map.set(item.rmCode, node);
            return node;
        }).forEach(item => {
            if (!item.pRmCode) {
                treeData.push(item);
            } else {
                const pTreeNode = map.get(item.pRmCode);
                if (pTreeNode) {
                    pTreeNode.children.push(item);
                }
            }
        });
    }
    return treeData;
}

//树组件搜索算法
const keyWordFilter = ({keyWord, data}) => {
    //数据源为空、关键词为空均直接返回
    if (!data || data.length === 0 || !keyWord || keyWord.trim() === '') {
        return data;
    }

    //递归搜索算法，使用map函数是为了产生新的对象，防止修改原数据
    const searchMap = (item) => {
        const {title, rmUserCode, rmName, children, ...props} = item

        //字段是否匹配关键词
        const isMatch = () => (title && title.toLowerCase().indexOf(keyWord.toLowerCase()) > -1) || (rmUserCode && rmUserCode.toLowerCase().indexOf(keyWord.toLowerCase()) > -1) || (rmName && rmName.toLowerCase().indexOf(keyWord.toLowerCase()) > -1)

        //存在子元素时优先向下寻找匹配，否则直接匹配自身是否符合条件
        if (children && children.length > 0) {
            //递归子元素
            const children_ = children.map(searchMap).filter(f => f);
            //递归匹配完成后还存在子元素时，要返回自身（即父节点也要返回），否则直接匹配自身是否符合条件
            if (children_.length > 0) {
                return {...item, children: children_}
            } else {
                if (isMatch()) {
                    return {...item}
                } else {
                    return null
                }
            }
        } else {
            if (isMatch()) {
                return {...item}
            } else {
                return null
            }
        }
    }

    console.log('data.map(searchMap).filter(f => f)', data.map(searchMap).filter(f => f))
    return data.map(searchMap).filter(f => f)
}

//提取全部的展开Key
const extractExpandedKey = (treeData) => {
    const expandedKeys = []
    if (treeData) {
        const __foreach__ = ({key, value, children}) => {
            expandedKeys.push(key)
            if (children) {
                children.forEach(__foreach__)
            }
        }

        treeData.forEach(__foreach__)
    }
    return expandedKeys
}

/**
 * 选择需求域弹窗
 * @param queryParams 需要传递给查询请求的参数
 * @param localData 本地数据，当存在本地数据优先使用本地数据，主要解决下拉框和本弹窗组合使用时，可能出现的数据源不一致问题
 * @param valueLabelProp 指定弹窗选择后返回的主键，默认为pkid，解决某些场景需要rmCode等字段
 * @param value 默认选中项，当字段有值时，弹窗打开后会默认选中
 * @param onOk
 * @param multiple 多选模式，默认false
 * @param treeProps 定制树控件属性
 * @param props
 * @returns {JSX.Element}
 */
export default function SelectRmDialog({queryParams, localData, valueLabelProp = 'pkid', value, onOk, multiple = false, treeProps, ...props}) {
    const http = useHTTP();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedKeys, setSelectedKeys] = useState([])
    const [selectedNodes, setSelectedNodes] = useState([])
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    //树配置根据单选/多选模式来确定最终组合
    const treeSingleProps = {
        selectable: true,
        selectedKeys: selectedKeys,
        onSelect: (selectedKeys_, {selected, selectedNodes, node, event}) => {
            setSelectedKeys(selectedKeys_);
            setSelectedNodes(selectedNodes)
        },
        checkable: false,
        ...treeProps
    }

    const treeMultipleProps = {
        selectable: false,
        checkable: true,
        checkedKeys: selectedKeys,
        onCheck: (checkedKeys_, {checked, checkedNodes, node, event, halfCheckedKeys}) => {
            setSelectedKeys(
                checkedNodes
                    .filter(e => e.leaf)
                    .map(e => e[valueLabelProp])
            )

            setSelectedNodes(checkedNodes)
        },
    }

    const doQuery = async ({...values} = {}) => {
        if (localData) {
            return localData
        }

        try {
            const data = await runAsync(
                async () => {
                    return await http.get(`/archRm/listArchRm`, {...queryParams, ...values});
                }, {
                    error: {content: (err) => `查询失败: ${err.message}`},
                    loading: {show: false},
                    success: {show: false},
                })
            if (data) {
                return convertToTreeData({valueLabelProp, data})
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }

        return []
    }

    const handleInputSearch = async (keyWord) => {
        // const data = await doQuery()
        const filterData = keyWordFilter({keyWord, data})
        setFilterData(filterData)
    }

    const handleOk = async () => {
        console.log('selectedKeys', selectedKeys)
        onOk({isOk: true, value: multiple ? selectedKeys : (selectedKeys.length > 0 ? selectedKeys[0] : null), node: selectedNodes[0], nodes: selectedNodes})
    }

    useEffect(() => {
        (async () => {
            const data = await doQuery()
            setData(data)
            setFilterData(data)
            setExpandedKeys(extractExpandedKey(data))
            //当外部传入默认选中值时
            console.log('value=', value)
            if (value) {
                setSelectedKeys([...(multiple ? value : [value])])
            }
        })()
    }, [])

    return (
        <Modal
            title={"选择需求域"}
            width={800}
            style={{top: 20}}
            bodyStyle={{overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '80vh', minHeight: 320, background: '#fff', padding: 12}}
            onOk={handleOk}
            {...props}
        >
            <div style={{marginBottom: 12,}}>
                <Input.Search
                    loading={loading}
                    enterButton="查询"
                    allowClear
                    size="small"
                    onSearch={handleInputSearch}
                    placeholder='可输入需求域代码或名称进行查询'
                />
            </div>

            <div style={{height: 'calc(80vh-200)', overflow: 'hidden', border: '1px solid #f0f0f0', padding: 8}}>
                {
                    data && data.length > 0 &&
                    <Tree
                        height={400}
                        motion={null}
                        showLine={{showLeafIcon: false,}}
                        showIcon={true}
                        defaultExpandAll={false}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        autoExpandParent={autoExpandParent}
                        treeData={filterData}

                        {...(multiple ? {multiple, ...treeMultipleProps, ...treeProps} : {multiple, ...treeSingleProps, ...treeProps})}
                    />
                }
            </div>
        </Modal>
    );
}
