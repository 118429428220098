import React, {useEffect, useState} from 'react';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import {Button, Form, Input, Select, Space, Spin, Typography} from "antd";
import Modal from "@/components/Modal/Modal";

const {Text} = Typography

export default function BrfAccessApproval({record, onOk, ...props}) {
    const http = useHTTP();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [tms, setTms] = useState([]);
    const [bms, setBms] = useState([]);

    const doQueryUsers = async () => {
        const data = await runAsync(
            async () => {
                return await http.get(`/listPrjMbrBySpecialRole`, {prjId: record.prjId});
            }, {
                error: {content: (err) => `查询失败: ${err.message}`},
                loading: {show: false},
                success: {show: false},
            });

        const tms = []
        const bms = []
        if (data) {
            data.forEach(({userId, name, prjGrpTypeCode}) => {
                if (prjGrpTypeCode === 'TM') {
                    tms.push({
                        label: name,
                        value: userId
                    })
                }
                if (prjGrpTypeCode === 'BM') {
                    bms.push({
                        label: name,
                        value: userId
                    })
                }
            })
        }
        setTms(tms)
        setBms(bms)
    }

    const doApproval = async (actionResult, values) => {
        try {
            setLoading(true)
            await runAsync(
                async () => {
                    return await http.post(`/brf/accessApproval`, {...values, actionResult}, {headers: {prjId: record.prjId, sVer: record.sVer}});
                }, {
                    error: {content: (err) => `评审失败: ${err.message}`},
                    loading: {show: true},
                    success: {show: true},
                });
            onOk(true)
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false)
        }
    }

    const handleApprovalClick = async (actionResult) => {
        if (actionResult === 'yes') {
            form.validateFields().then(async values => {
                if (!values.mbmId) {
                    Modal.info({title: '提示信息', content: '请分配一位主牵业务经理'});
                    return
                }
                doApproval(actionResult, values)
            })
        } else {
            const values = form.getFieldsValue()
            doApproval(actionResult, values)
        }
    }

    useEffect(() => {
        form.setFieldsValue(record)
        doQueryUsers()
    }, []);

    return (
        <Modal
            title="评审"
            width="600px"
            {...props}
            footer={
                <Space>
                    <Button type='primary' ghost size='small' onClick={() => handleApprovalClick('yes')}>通过</Button>
                    <Button type='ghost' size='small' danger onClick={() => handleApprovalClick('no')}>驳回</Button>
                    <Button type='ghost' size='small' onClick={() => onOk(false)}>取消</Button>
                </Space>
            }
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical">
                    <Form.Item name='pkid' hidden><Input/></Form.Item>
                    <Form.Item
                        label='主牵业务经理'
                        name='mbmId'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请分配主牵业务经理',
                        //     },
                        // ]}
                    >
                        <Select
                            allowClear
                            options={bms}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                    <Form.Item
                        label='主牵技术经理'
                        name='mtmId'
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: '请分配主牵技术经理',
                        //     },
                        // ]}
                    >
                        <Select
                            allowClear
                            options={tms}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input?.toLowerCase())}
                        />
                    </Form.Item>
                    <Form.Item name='actionDescription' label='备注'>
                        <Input.TextArea rows={4} allowClear/>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    )
};
