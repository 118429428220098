import Table from '@/components/Table/Table.jsx'
import TableChooseComponent from '../_COMPONENT/TableChooseComponent.jsx'

export default function TableChooseBrfsys(props) {
    const columns = [
        {
            title: '需求单名称',
            dataIndex: 'arName',
            component: <Table.ViewText />,
        },

        {
            title: '需求单代码',
            dataIndex: 'arNo',
            component: <Table.ViewText />,
        },
        {
            title: '系统名称',
            dataIndex: 'riName',
            component: <Table.ViewText />,
        },

        {
            title: '系统代码',
            dataIndex: 'riUserCode',
            component: <Table.ViewText />,
        },

        // {
        //     title: '维度实体标题',
        //     dataIndex: 'deTitle',
        //     component: <Table.ViewText />,
        // },
    ]

    return (
        <TableChooseComponent
            columns={columns}
            {...props}
        />
    )
}
