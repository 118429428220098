import bizNodeModel from '../../bizNodeModel.mjs'

export default bizNodeModel({
    fmUserCode: {
        name: () => '功能域代码',
    },

    fmName: {
        name: () => '功能域名称',
        required: () => true,
    },
})
