import {BatchPropertiesPane} from '../components/NodePropertiesPane.jsx'
import ButtonGenCode from './PropertiesPaneButtonGenCode.jsx'

const PropertiesPaneBatchBd = (props) => (
    <BatchPropertiesPane
        buttons={<ButtonGenCode />}
        {...props}
    />
)

export default PropertiesPaneBatchBd
