import ArchPropertiesPane from '../_ARCH/ArchPropertiesPane.jsx'
import ButtonImportProductsFromXls from '../_DESIGN/PropertiesPaneButtonImportProductsFromXls.jsx'
import ButtonPreview from './PropertiesPaneButtonPreview.jsx'

export default function PropertiesPaneUa(props) {
    return (
        <ArchPropertiesPane
            buttons={
                <>
                    <ButtonImportProductsFromXls type="um">
                        导入界面结构
                    </ButtonImportProductsFromXls>

                    <ButtonPreview />
                </>
            }

            {...props}
        />
    )
}
