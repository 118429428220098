import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useProductNode from '../_PRODUCT/useProductNode.jsx'
import meta from './metaBrf.mjs'
import Model from './ModelBrf.mjs'
import PropertiesPane from './PropertiesPaneBrf.jsx'
import FormChoose from './FormChooseBrf.jsx'
import TableChoose from './TableChooseBrf.jsx'
import api from './apiBrf.mjs'
import { message } from 'antd'

export default () => {
    const ProductNode = useProductNode()

    return extendNode(ProductNode, {
        ...meta,
        api,
        FormChoose,
        TableChoose,
        Model,
        PropertiesPane,
        attrNodes: {
            top: ['ATTR_BRF_RESUME'],
        },

        getStyle(map, node) {
            return {
                ...this._getStyle(map, node, {
                    backgroundColor: Color.LIGHT_ORANGE,
                }),

                shape: 'Rectangle',
            }
        },
        canWriteData(map) {
            const data = map.root.data;
            return ['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)
        },

        canWriteTree(map) {
            const data = map.root.data;

            return !["PRODUCTION", "PENDING_PRODUCTION"].includes(data.brfStsCode)
        },

        get push() {
            const {push} = this.api
            

            if (! push) {
                return void 0
            }

            return async (map, node) => {
                const { brfStsCode } = map.root.data;
                if('PENDING_PRODUCTION' == brfStsCode) throw new Error('待投产状态不能更新');
                if('PRODUCTION' == brfStsCode) throw new Error('已投产状态不能更新');
                const args = await this._getPushData(map, node)
                const result = await push(args)
                await this._pushDone(map, node, result)
            }
        },

        _getCategories(map, node) {
            return [
                'BRF_OVERVIEW',
                'BRF_SYS',
            ]
        },

        _pushDataSlots: {
            brfSyss: [],
        },
    })
}
