import {ComponentPropertiesPane } from '../components/NodePropertiesPane.jsx'
import {NodePropertiesPane} from '../../SidePanel/RightPanel/NodePane.jsx'
import Text from '../../SidePanel/RightPanel/PropertiesControllerText.jsx'
import Model from './ModelBrfsys.mjs'
import Prj from './PropertiesControllerPrj.jsx'
import TmUser from './PropertiesControllerTm.jsx'
import { useUser } from "@/biz/user.mjs";

function getFinalData(obj) {
    // 如果obj不存在，返回undefined
    if (!obj) return undefined;
    // 如果parent存在且不为null，继续递归
    if (obj.parent !== null && obj.parent !== undefined) {
    return getFinalData(obj.parent);
    }
    // 否则返回当前对象的data
    return obj.data;
    }

const PropertiesPaneAr = (props) => {
    const user = useUser();
    const data = getFinalData(props.nodes[0])
    return (<>
        <NodePropertiesPane
            Model={Model}
            {...props}
        >
            <Prj prop="sysPrjNo" label={'关联系统'} disabled={!['DRAFT', 'PRE_APPROVAL_REJECTED', 'ACCESS_APPROVAL_REJECTED'].includes(data.brfStsCode)} />
            <TmUser prop='bm' label="业务经理" disabled={user.pkid != data.mrmId || ['PRODUCTION', 'PENDING_PRODUCTION'].includes(data.brfStsCode)} />
            <TmUser prop='tm' label="技术经理" disabled={user.pkid != data.mrmId || ['PRODUCTION', 'PENDING_PRODUCTION'].includes(data.brfStsCode)} />
        </NodePropertiesPane>
    </>
)}

export default PropertiesPaneAr
