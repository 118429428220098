import React from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

// 创建对象
const DbData = () => {

}

DbData.dbUserCode = () => {
    const rules = [
        {
            required: true,
            message: '请输入数据库代码',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dbUserCode" rules={rules} label="数据库代码">
            <Input size="middle" placeholder="请输入数据库代码" />
        </Form.Item>
    );
}

DbData.dbName = () => {
    const rules = [
        {
            required: true,
            message: '请输入数据库名称',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dbName" rules={rules} label="数据库名称">
            <Input size="middle" placeholder="请输入数据库名称" />
        </Form.Item>
    );
}

DbData.dbmsCode = () => {
    const rules = [
        {
            required: true,
            message: '请选择数据库管理系统',
        },
    ];

    return (
        <Form.Item name="dbmsCode" rules={rules} label="数据库管理系统">
            <Select placeholder="请选择数据库管理系统">
                <Option value="MYSQL">MySQL</Option>
                <Option value="ORACLE">Oracle</Option>
                <Option value="SQLSERVER">SQLServer</Option>
                <Option value="POSTGRESQL">PostgreSQL</Option>
            </Select>
        </Form.Item>
    );
}

DbData.memo = () => {
    const rules = [
        {
            max: 2000,
            message: '最长2000位',
        },
    ];

    return (
        <Form.Item name="memo" rules={rules} label="备注">
            <Input.TextArea placeholder="请输入备注" rows="4" />
        </Form.Item>
    );
}

DbData.SaveButton = ({ onCancel, onSave }) => {
    return (
        <Space>
            <Button size="middle" type="primary" ghost onClick={onCancel}>取消</Button>
            <Button htmlType="submit" type="primary" size="middle" icon={<SaveOutlined />} onClick={onSave}>保存</Button>
        </Space>
    );
}

export default DbData;