import http from '@/biz/http.mjs'

export default {

    publish: (data) => http.post(
        '/archMiddlewares/modifySts',
        {...data, stsCode: 'RLS'}
    ),

    pull: ({pkid}) => http.get(`/archMiddlewares/${pkid}/dps`),
    push: (data) => http.post('/archMiddlewares/batchmodify', data),
    read: ({pkid}) => http.get('/oneArchMiddleware', {pkid}),
    readList: (args) => http.get('/listArchMiddlewareByMap', args),

    revise: ({pkid}) => http.post(
        '/archMiddlewares/modifySts',
        {pkid, stsCode: 'REVISE'}
    ),

    updateMap: (data) => http.put(
        `/archMiddlewares/${data.pkid}/map`,
        data
    ),
}
