import { Button, Form, Input, Select, Space } from 'antd';

// 创建对象
const DtData = () => {

};

DtData.dtUserCode = () => {
    const rules = [
        {
            required: true,
            message: '请输入表代码',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dtUserCode" rules={rules} label="表代码">
            <Input size="middle" placeholder="表代码" />
        </Form.Item>
    );
};

DtData.dtAlias = () => {
    const rules = [
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dtAlias" rules={rules} label="表别名">
            <Input size="middle" placeholder="表别名" />
        </Form.Item>
    );
};

DtData.dtName = () => {
    const rules = [
        {
            required: true,
            message: '请输入表名称',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dtName" rules={rules} label="表名称">
            <Input size="middle" placeholder="表名称" />
        </Form.Item>
    );
};

DtData.dtRev = () => {
    const rules = [
        {
            required: true,
            message: '请输入修订号',
        },
        {
            max: 50,
            message: '最长50位',
        },
    ];

    return (
        <Form.Item name="dtRev" rules={rules} label="修订号">
            <Input size="middle" placeholder="修订号" />
        </Form.Item>
    );
};

DtData.dtDesc = () => {
    return (
        <Form.Item name="dtDesc" label="说明">
            <Input size="middle" placeholder="说明" />
        </Form.Item>
    );
};

DtData.memo = () => {
    return (
        <Form.Item name="memo" label="备注">
            <Input.TextArea placeholder="备注" rows={4} />
        </Form.Item>
    );
};

export default DtData;