import superMeta from '../_COMPONENT/metaComponentNode.mjs'

export default {
    ...superMeta,
    codeProp: 'sysCode',
    // mapProp: 'map',
    name: '分支',
    ownerIdProp: 'brfId',
    ownerTextProp: 'brfName',
    ownerType: 'BRFSYS',
    textProp: 'name',
    type: 'BRFBRANCH',
}