import extendNode from '../extendNode.mjs'
import Color from '../Color.mjs'
import useModuleNode from '../_MODULE/useModuleNode.jsx'
import api from './apiCm.mjs'
import meta from './metaCm.mjs'
import Model from './ModelCm.mjs'
import PropertiesPane from './PropertiesPaneCm.jsx'

export default () => {
    const ModuleNode = useModuleNode()

    return extendNode(ModuleNode, {
        ...meta,
        api,
        Model,
        PropertiesPane,

        getStyle(map, node) {
            return this._getStyle(map, node, {borderColor: Color.GOLD})
        },

        async _atOwnerPush(map, node, event) {
            const {bizNodeType} = event.target.data

            const key = {
                CA: 'archCmList',
                CM: 'child'
            }[bizNodeType]

            const d = await this._getPushData(map, node)
            event.detail[key].push(d)
        },
    })
}
